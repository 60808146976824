import React, { useEffect, useState, Fragment} from 'react';

import clsx from 'clsx';
import Select from 'react-select';

import {
    MenuItem, Chip, Paper, TextField, NoSsr, Typography,
} from '@material-ui/core'
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import {Picky} from "react-picky";
import '../../assets/picky/picky.css';
import {commonLang} from '../../utils/commonLang';
import {multiLangReplace} from "../../utils/commUtils";
import {nullToBlank} from "../../utils/commUtils";
import $ from 'jquery';
import { commonRequest, postAxios } from '../../utils/commAxios';
import { injectIntl } from 'react-intl';
import { Autocomplete_LANG } from './Autocomplete_leng';

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};


function AutocompleteSelect( props ) {
    const classes = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            height: 250,
            minWidth: 290,
        },
        input: {
            display: 'flex',
            padding: 0,
            height: 'auto',
        },
        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
        },
        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
                0.08,
            ),
        },
        noOptionsMessage: {
            padding: theme.spacing(1, 2),
        },
        singleValue: {
            fontSize: 16,
        },
        placeholder: {
            position: 'absolute',
            left: 2,
            bottom: 6,
            fontSize: 16,
        },
        paper: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        divider: {
            height: theme.spacing(2),
        },
    }))();

    const { placeholder, suggestions, value, onChange, label, className, multipleYn, ...otherProperties} = props
    const theme = useTheme();

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    // const [state, setState] = React.useState(value);
    const [multiple, setMultiple] = React.useState(multipleYn);

    const onChangeHandler = event => {
        // setState(event)
        onChange(event)
    }

    /*
    if(multipleYn){
        setMultiple(true);
    }
    */

    return (
        <div className={className}>
            <NoSsr>
                <Select
                    classes={classes}
                    styles={selectStyles}
                    inputId="react-select-single"
                    TextFieldProps={{
                        label: [label],
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    placeholder={placeholder}
                    options={suggestions}
                    components={components}
                    value={value}
                    onChange={onChangeHandler}
                    isMulti={multiple}

                    {...otherProperties}
                />
            </NoSsr>
        </div>
    );
}


// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
function AutocomplateSelect( props ) {
    const classes = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            height: 250,
            minWidth: 290,
        },
        input: {
            display: 'flex',
            padding: 0,
            height: 'auto',
        },
        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
        },
        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
                0.08,
            ),
        },
        noOptionsMessage: {
            padding: theme.spacing(1, 2),
        },
        singleValue: {
            fontSize: 16,
        },
        placeholder: {
            position: 'absolute',
            left: 2,
            bottom: 6,
            fontSize: 16,
        },
        paper: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        divider: {
            height: theme.spacing(2),
        },
    }))();

    const { placeholder, suggestions, value, onChange, label, className, multipleYn, ...otherProperties} = props
    const theme = useTheme();

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    const [state, setState] = React.useState(value);
    const [multiple, setMultiple] = React.useState(multipleYn);

    const onChangeHandler = event => {
        setState(event)
        onChange(event)
    }

    /*
    if(multipleYn){
        setMultiple(true);
    }
    */

    return (
        <div className={className}>
            <NoSsr>
                <Select
                    classes={classes}
                    styles={selectStyles}
                    inputId="react-select-single"
                    TextFieldProps={{
                        label: [label],
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    placeholder={placeholder}
                    options={suggestions}
                    components={components}
                    value={state}
                    onChange={onChangeHandler}
                    isMulti={multiple}

                    {...otherProperties}
                />
            </NoSsr>
        </div>
    );
}

function AutocomplateMultiSelect( props ) {
    const classes = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            height: 250,
            minWidth: 290,
        },
        input: {
            display: 'flex',
            padding: 0,
            height: 'auto',
        },
        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
        },
        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
                0.08,
            ),
        },
        noOptionsMessage: {
            padding: theme.spacing(1, 2),
        },
        singleValue: {
            fontSize: 16,
        },
        placeholder: {
            position: 'absolute',
            left: 2,
            bottom: 6,
            fontSize: 16,
        },
        paper: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
    }))();

    const { suggestions, placeholder, label, value, onChange, className, ...otherProperties } = props
    const theme = useTheme();

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    const [state, setState] = React.useState(value);

    const onChangeHandler = event => {
        setState(event)
        onChange(event)
    }

    return (
        <div className={className}>
            <NoSsr>
                <Select
                    classes={classes}
                    styles={selectStyles}
                    inputId="react-select-multiple"
                    TextFieldProps={{
                        label: [label],
                        InputLabelProps: {
                            htmlFor: 'react-select-multiple',
                            shrink: true,
                        },
                    }}
                    placeholder={placeholder}
                    options={suggestions}
                    components={components}
                    value={state}
                    onChange={onChangeHandler}
                    isMulti

                    {...otherProperties}
                />
            </NoSsr>
        </div>
    );
}
// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
// DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD

const BootstrapAutoComplete = props => {
    const {options, selectList, onChangeFunc, dropHeight, multiple, className, dropdownHeight, numberDisplayed, ...etc} = props;

    const [classNm, setClassNm] = React.useState('');
    const [selOptions, setSelOptions] = React.useState([]);

    const optionsHeight = nullToBlank(dropdownHeight) != "" ? dropdownHeight : 450;

    let displayNumber = (nullToBlank(numberDisplayed) !== "") ? numberDisplayed : 1;

    const handleSelectChange = (event) => {

        setSelOptions(event);

        if (onChangeFunc) {
            onChangeFunc(event);
        }
    };

    React.useEffect(()=>{
        if(multiple) {
            setClassNm(className + ' picky-checkbox');
        }else{
            setClassNm(className + ' picky-radio');
        }
    }, [multiple]);

    React.useEffect(()=>{
        setSelOptions(selectList);
    }, [selectList]);

    React.useEffect(()=>{
        if(multiple) {
            setClassNm(className + ' picky-checkbox');
        }else{
            setClassNm(className + ' picky-radio');
        }
    }, [className]);

    const onOpenFunc = () => {
        if (nullToBlank(props.id) != "" && options.length > 0) {
            let find = -1;

            if (selOptions instanceof Array) {
                if (selOptions.length < 1) {
                    return;
                }

                find = options.findIndex(child => {
                    return child.value == selOptions[0].value;
                });

                if (find > -1) {
                    const elementId = `#${props.id}-option-${find}`;
                    $(elementId).focus();
                    $(elementId).select();
                }
            } else {
                if (nullToBlank(selOptions) != "") {
                    find = options.findIndex(child => {
                        return child.value == selOptions.value;
                    });
    
                    if (find > -1) {
                        const elementId = `#${props.id}-option-${find}`;
                        $(elementId).focus();
                        $(elementId).select();
                    }
                }
            }
        }
    }

    return (
        <Fragment>
            <Picky
                options={options}
                value={selOptions}
                valueKey="value"
                labelKey="label"
                onChange={handleSelectChange}
                dropdownHeight={optionsHeight}
                multiple={multiple}
                className={classNm}
                manySelectedPlaceholder={multiLangReplace(commonLang().SELECT_MESSAGE_MANYSELECTED, [selOptions instanceof Array ? selOptions.length : 0])}
                allSelectedPlaceholder={multiLangReplace(commonLang().SELECT_MESSAGE_MANYSELECTED, [selOptions instanceof Array ? selOptions.length : 0])}
                filterPlaceholder={commonLang().SELECT_MESSAGE_FILTER}
                onOpen={onOpenFunc}
                numberDisplayed={displayNumber}
                clearFilterOnClose={true}
                {...etc}
            />
        </Fragment>
    )
}

/**
 *  BootstrapAutoComplete와 동일하나 selectAllMode="filtered" 옵션 하나만 추가
 */
const BootstrapAutoCompleteSelectModeFiltered = props => {
    const {options, selectList, onChangeFunc, dropHeight, multiple, className, dropdownHeight, numberDisplayed, ...etc} = props;

    const [classNm, setClassNm] = React.useState('');
    const [selOptions, setSelOptions] = React.useState([]);

    const optionsHeight = nullToBlank(dropdownHeight) != "" ? dropdownHeight : 450;

    let displayNumber = (nullToBlank(numberDisplayed) !== "") ? numberDisplayed : 1;

    const handleSelectChange = (event) => {

        setSelOptions(event);

        if (onChangeFunc) {
            onChangeFunc(event);
        }
    };

    React.useEffect(()=>{
        if(multiple) {
            setClassNm(className + ' picky-checkbox');
        }else{
            setClassNm(className + ' picky-radio');
        }
    }, [multiple]);

    React.useEffect(()=>{
        setSelOptions(selectList);
    }, [selectList]);

    React.useEffect(()=>{
        if(multiple) {
            setClassNm(className + ' picky-checkbox');
        }else{
            setClassNm(className + ' picky-radio');
        }
    }, [className]);

    const onOpenFunc = () => {
        if (nullToBlank(props.id) != "" && options.length > 0) {
            let find = -1;

            if (selOptions instanceof Array) {
                if (selOptions.length < 1) {
                    return;
                }

                find = options.findIndex(child => {
                    return child.value == selOptions[0].value;
                });

                if (find > -1) {
                    const elementId = `#${props.id}-option-${find}`;
                    $(elementId).focus();
                    $(elementId).select();
                }
            } else {
                if (nullToBlank(selOptions) != "") {
                    find = options.findIndex(child => {
                        return child.value == selOptions.value;
                    });
    
                    if (find > -1) {
                        const elementId = `#${props.id}-option-${find}`;
                        $(elementId).focus();
                        $(elementId).select();
                    }
                }
            }
        }
    }

    return (
        <Fragment>
            <Picky
                options={options}
                value={selOptions}
                valueKey="value"
                labelKey="label"
                onChange={handleSelectChange}
                dropdownHeight={optionsHeight}
                multiple={multiple}
                className={classNm}
                manySelectedPlaceholder={multiLangReplace(commonLang().SELECT_MESSAGE_MANYSELECTED, [selOptions instanceof Array ? selOptions.length : 0])}
                allSelectedPlaceholder={multiLangReplace(commonLang().SELECT_MESSAGE_MANYSELECTED, [selOptions instanceof Array ? selOptions.length : 0])}
                filterPlaceholder={commonLang().SELECT_MESSAGE_FILTER}
                onOpen={onOpenFunc}
                numberDisplayed={displayNumber}
                clearFilterOnClose={true}
                selectAllMode="filtered"
                {...etc}
            />
        </Fragment>
    )
}

const ChannelNameAutoCompleteSelete = injectIntl(({ intl, channel, onChannelChange }) => {
    const { formatMessage } = intl;
    const MULTI_LANG = Autocomplete_LANG(formatMessage);

    const [channels, setChannels] = useState([]);

    useEffect(() => {
        async_loadChannelName();
    }, []);

    const handleChannelChange = (value) => {
        onChannelChange(value)
    }

    const async_loadChannelName = (additionalFormParams={}) => {
        let form = {
            PROCEDURE_OR_TABLE_NAME : "PG_ZZ00_COMN_COMBO_LIST.PR_CH_CHANNEL_LIST_SEL",
        };
        form = {...form, ...additionalFormParams };

        commonRequest((result) => {
            const session = JSON.parse(result.data.resultData.V_IN_JAVA_SESSION);
            const pmNo = parseInt(session.SS_PMS_NO);

            const ChannelNameCursor = result.data.resultData.V_OUT_JAVA_CURSOR;
            const mappedChannelName = ChannelNameCursor.map(( channel )=>({
                key : channel.CN_CODE,
                value : channel.CN_NO,
                label : channel.CN_NAME,
            }));

            const tmpChannelName = [...mappedChannelName];

            setChannels(tmpChannelName);
            onChannelChange(tmpChannelName);
        }, form);
    };

    return (
        <BootstrapAutoComplete
        options={channels}
        selectList={channel?channel:channels}
        onChangeFunc={handleChannelChange}
        multiple={true}
        includeSelectAll={true}
        includeFilter={true}
        placeholder=""
        selectAllText={MULTI_LANG.CHANNEL_NAME_LABEL_ALL_OPTION}
        keepOpen={false}
        className={"w_250_md d-md-inline-block align-bottom"}
    />
    );
})

export {
    AutocompleteSelect,
    BootstrapAutoComplete,
    BootstrapAutoCompleteSelectModeFiltered,
    AutocomplateSelect ,
    AutocomplateMultiSelect ,
    ChannelNameAutoCompleteSelete,
};

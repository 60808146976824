export default {
    " grid.header.gold" : `금메달`,
    "DownloadReasonPopup.alert.noGridData" : `그리드안에 데이터가 존재하지 않습니다.`,
    "FAQ" : `Q&A`,
    "TB_DE_DB_MESSAGE_CODE_LIST.MSG_COMT_MKEY.2079500" : `이미 등록된 메일 양식 입니다. 변경 또는 삭제 후 등록바랍니다.`,
    "TB_IF_CM_RS.ADDR" : `주소`,
    "TB_IF_CM_RS.ADULT_CNT" : `대인 수`,
    "TB_IF_CM_RS.ARRV_DATE" : `도착 일자`,
    "TB_IF_CM_RS.ARRV_EXPECTED_TIME" : `도착 예정 시간`,
    "TB_IF_CM_RS.CALC_PAY_CN" : `정산 지불 코드 번호`,
    "TB_IF_CM_RS.CHILD_CNT" : `소인 수`,
    "TB_IF_CM_RS.CM_RA_TY_NO" : `CMS 요금 타입 번호`,
    "TB_IF_CM_RS.CM_RM_TY_NO" : `CMS 객실 타입 번호`,
    "TB_IF_CM_RS.CM_RS_NO" : `CMS 예약 번호`,
    "TB_IF_CM_RS.CNCL_CL_CHARGE" : `취소 위약금`,
    "TB_IF_CM_RS.CNCL_DATE" : `취소 일자`,
    "TB_IF_CM_RS.CNCL_POLICY" : `취소 정책`,
    "TB_IF_CM_RS.CN_CALC_INFO" : `채널 정산 정보`,
    "TB_IF_CM_RS.CN_NO" : `채널 번호`,
    "TB_IF_CM_RS.CN_PM_NO" : `채널 호텔 번호`,
    "TB_IF_CM_RS.CN_RA_TY_NO" : `채널 요금 타입 번호`,
    "TB_IF_CM_RS.CN_RM_TY_NO" : `채널 객실 타입 번호`,
    "TB_IF_CM_RS.CN_RS_DT" : `채널 예약 일시`,
    "TB_IF_CM_RS.CN_RS_NO" : `채널 예약 번호`,
    "TB_IF_CM_RS.COMT" : `코멘트`,
    "TB_IF_CM_RS.CORP_CUSTM_NO" : `법인 고객 번호`,
    "TB_IF_CM_RS.CRDT_CARD_CVC_NO" : `신용 카드 CVC 번호`,
    "TB_IF_CM_RS.CRDT_CARD_NO" : `신용 카드 번호`,
    "TB_IF_CM_RS.CRDT_CARD_OWNER_NAME" : `신용 카드 소유자 명`,
    "TB_IF_CM_RS.CRDT_CARD_TYPE_CN" : `신용 카드 구분 코드 번호`,
    "TB_IF_CM_RS.CRDT_CARD_VAL_PERIOD" : `신용 카드 유효 기간`,
    "TB_IF_CM_RS.CREATED" : `예약자료가 생성되었습니다.`,
    "TB_IF_CM_RS.CURNCY_CN" : `통화 코드 번호`,
    "TB_IF_CM_RS.CYBER_ID" : `사이버 ID`,
    "TB_IF_CM_RS.DELETED" : `예약자료가 삭제되었습니다.`,
    "TB_IF_CM_RS.DEPT_DATE" : `출발 일자`,
    "TB_IF_CM_RS.DEPT_EXPECTED_TIME" : `출발 예정 시간`,
    "TB_IF_CM_RS.DPSIT_PAY_INFO" : `선수금 정보`,
    "TB_IF_CM_RS.FIRST_REG_DT" : `최초 등록 일시`,
    "TB_IF_CM_RS.FIRST_REG_USER_IP" : `최초 등록 사용자 IP`,
    "TB_IF_CM_RS.FIRST_REG_USER_NO" : `최초 등록 사용자 번호`,
    "TB_IF_CM_RS.INFANT_CNT" : `유아 수`,
    "TB_IF_CM_RS.INHS_GEST_EMAIL" : `투숙자 이메일`,
    "TB_IF_CM_RS.INHS_GEST_FIRST_NAME" : `투숙자 이름`,
    "TB_IF_CM_RS.INHS_GEST_LAST_NAME" : `투숙자 성`,
    "TB_IF_CM_RS.INHS_GEST_MOBILE_NO" : `투숙자 휴대폰 번호`,
    "TB_IF_CM_RS.INHS_GEST_SEX_CN" : `투숙자 성별 코드 번호`,
    "TB_IF_CM_RS.INHS_GEST_TEL_NO" : `투숙자 전화 번호`,
    "TB_IF_CM_RS.INHS_GEST_TITLE" : `투숙자 TITLE`,
    "TB_IF_CM_RS.INPUT_CN_RA_TY_CODE" : `입력 채널 요금 타입 코드`,
    "TB_IF_CM_RS.INPUT_CN_RA_TY_NAME" : `입력 채널 요금 타입 명`,
    "TB_IF_CM_RS.INPUT_CN_RM_TY_CODE" : `입력 채널 객실 타입 코드`,
    "TB_IF_CM_RS.INPUT_CN_RM_TY_NAME" : `입력 채널 객실 타입 명`,
    "TB_IF_CM_RS.INPUT_CN_TOTRM_RA" : `입력 채널 총객실료`,
    "TB_IF_CM_RS.LANG_CN" : `언어 코드 번호`,
    "TB_IF_CM_RS.LAST_CHNG_DT" : `최종 변경 일시`,
    "TB_IF_CM_RS.LAST_CHNG_USER_IP" : `최종 변경 사용자 IP`,
    "TB_IF_CM_RS.LAST_CHNG_USER_NO" : `최종 변경 사용자 번호`,
    "TB_IF_CM_RS.MAIL_NO" : `우편 번호`,
    "TB_IF_CM_RS.MARKET_TY" : `마켓 타입`,
    "TB_IF_CM_RS.MASTER_CN_RSNO" : `마스터 채널 예약번호`,
    "TB_IF_CM_RS.NAT_CN" : `국가 코드 번호`,
    "TB_IF_CM_RS.PAY_METHOD" : `지불 수단`,
    "TB_IF_CM_RS.PMS_RS_NO" : `PMS 예약 번호`,
    "TB_IF_CM_RS.PMS_RS_SEQ_NO" : `PMS 예약 시퀀스 번호`,
    "TB_IF_CM_RS.PM_ARRV_DATE" : `호텔 도착 일자`,
    "TB_IF_CM_RS.PM_CM_RS_SEND_ERR_MSG" : `호텔 CMS 예약 전송 에러 메시지`,
    "TB_IF_CM_RS.PM_CM_RS_SEND_STATUS_CN" : `호텔 CMS 예약 전송 상태 코드 번호`,
    "TB_IF_CM_RS.PM_DEPT_DATE" : `호텔 출발 일자`,
    "TB_IF_CM_RS.PM_NO" : `호텔 번호`,
    "TB_IF_CM_RS.PM_PKG_CODE" : `호텔 패키지 코드`,
    "TB_IF_CM_RS.PM_RA_TY_CODE" : `호텔 요금 타입 코드`,
    "TB_IF_CM_RS.PM_RMS" : `호텔 객실수`,
    "TB_IF_CM_RS.PM_RM_TY_CODE" : `호텔 객실 타입 코드`,
    "TB_IF_CM_RS.PM_RM_TY_NO" : `호텔 객실 타입 번호`,
    "TB_IF_CM_RS.PM_RS_CNCL_DATE" : `호텔 예약 취소 일자`,
    "TB_IF_CM_RS.PM_RS_STATUS_CN" : `호텔 예약 상태 코드 번호`,
    "TB_IF_CM_RS.PM_TOT_RM_RA" : `호텔 총 객실료`,
    "TB_IF_CM_RS.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS.PRMT_EMP_ID" : `판촉 사원 ID`,
    "TB_IF_CM_RS.REGION_CN" : `지역 코드 번호`,
    "TB_IF_CM_RS.RM_CNT" : `객실 수`,
    "TB_IF_CM_RS.ROW_UNIQUE_NO" : `ROW UNIQUE 번호`,
    "TB_IF_CM_RS.RSVN_GEST_EMAIL" : `예약자 이메일`,
    "TB_IF_CM_RS.RSVN_GEST_FIRST_NAME" : `예약자 이름`,
    "TB_IF_CM_RS.RSVN_GEST_LAST_NAME" : `예약자 성`,
    "TB_IF_CM_RS.RSVN_GEST_MOBILE_NO" : `예약자 휴대폰 번호`,
    "TB_IF_CM_RS.RSVN_GEST_SEX_CN" : `예약자 성별 코드 번호`,
    "TB_IF_CM_RS.RSVN_GEST_TEL_NO" : `예약자 전화 번호`,
    "TB_IF_CM_RS.RSVN_GEST_TITLE" : `예약자 TITLE`,
    "TB_IF_CM_RS.RS_DATE" : `예약 일자`,
    "TB_IF_CM_RS.RS_STATUS_CN" : `예약 상태 코드 번호`,
    "TB_IF_CM_RS.SORC_TY" : `소스 타입`,
    "TB_IF_CM_RS.SOURCE_CODE" : `채널 소스`,
    "TB_IF_CM_RS.TOT_RM_RA" : `총 객실료`,
    "TB_IF_CM_RS.TOT_SVC_AMT" : `총 서비스 금액`,
    "TB_IF_CM_RS.TRACE_NO" : `추적 번호`,
    "TB_IF_CM_RS.USER_CNFM_DT" : `사용자 확인 일시`,
    "TB_IF_CM_RS_INTER_MEMO.CM_RS_NO" : `CMS 예약 번호`,
    "TB_IF_CM_RS_INTER_MEMO.FIRST_REG_DT" : `최초 등록 일시`,
    "TB_IF_CM_RS_INTER_MEMO.FIRST_REG_USER_IP" : `최초 등록 사용자 IP`,
    "TB_IF_CM_RS_INTER_MEMO.FIRST_REG_USER_NO" : `최초 등록 사용자 번호`,
    "TB_IF_CM_RS_INTER_MEMO.INTER_MEMO_COMT" : `인터메모 내용`,
    "TB_IF_CM_RS_INTER_MEMO.INTER_MEMO_SEQ_NO" : `인터메모 순번`,
    "TB_IF_CM_RS_INTER_MEMO.LAST_CHNG_DT" : `최종 변경 일시`,
    "TB_IF_CM_RS_INTER_MEMO.LAST_CHNG_USER_IP" : `최종 변경 사용자 IP`,
    "TB_IF_CM_RS_INTER_MEMO.LAST_CHNG_USER_NO" : `최종 변경 사용자 번호`,
    "TB_IF_CM_RS_INTER_MEMO.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS_INTER_MEMO.ROW_UNIQUE_NO" : `ROW UNIQUE 번호`,
    "TB_IF_CM_RS_INTER_MEMO.TRACE_NO" : `추적 번호`,
    "TB_IF_CM_RS_RM_MATE.CM_RS_NO" : `CMS 예약 번호`,
    "TB_IF_CM_RS_RM_MATE.FIRST_REG_DT" : `최초 등록 일시`,
    "TB_IF_CM_RS_RM_MATE.FIRST_REG_USER_IP" : `최초 등록 사용자 IP`,
    "TB_IF_CM_RS_RM_MATE.FIRST_REG_USER_NO" : `최초 등록 사용자 번호`,
    "TB_IF_CM_RS_RM_MATE.LAST_CHNG_DT" : `최종 변경 일시`,
    "TB_IF_CM_RS_RM_MATE.LAST_CHNG_USER_IP" : `최종 변경 사용자 IP`,
    "TB_IF_CM_RS_RM_MATE.LAST_CHNG_USER_NO" : `최종 변경 사용자 번호`,
    "TB_IF_CM_RS_RM_MATE.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_FIRST_NAME" : `동숙자 이름`,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_LAST_NAME" : `동숙자 성`,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_SEQ_NO" : `동숙자 순번`,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_SEX_CN" : `동숙자 성별 코드 번호`,
    "TB_IF_CM_RS_RM_MATE.ROOMMATE_TITLE" : `동숙자 TITLE`,
    "TB_IF_CM_RS_RM_MATE.ROW_UNIQUE_NO" : `ROW UNIQUE 번호`,
    "TB_IF_CM_RS_RM_MATE.TRACE_NO" : `추적 번호`,
    "TB_IF_CM_RS_RM_RATE.CMSN_AMT" : `커미션 금액`,
    "TB_IF_CM_RS_RM_RATE.CM_RM_RA" : `CMS 객실료`,
    "TB_IF_CM_RS_RM_RATE.CM_RS_NO" : `CMS 예약 번호`,
    "TB_IF_CM_RS_RM_RATE.FIRST_REG_DT" : `최초 등록 일시`,
    "TB_IF_CM_RS_RM_RATE.FIRST_REG_USER_IP" : `최초 등록 사용자 IP`,
    "TB_IF_CM_RS_RM_RATE.FIRST_REG_USER_NO" : `최초 등록 사용자 번호`,
    "TB_IF_CM_RS_RM_RATE.LAST_CHNG_DT" : `최종 변경 일시`,
    "TB_IF_CM_RS_RM_RATE.LAST_CHNG_USER_IP" : `최종 변경 사용자 IP`,
    "TB_IF_CM_RS_RM_RATE.LAST_CHNG_USER_NO" : `최종 변경 사용자 번호`,
    "TB_IF_CM_RS_RM_RATE.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS_RM_RATE.RM_RA" : `객실료`,
    "TB_IF_CM_RS_RM_RATE.ROW_UNIQUE_NO" : `ROW UNIQUE 번호`,
    "TB_IF_CM_RS_RM_RATE.RS_STATUS_CN" : `예약 상태 코드 번호`,
    "TB_IF_CM_RS_RM_RATE.SALE_DATE" : `영업 일자`,
    "TB_IF_CM_RS_RM_RATE.SVC" : `봉사료`,
    "TB_IF_CM_RS_RM_RATE.TRACE_NO" : `추적 번호`,
    "TB_IF_CM_RS_RM_RATE.VAT" : `부가세`,
    "TB_IF_CM_RS_SVC.AMT" : `금액`,
    "TB_IF_CM_RS_SVC.CM_RS_NO" : `CMS 예약 번호`,
    "TB_IF_CM_RS_SVC.COMT" : `코멘트`,
    "TB_IF_CM_RS_SVC.FIRST_REG_DT" : `최초 등록 일시`,
    "TB_IF_CM_RS_SVC.FIRST_REG_USER_IP" : `최초 등록 사용자 IP`,
    "TB_IF_CM_RS_SVC.FIRST_REG_USER_NO" : `최초 등록 사용자 번호`,
    "TB_IF_CM_RS_SVC.LAST_CHNG_DT" : `최종 변경 일시`,
    "TB_IF_CM_RS_SVC.LAST_CHNG_USER_IP" : `최종 변경 사용자 IP`,
    "TB_IF_CM_RS_SVC.LAST_CHNG_USER_NO" : `최종 변경 사용자 번호`,
    "TB_IF_CM_RS_SVC.PM_SVC_CODE" : `호텔 서비스 코드`,
    "TB_IF_CM_RS_SVC.PM_SVC_SEQ_NO" : `호텔 서비스 순번`,
    "TB_IF_CM_RS_SVC.PRE_HIS_CRE_NO" : ``,
    "TB_IF_CM_RS_SVC.QTY" : `수량`,
    "TB_IF_CM_RS_SVC.ROW_UNIQUE_NO" : `ROW UNIQUE 번호`,
    "TB_IF_CM_RS_SVC.SALE_DATE" : `영업 일자`,
    "TB_IF_CM_RS_SVC.SVC" : `봉사료`,
    "TB_IF_CM_RS_SVC.SVC_ORGN_TYPE_CN" : `서비스 발생 구분 코드 번호`,
    "TB_IF_CM_RS_SVC.TRACE_NO" : `추적 번호`,
    "TB_IF_CM_RS_SVC.VAT" : `부가세`,
    "TB_ZZ_EMAIL_CUSTM_SET.ACTIVE_YN" : `사용 여부`,
    "TB_ZZ_EMAIL_CUSTM_SET.CN_NO" : `채널 번호`,
    "TB_ZZ_EMAIL_CUSTM_SET.CREATED" : `이메일설정자료가 생성되었습니다..`,
    "TB_ZZ_EMAIL_CUSTM_SET.DELETED" : `이메일설정자료가 삭제되었습니다.`,
    "TB_ZZ_EMAIL_CUSTM_SET.EMAIL" : `이메일`,
    "TB_ZZ_EMAIL_CUSTM_SET.EMAIL_CUSTM_SET_NO" : `이메일 대상자 설정 번호`,
    "TB_ZZ_EMAIL_CUSTM_SET.EMAIL_SENDER_SET_CN" : `이메일 발송자 설정 코드 번호`,
    "TB_ZZ_EMAIL_CUSTM_SET.EMAIL_SET_DATE" : `이메일 설정 일자`,
    "TB_ZZ_EMAIL_CUSTM_SET.FIRST_REG_DT" : `최초 등록 일시`,
    "TB_ZZ_EMAIL_CUSTM_SET.FIRST_REG_USER_IP" : `최초 등록 사용자 IP`,
    "TB_ZZ_EMAIL_CUSTM_SET.FIRST_REG_USER_NO" : `최초 등록 사용자 번호`,
    "TB_ZZ_EMAIL_CUSTM_SET.INCHARGE_NAME" : `담당자 명`,
    "TB_ZZ_EMAIL_CUSTM_SET.LAST_CHNG_DT" : `최종 변경 일시`,
    "TB_ZZ_EMAIL_CUSTM_SET.LAST_CHNG_USER_IP" : `최종 변경 사용자 IP`,
    "TB_ZZ_EMAIL_CUSTM_SET.LAST_CHNG_USER_NO" : `최종 변경 사용자 번호`,
    "TB_ZZ_EMAIL_CUSTM_SET.TRACE_NO" : `추적 번호`,
    "TB_ZZ_USER.ACTIVE_YN" : `사용 여부`,
    "TB_ZZ_USER.CN_NO" : `채널`,
    "TB_ZZ_USER.DATE_TYPE_CN" : `일자 구분`,
    "TB_ZZ_USER.DEL_YN" : `삭제 여부`,
    "TB_ZZ_USER.DORMANT_GUID_DATE" : `휴면 안내 일자`,
    "TB_ZZ_USER.DORMANT_MEMBER_STATUS_CN" : `휴면 회원 상태`,
    "TB_ZZ_USER.DORMANT_START_DATE" : `휴면 시작 일자`,
    "TB_ZZ_USER.DUTY_CN" : `직무 코드 번호`,
    "TB_ZZ_USER.ETIQUETTE_SET_YN" : `에티켓모드 여부`,
    "TB_ZZ_USER.FIRST_NAME" : `이름`,
    "TB_ZZ_USER.FIRST_REG_DT" : `최초 등록 일시`,
    "TB_ZZ_USER.FIRST_REG_USER_IP" : `최초 등록 사용자 IP`,
    "TB_ZZ_USER.FIRST_REG_USER_NO" : `최초 등록 사용자 번호`,
    "TB_ZZ_USER.GMT_TIME_GAP" : `GMT 시간 차이`,
    "TB_ZZ_USER.INCHARGE_YN" : `담당자 여부`,
    "TB_ZZ_USER.LANG_CN" : `언어 코드 번호`,
    "TB_ZZ_USER.LAST_CHNG_DT" : `최종 변경 일시`,
    "TB_ZZ_USER.LAST_CHNG_USER_IP" : `최종 변경 사용자 IP`,
    "TB_ZZ_USER.LAST_CHNG_USER_NO" : `최종 변경 사용자 번호`,
    "TB_ZZ_USER.LAST_LOGIN_DATE" : `마지막 로그인 일자`,
    "TB_ZZ_USER.LAST_NAME" : `성`,
    "TB_ZZ_USER.LOGIN_FAIL_TIMES" : `로그인 실패 횟수`,
    "TB_ZZ_USER.LOGIN_PWD_CHNG_YN" : `로그인 암호 변경 여부`,
    "TB_ZZ_USER.MOBILE_NO" : `휴대폰 번호`,
    "TB_ZZ_USER.PM_NO" : `호텔`,
    "TB_ZZ_USER.PWD_CHNG_DATE" : `비밀번호 변경 일자`,
    "TB_ZZ_USER.ROW_UNIQUE_NO" : `ROW UNIQUE 번호`,
    "TB_ZZ_USER.SMS_SET_YN" : `SMS 설정 여부`,
    "TB_ZZ_USER.TEL_NO" : `전화 번호`,
    "TB_ZZ_USER.TRACE_NO" : `추적 번호`,
    "TB_ZZ_USER.TUTORIAL_VIEW_YN" : `튜토리얼 보기 여부`,
    "TB_ZZ_USER.USER_EMAIL" : `사용자 이메일`,
    "TB_ZZ_USER.USER_ID" : `사용자 ID`,
    "TB_ZZ_USER.USER_ID_LOCK_YN" : `사용자 ID LOCK 여부`,
    "TB_ZZ_USER.USER_NO" : `사용자 번호`,
    "TB_ZZ_USER.USER_PWD" : `사용자 비밀번호`,
    "TB_ZZ_USER.USER_ROLE_CN" : `사용자 역할`,
    "ZZ02_0500.message.cert_key_expired" : `인증코드 만료일을 초과하였습니다.`,
    "ZZ02_0500.message.cert_key_not_exist" : `존재하지 않는 인증코드입니다.`,
    "ZZ10_0101.title.subject_manual_management" : `매뉴얼관리`,
    "ZZ10_0102.title.subject_service_management" : `서비스관리`,
    "ZZ10_0103.title.subject_source_management" : `B2B 거래처관리`,
    "ZZ10_0104.title.subject_operation_management" : `운영관리`,
    "ZZ13_0200.buttom.init" : `초기화`,
    "ZZ13_0200.buttom.search" : `검색`,
    "ZZ13_0200.etc.required" : `필수항목`,
    "ZZ13_0200.title.gridHeaderCrtDate" : `처리일자`,
    "ZZ13_0200.title.gridHeaderDeadline" : `마감여부`,
    "ZZ13_0200.title.gridHeaderHotelRoomName" : `호텔 객실명`,
    "ZZ13_0200.title.gridHeaderPropertyName" : `프로퍼티 명`,
    "ZZ13_0200.title.gridHeaderReserveCount" : `예약수량`,
    "ZZ13_0200.title.gridHeaderResiduaryCount" : `잔여수량`,
    "ZZ13_0200.title.gridHeaderSaleDate" : `판매일자`,
    "ZZ13_0200.title.searchAll" : `전체`,
    "ZZ13_0200.title.searchDivision" : `구분`,
    "ZZ13_0200.title.searchHotelRoomType" : `호텔 객실`,
    "ZZ13_0200.title.searchProperty" : `프로퍼터 명`,
    "ZZ13_0200.title.searchperiodType" : `처리기간`,
    "ZZ13_0200.validation.warningMessageProcDate" : `처리기간은 31일을 넘어갈 수 없습니다.`,
    "appFooter.title.message" : `메뉴얼 - WINGSCMS에서는 각 메뉴별로 매뉴얼을 보실 수 있습니다. 궁금하신 메뉴를 왼쪽 메뉴바에서 선택해주세요.`,
    "autocomplete.etc.allOption" : `전체`,
    "certpopup.title.cancel" : `취소`,
    "certpopup.title.certification" : `인증`,
    "certpopup.title.checkInput" : `을(를) 입력해 주세요.`,
    "certpopup.title.close" : `닫기`,
    "certpopup.title.complate" : `완료되었습니다.`,
    "certpopup.title.password" : `비밀번호`,
    "certpopup.title.username" : `사용자 명`,
    "cm01_0100.button.accept" : `고객센터 접수`,
    "cm01_0100.button.confirm" : `확인`,
    "cm01_0100.button.confirmNo" : `아니오`,
    "cm01_0100.button.confirmYes" : `예`,
    "cm01_0100.button.resend" : `재전송`,
    "cm01_0100.button.search" : `검색`,
    "cm01_0100.button.searchInitialize" : `초기화`,
    "cm01_0100.message.noDataChannel" : `채널을 선택해 주세요.`,
    "cm01_0100.message.resendAlertConfirmMessage" : `재전송 결과 (@(0)/3) <br/><br/>데이터가 정상적으로 PMS로 전송되지 못햇습니다. <br/><br/>미전송 예약은 PMS에 수기로 입력 해 주시기 바랍니다. <br/>이 문제에 대해서 산하정보기술로 오류 내용을 보내시려면 [오류 내용 보내기] 버튼을 클릭 해 주세요. <br/>수집된 데이터는 오직 문제를 해결하는데만 사용됩니다. <br/>`,
    "cm01_0100.message.resendAlertMessage" : `재전송 결과 (@(0)/3) <br/><br/>데이터가 정상적으로 PMS로 전송되지 못햇습니다. <br/><br/>[재전송] 버튼을 클릭 하면, 다시한번 재전송 시도를 합니다. <br/>이 문제에 대해서 산하정보기술로 오류 내용을 보내시려면 [오류 내용 보내기] 버튼을 클릭 해 주세요. <br/>수집된 데이터는 오직 문제를 해결하는데만 사용됩니다. <br/>`,
    "cm01_0100.title.adr" : `1박 객실료`,
    "cm01_0100.title.all" : `전체`,
    "cm01_0100.title.cancelReservation" : `취소예약`,
    "cm01_0100.title.channelName" : `채널명`,
    "cm01_0100.title.channelNameCondition" : `채널명`,
    "cm01_0100.title.channelRsvnNo" : `채널 예약번호`,
    "cm01_0100.title.channelRsvnNoCondition" : `채널 예약번호`,
    "cm01_0100.title.checkInDate" : `입실일자`,
    "cm01_0100.title.checkOutDate" : `퇴실일자`,
    "cm01_0100.title.cmsRsvnNo" : `CMS 예약번호`,
    "cm01_0100.title.cmsRsvnNoCondition" : `CMS 예약번호`,
    "cm01_0100.title.cmsStatus" : `CMS 상태`,
    "cm01_0100.title.complete" : `완료`,
    "cm01_0100.title.confirmMessage" : `채널예약번호 : @(0), @(1) 예약자료를 재전송 하시겠습니까?`,
    "cm01_0100.title.errorEmailSuccess" : `오류 내용이 전송 되었습니다.`,
    "cm01_0100.title.guestName" : `고객명`,
    "cm01_0100.title.hotelRoom" : `호텔객실`,
    "cm01_0100.title.inhouseName" : `투숙객명`,
    "cm01_0100.title.modifyDate" : `수정일자`,
    "cm01_0100.title.modifyDate_1" : `수정일시`,
    "cm01_0100.title.night" : `박수`,
    "cm01_0100.title.normalReservation" : `정상예약`,
    "cm01_0100.title.pmsRsvnNo" : `PMS 예약번호`,
    "cm01_0100.title.pmsRsvnNoCondition" : `PMS 예약번호`,
    "cm01_0100.title.pmsStatus" : `PMS 상태`,
    "cm01_0100.title.popupSuccess" : `성공`,
    "cm01_0100.title.realCheckOutDate" : `실퇴실일자`,
    "cm01_0100.title.resendMessage" : `<div style='text-align:right;margin-top:-20px;margin-bottom:20px;'>데이터가 정상적으로 PMS로 전송되지 못했습니다.</span></div><div>[재전송] 버튼을 클릭하면, 다시 한번 재전송 시도를 합니다.</div><div>[고객센터 접수] 버튼을 클릭 시 자동으로 산하정보기술 고객센터로 메일 접수가 됩니다.</div><br/><div>접수내용은 사용자가 이메일에서 확인 가능합니다.</div>`,
    "cm01_0100.title.resendMessage_1" : `재전송 결과 (1/3) <br/><br/>데이터가 정상적으로 PMS로 전송되지 못햇습니다. <br/><br/>[재전송] 버튼을 클릭 하면, 다시한번 재전송 시도를 합니다. <br/>이 문제에 대해서 산하정보기술로 오류 내용을 보내시려면 [오류 내용 보내기] 버튼을 클릭 해 주세요. <br/>수집된 데이터는 오직 문제를 해결하는데만 사용됩니다. <br/>`,
    "cm01_0100.title.reservationDate" : `예약일자`,
    "cm01_0100.title.reservationList" : `예약 목록`,
    "cm01_0100.title.restore" : `복구`,
    "cm01_0100.title.revenue" : `매출`,
    "cm01_0100.title.roomCount" : `객실수`,
    "cm01_0100.title.sendResult" : `전송결과`,
    "cm01_0100.title.sendStatus" : `전송상태`,
    "cm01_0100.title.successMessage" : `예약번호 : @(0)이(가) PMS로 전송이 완료되었습니다.`,
    "cm01_0100.title.todaySaleResult" : `당일판매실적`,
    "cm01_0100.title.tooltipMessage1" : `CMS에서 PMS로 정상적으로 예약이`,
    "cm01_0100.title.tooltipMessage2" : `전송되지못하는 경우 재전송 버튼이`,
    "cm01_0100.title.tooltipMessage3" : `활성화됩니다.PMS로 예약 전송을 시도해주세요.`,
    "cm01_0100.title.totalAmount" : `총객실료`,
    "cm01_0100.title.userCnfmDt" : `예약확인`,
    "cm01_0110.title.addOption" : `CMS 서비스 코드`,
    "cm01_0110.title.afterData" : `변경 후`,
    "cm01_0110.title.amount" : `합계`,
    "cm01_0110.title.beforeData" : `변경 전`,
    "cm01_0110.title.bfKidFee" : `조식 어린이요금`,
    "cm01_0110.title.bfKidFee_1" : `특별요청`,
    "cm01_0110.title.cancelDate" : `취소일자`,
    "cm01_0110.title.ccCvcExp" : `CC / Exp.`,
    "cm01_0110.title.ccInfo" : `CC 정보`,
    "cm01_0110.title.changeMessage" : `@(0)(이)가 '@(1)'에서 '@(2)'(으)로 변경되었습니다.`,
    "cm01_0110.title.changeMessageWithMore" : `@(0)(이)가 변경되었습니다.`,
    "cm01_0110.title.channelName" : `채널명`,
    "cm01_0110.title.channelRsvnNo" : `채널 예약번호`,
    "cm01_0110.title.checkinDate" : `입실일자`,
    "cm01_0110.title.checkoutDate" : `퇴실일자`,
    "cm01_0110.title.close" : `닫기`,
    "cm01_0110.title.cmsRsvnNo" : `CMS 예약번호`,
    "cm01_0110.title.commision" : `커미션`,
    "cm01_0110.title.count" : `개수`,
    "cm01_0110.title.date" : `일자`,
    "cm01_0110.title.detailInfo" : `상세 정보`,
    "cm01_0110.title.detailViewer" : `상세보기`,
    "cm01_0110.title.etcInfo" : `기타 정보`,
    "cm01_0110.title.fee" : `요금`,
    "cm01_0110.title.history" : `히스토리`,
    "cm01_0110.title.inhouseGuestEmail" : `투숙자 이메일`,
    "cm01_0110.title.inhouseGuestInfo" : `투숙객 정보`,
    "cm01_0110.title.inhouseInfo" : `투숙 정보`,
    "cm01_0110.title.inhouseName" : `투숙객 명`,
    "cm01_0110.title.interMemo" : `인터메모`,
    "cm01_0110.title.memo" : `메모`,
    "cm01_0110.title.mobileNo" : `휴대폰 번호`,
    "cm01_0110.title.nationAndCurreny" : `국가/통화`,
    "cm01_0110.title.noneData" : `공백`,
    "cm01_0110.title.otaRsvnNo" : `OTA 예약번호`,
    "cm01_0110.title.payAndStep" : `지불/단계`,
    "cm01_0110.title.personCount" : `성인/아동/아기`,
    "cm01_0110.title.pmsRoomType" : `채널/PMS 객실타입`,
    "cm01_0110.title.pmsRsvnNo" : `PMS 예약번호`,
    "cm01_0110.title.receiveHistory" : `수신 이력`,
    "cm01_0110.title.reservationName" : `예약자명`,
    "cm01_0110.title.roomCount" : `객실수`,
    "cm01_0110.title.roomFee" : `객실료`,
    "cm01_0110.title.roomFeeInsvc" : `채널 서비스 옵션`,
    "cm01_0110.title.roomType" : `채널/CMS 객실타입`,
    "cm01_0110.title.roommateName" : `동숙자명`,
    "cm01_0110.title.rsvnDate" : `예약일자`,
    "cm01_0110.title.rsvnDetailPopup" : `예약 목록 상세 팝업`,
    "cm01_0110.title.rsvnInfo" : `예약자 정보`,
    "cm01_0110.title.saleInfo" : `판매 정보`,
    "cm01_0110.title.sendStatus" : `전송 상태`,
    "cm01_0110.title.source" : `출처`,
    "cm01_0110.title.storeSob" : `판매처 (SOB)`,
    "cm01_0110.title.svc" : `서비스`,
    "cm01_0110.title.svcName" : `서비스명`,
    "cm01_0110.title.telNo" : `전화번호`,
    "cm01_0110.title.title" : `호칭`,
    "cm01_0110.title.tooltipMessage" : `CMS에서 PMS로 정상적으로 예약이 전송되지못하는 경우 재전송 버튼이 활성화됩니다.PMS로 예약 전송을 시도해주세요.`,
    "cm01_0110.title.totalAmount" : `총 합계`,
    "cm01_0110.title.updateContent" : `업데이트 내용`,
    "cm01_0110.title.updateDate" : `업데이트 일자`,
    "cm01_0110.title.vat" : `부가세`,
    "cm01_0200.button.init" : `초기화`,
    "cm01_0200.button.search" : `검색`,
    "cm01_0200.message.notSyncDate" : `동기화는 오늘 날짜 기준 최대 365일까지 가능합니다.`,
    "cm01_0200.message.selNotRm" : `동기화를 위해 선택된 객실이 없습니다. <br /> 확인 후 다시 시도해주세요.`,
    "cm01_0200.message.success" : `동기화 요청이 완료되었습니다. <br /> 동기화는 최대 5분 정도 소요됩니다.`,
    "cm01_0200.title.all" : `전체`,
    "cm01_0200.title.cancel" : `취소`,
    "cm01_0200.title.date" : `기간`,
    "cm01_0200.title.friday" : `금`,
    "cm01_0200.title.monday" : `월`,
    "cm01_0200.title.pmsSync" : `PMS 동기화`,
    "cm01_0200.title.propertyName" : `프로퍼티명`,
    "cm01_0200.title.room" : `객실`,
    "cm01_0200.title.roomOccupancy" : `점유율`,
    "cm01_0200.title.roomRowTotal" : `객실 수`,
    "cm01_0200.title.roomTotal" : `합계`,
    "cm01_0200.title.roomTotal_1" : `잔여 합계`,
    "cm01_0200.title.roomType" : `객실 타입`,
    "cm01_0200.title.roomTypeCode" : `코드`,
    "cm01_0200.title.saturday" : `토`,
    "cm01_0200.title.sellRoomCount" : `예약된 객실수`,
    "cm01_0200.title.startDate" : `시작일자`,
    "cm01_0200.title.sunday" : `일`,
    "cm01_0200.title.sync" : `동기화`,
    "cm01_0200.title.thursday" : `목`,
    "cm01_0200.title.title" : `잔여 객실 현황`,
    "cm01_0200.title.today" : `오늘`,
    "cm01_0200.title.tuesday" : `화`,
    "cm01_0200.title.wednesday" : `수`,
    "cm01_0300.title.baseTitle" : `판매분석`,
    "cm01_0300.title.dateReportEmptyMessage" : `@(0)년 @(1)월 @(2)일의 실적 자료가 없습니다.`,
    "cm01_0300.title.daySale" : `판매 분석_일간`,
    "cm01_0300.title.monthReportEmptyMessage" : `@(0)년 @(1)월 의 실적 자료가 없습니다.`,
    "cm01_0300.title.monthReportEmptyMessage_1" : `@(0)년의 실적 자료가 없습니다.`,
    "cm01_0300.title.monthSale" : `판매 분석_월간`,
    "cm01_0300.title.sliderCount" : `건`,
    "cm01_0300.title.weekSale" : `판매 분석_주간`,
    "cm01_0300.title.weeklyReportEmptyMessage" : `@(0)주의 실적 자료가 없습니다.`,
    "cm01_0300.title.weeklyReportEmptyMessage_1" : `@(0)년 @(1)월 @(2)일 ~ @(3)년 @(4)월 @(5)일의 실적 자료가 없습니다.`,
    "cm01_0301.buttom.init" : `초기화`,
    "cm01_0301.buttom.search" : `검색`,
    "cm01_0301.etc.gridUnitCount" : `명`,
    "cm01_0301.etc.gridUnitDay" : `박`,
    "cm01_0301.etc.gridUnitNumber" : `건`,
    "cm01_0301.etc.required" : `필수항목`,
    "cm01_0301.etc.sliderCount" : `건`,
    "cm01_0301.title.all" : `전체`,
    "cm01_0301.title.channel" : `채널`,
    "cm01_0301.title.filterChannel" : `채널명`,
    "cm01_0301.title.filterCmRaTy" : `상품그룹명`,
    "cm01_0301.title.filterOption" : `옵션`,
    "cm01_0301.title.filterProperty" : `프로퍼티명`,
    "cm01_0301.title.filterStructure" : `구성`,
    "cm01_0301.title.filterperiod" : `일자`,
    "cm01_0301.title.filterperiodType" : `기간타입`,
    "cm01_0301.title.gridColCount" : `행 갯수`,
    "cm01_0301.title.gridHeaderAdr" : `객단가`,
    "cm01_0301.title.gridHeaderCancelCnt" : `취소`,
    "cm01_0301.title.gridHeaderGuestCnt" : `인원수`,
    "cm01_0301.title.gridHeaderHigntCnt" : `숙박일수`,
    "cm01_0301.title.gridHeaderNoshCnt" : `노쇼`,
    "cm01_0301.title.gridHeaderRoomRev" : `매출`,
    "cm01_0301.title.rev" : `예약`,
    "cm01_0301.title.searchProperty" : `프로퍼티명`,
    "cm01_0301.title.searchperiod" : `일자`,
    "cm01_0301.title.sliderTitle" : `당일 판매 실적`,
    "cm01_0302.buttom.init" : `초기화`,
    "cm01_0302.buttom.search" : `검색`,
    "cm01_0302.etc.gridUnitDay" : `박`,
    "cm01_0302.etc.gridUnitNumber" : `건`,
    "cm01_0302.etc.required" : `필수항목`,
    "cm01_0302.etc.sliderCount" : `건`,
    "cm01_0302.title.all" : `전체`,
    "cm01_0302.title.filterChannel" : `채널명`,
    "cm01_0302.title.filterCmRaTy" : `상품그룹명`,
    "cm01_0302.title.filterOption" : `옵션`,
    "cm01_0302.title.filterProperty" : `프로퍼티명`,
    "cm01_0302.title.filterStructure" : `구성`,
    "cm01_0302.title.filterperiod" : `일자`,
    "cm01_0302.title.filterperiodType" : `기간타입`,
    "cm01_0302.title.gridColCount" : `행 갯수`,
    "cm01_0302.title.gridHeaderFri" : `금`,
    "cm01_0302.title.gridHeaderMon" : `월`,
    "cm01_0302.title.gridHeaderSat" : `토`,
    "cm01_0302.title.gridHeaderSun" : `일`,
    "cm01_0302.title.gridHeaderThu" : `목`,
    "cm01_0302.title.gridHeaderTue" : `화`,
    "cm01_0302.title.gridHeaderWeb" : `수`,
    "cm01_0302.title.proc_dt" : `합계`,
    "cm01_0302.title.searchProperty" : `프로퍼티명`,
    "cm01_0302.title.searchperiod" : `일자`,
    "cm01_0302.title.sliderTitle" : `금주 판매 실적`,
    "cm01_0302.title.userid" : `채널`,
    "cm01_0302.title.username" : `항목`,
    "cm01_0303.buttom.init" : `초기화`,
    "cm01_0303.buttom.search" : `검색`,
    "cm01_0303.etc.gridUnitDay" : `박`,
    "cm01_0303.etc.gridUnitNumber" : `건`,
    "cm01_0303.etc.required" : `필수항목`,
    "cm01_0303.etc.sliderCount" : `건`,
    "cm01_0303.title.all" : `전체`,
    "cm01_0303.title.filterChannel" : `채널명`,
    "cm01_0303.title.filterCmRaTy" : `상품그룹명`,
    "cm01_0303.title.filterOption" : `옵션`,
    "cm01_0303.title.filterProperty" : `프로퍼티명`,
    "cm01_0303.title.filterStructure" : `구성`,
    "cm01_0303.title.filterperiod" : `일자`,
    "cm01_0303.title.filterperiodType" : `기간타입`,
    "cm01_0303.title.gridColCount" : `행 갯수`,
    "cm01_0303.title.gridHeaderApr" : `4월`,
    "cm01_0303.title.gridHeaderAug" : `8월`,
    "cm01_0303.title.gridHeaderFeb" : `2월`,
    "cm01_0303.title.gridHeaderJan" : `1월`,
    "cm01_0303.title.gridHeaderJul" : `7월`,
    "cm01_0303.title.gridHeaderJun" : `6월`,
    "cm01_0303.title.gridHeaderMar" : `3월`,
    "cm01_0303.title.gridHeaderMay" : `5월`,
    "cm01_0303.title.gridHeaderSept" : `9월`,
    "cm01_0303.title.gridHeaderdev" : `12월`,
    "cm01_0303.title.gridHeadernov" : `11월`,
    "cm01_0303.title.gridHeaderoct" : `10월`,
    "cm01_0303.title.proc_dt" : `합계`,
    "cm01_0303.title.searchProperty" : `프로퍼티명`,
    "cm01_0303.title.searchperiod" : `기간`,
    "cm01_0303.title.sliderTitle" : `이 달 판매 실적`,
    "cm01_0303.title.userid" : `채널`,
    "cm01_0303.title.username" : `항목`,
    "cm01_0400.buttom.init" : `초기화`,
    "cm01_0400.buttom.search" : `검색`,
    "cm01_0400.etc.required" : `필수항목`,
    "cm01_0400.title.gridHeaderChangeDate" : `변경일자`,
    "cm01_0400.title.gridHeaderChannelName" : `채널명`,
    "cm01_0400.title.gridHeaderChannelProductName" : `채널 상품명`,
    "cm01_0400.title.gridHeaderHotelRoomName" : `호텔 객실명`,
    "cm01_0400.title.gridHeaderProductGorupName" : `상품 그룹명`,
    "cm01_0400.title.gridHeaderPropertyName" : `프로퍼티 명`,
    "cm01_0400.title.gridHeaderRoomGroupName" : `객실 그룹명`,
    "cm01_0400.title.gridHeaderSaleDate" : `판매일자`,
    "cm01_0400.title.gridHeaderUserIp" : `처리자IP`,
    "cm01_0400.title.gridHeaderUserName" : `처리자`,
    "cm01_0400.title.gridHeaderWorkHistory" : `작업내역`,
    "cm01_0400.title.searchChannel" : `채널`,
    "cm01_0400.title.searchChannelProductName" : `채널상품명`,
    "cm01_0400.title.searchDivision" : `구분`,
    "cm01_0400.title.searchHotelRoomName" : `호텔객실명`,
    "cm01_0400.title.searchPeriodSale" : `판매기간`,
    "cm01_0400.title.searchProductGroupName" : `상풍그룹명`,
    "cm01_0400.title.searchProperty" : `프로퍼터 명`,
    "cm01_0400.title.searchRoomGroupName" : `객실그룹명`,
    "cm01_0400.title.searchUserName" : `처리자`,
    "cm01_0400.title.searchperiodType" : `변경기간`,
    "cm01_0400.validation.warningMessageProcDate" : `처리기간은 31일을 넘어갈 수 없습니다.`,
    "cm01_0600.buttom.init" : `초기화`,
    "cm01_0600.buttom.search" : `검색`,
    "cm01_0600.etc.required" : `필수항목`,
    "cm01_0600.title.filterChannelProductName" : `채널상품명`,
    "cm01_0600.title.filterDivision" : `구분`,
    "cm01_0600.title.filterHotelRoomName" : `호텔객실명`,
    "cm01_0600.title.filterPeriodSale" : `판매기간`,
    "cm01_0600.title.filterProductGroupName" : `상풍그룹명`,
    "cm01_0600.title.filterProperty" : `프로퍼터 명`,
    "cm01_0600.title.filterRoomGroupName" : `객실그룹명`,
    "cm01_0600.title.filterUserName" : `처리자`,
    "cm01_0600.title.filterperiodType" : `변경기간`,
    "cm01_0600.title.filterperiodType_1" : `전송기간`,
    "cm01_0600.title.gridHeaderAllocationNumber" : `할당수`,
    "cm01_0600.title.gridHeaderChannelChargeType" : `채널요금명`,
    "cm01_0600.title.gridHeaderChannelName" : `채널명`,
    "cm01_0600.title.gridHeaderChannelRoomName" : `채널객실명`,
    "cm01_0600.title.gridHeaderCheckInDeadline" : `입실마감`,
    "cm01_0600.title.gridHeaderCheckOutDeadline" : `퇴실마감`,
    "cm01_0600.title.gridHeaderDeadline" : `마감여부`,
    "cm01_0600.title.gridHeaderEndDate" : `종료일자`,
    "cm01_0600.title.gridHeaderMaxStayPeriod" : `최소투숙기간`,
    "cm01_0600.title.gridHeaderMinStayPeriod" : `최대투숙기간`,
    "cm01_0600.title.gridHeaderRoomRate" : `객실료`,
    "cm01_0600.title.gridHeaderStartDate" : `시작일자`,
    "cm01_0600.title.gridHeaderTransferResult" : `전송결과`,
    "cm01_0600.title.popupClose" : `닫기`,
    "cm01_0600.title.popupDetailInfo" : `상세 정보`,
    "cm01_0600.title.popupProcessingResult" : `처리결과`,
    "cm01_0600.title.popupStayInfo" : `투숙 정보`,
    "cm01_0600.title.popupTransferStatus" : `전송 상태`,
    "cm01_0600.title.popupTransferStatusMessage" : `CMS에서 채널로 전송된 데이터가 정상적으로 전송되었는지 확인할 수있습니다.`,
    "cm01_0600.title.searchChannel" : `채널`,
    "cm01_0600.validation.warningMessageProcDate" : `처리기간은 31일을 넘어갈 수 없습니다.`,
    "cm02_0100.button.init" : `초기화`,
    "cm02_0100.button.search" : `검색`,
    "cm02_0100.message.addChnlRate" : `호텔 계약사항에 따라 요금이 상이할 수 있습니다.`,
    "cm02_0100.title.ADD_CHNL_AGREE_STAY" : `연동 승인 대기 중입니다.`,
    "cm02_0100.title.ADD_CHNL_BTN_NO" : `아니오`,
    "cm02_0100.title.ADD_CHNL_BTN_YES" : `예`,
    "cm02_0100.title.ADD_CHNL_NOT_OPEN_USER" : `사용자가 오픈 진행할 수 없는 채널입니다.`,
    "cm02_0100.title.ADD_CHNL_YN_MSG_1" : `계약 채널 수가 초과 되었습니다. <br/>채널을 추가하시겠습니까?`,
    "cm02_0100.title.ADD_CHNL_YN_MSG_2" : `채널 추가 시 월 2만원(부가세별도)이 청구됩니다.`,
    "cm02_0100.title.ADD_CHNL_YN_MSG_3" : `채널 추가 시 월 5만원(부가세별도)이 청구됩니다.`,
    "cm02_0100.title.BTN_CERT" : `인증확인하기`,
    "cm02_0100.title.BTN_MANUAL_NON" : `등록된 매뉴얼이 없습니다.`,
    "cm02_0100.title.BTN_PREPARING" : `준비중입니다.`,
    "cm02_0100.title.CERT_CHECK" : `채널 인증 확인`,
    "cm02_0100.title.CERT_CHECK_TOOLTIP" : `채널의 호텔ID를 입력해주세요. 채널의 엑스트라넷의 호텔 ID 또는 채널에서 제공한 인증 ID (Product ID  등)를 입력해주세요.`,
    "cm02_0100.title.CHNL_PROCESS_CANCEL" : `연동 진행을 취소합니다. <br/>연동 진행 취소 하시겠습니까?`,
    "cm02_0100.title.CM02_0100_CHNL_CMPL_RELEASE" : `@(0) 채널 연동 해지 합니다. 연동 해지 하시겠습니까?`,
    "cm02_0100.title.CM02_0100_CHNL_CMPL_RELEASE_FAILE" : `연동해지는 호텔관리자만 가능합니다`,
    "cm02_0100.title.CM02_0100_FREE_CHNL" : `[무료 채널]`,
    "cm02_0100.title.CM02_0100_HOTEL_ID_CHECK" : `호텔 ID 를 입력해 주세요.`,
    "cm02_0100.title.CM02_0100_MANAGE_SVC" : `채널 서비스 매핑 관리`,
    "cm02_0100.title.CM02_0100_REAL_CHNL_CNT3" : `실 연동 채널 수(무료 채널)`,
    "cm02_0100.title.CertFail" : `채널 인증 확인 되지 않습니다.`,
    "cm02_0100.title.CertSuccess" : `채널 인증 확인 되었습니다.`,
    "cm02_0100.title.ChannelDisconect" : `연동 해지`,
    "cm02_0100.title.ChannelURL" : `채널 엑스트라넷`,
    "cm02_0100.title.ConnCancel" : `연동취소`,
    "cm02_0100.title.ConnCompl" : `연동완료`,
    "cm02_0100.title.ConnReq" : `연동추가요청`,
    "cm02_0100.title.DownManual" : `매뉴얼 다운`,
    "cm02_0100.title.ManageChannelInfo" : `채널 정보 설정`,
    "cm02_0100.title.ManagePlan" : `플랜 관리`,
    "cm02_0100.title.ManageRoom" : `객실 및 상품 관리`,
    "cm02_0100.title.OpenTutorial" : `튜토리얼 보기`,
    "cm02_0100.title.RqChannelInfo" : `채널 정보 호출`,
    "cm02_0100.title.SaveSuccess" : `저장 처리 되었습니다.`,
    "cm02_0100.title.cancel" : `다음`,
    "cm02_0100.title.cancelSuccess" : `연동 취소처리 되었습니다.`,
    "cm02_0100.title.channelInformation" : `채널매니저 정보`,
    "cm02_0100.title.complChannel" : `연동완료 채널`,
    "cm02_0100.title.complChannelTooltip" : `현재 연동중인 채널 리스트가 보여집니다. 연동 해지 시 CMS를 통해서 채널 관리가 불가능합니다.`,
    "cm02_0100.title.contChnlCNT" : `계약 채널 수`,
    "cm02_0100.title.contChnlCntExceptFree" : `계약 채널 수 (무료 채널 제외)`,
    "cm02_0100.title.next" : `저장`,
    "cm02_0100.title.notConnChannel" : `미연동 채널`,
    "cm02_0100.title.notConnChannelTooltip" : `추가 연동이 가능한 채널입니다. 무료 연동 채널수가 초과되면, 계약조건에 따라 요금이 부과됩니다.`,
    "cm02_0100.title.notUseSearch" : `비 활성화 객실 그룹 포함`,
    "cm02_0100.title.prev" : `이전`,
    "cm02_0100.title.processChannel" : `연동중 채널`,
    "cm02_0100.title.processChannelTooltip" : `연동 진행 중인 채널 리스트가 보여집니다. 연동 진행중 채널도 무료 연동 채널에 포함됩니다.`,
    "cm02_0100.title.realChnlCNT" : `실 연동 채널 수`,
    "cm02_0100.title.realChnlCNT2" : `실 연동 채널 수(추가 연동)`,
    "cm02_0100.title.realChnlCNTnotfree" : `실 연동 채널 수(무료 채널 제외)`,
    "cm02_0100.title.releaseSuccess" : `연동 해지처리 되었습니다.`,
    "cm02_0100.title.searchPromotion" : `프로모션 상품 조회`,
    "cm02_0100.title.title" : `채널관리`,
    "cm02_0200.title.RQ_ADD_CHANNEL" : `추가 연동 신청`,
    "cm02_0200.title.RQ_ADD_CHNL_MSG_1" : `※ 채널 연동 신청을 원하시는 경우 아래 내용을 작성 해주세요.`,
    "cm02_0200.title.RQ_ADD_CHNL_MSG_2" : `신청 이후 [승인]까지 영업일기준 최대 [3일] 정도 소요됩니다. [승인]이 완료되면 사용자가 직접 설정이 가능합니다.`,
    "cm02_0200.title.RQ_BTN_CANCEL" : `취소`,
    "cm02_0200.title.RQ_BTN_CLOSE" : `닫기`,
    "cm02_0200.title.RQ_BTN_SEND" : `신청`,
    "cm02_0200.title.RQ_BTN_SEND_MSG_1" : `완료되었습니다.`,
    "cm02_0200.title.RQ_BTN_SEND_MSG_2" : `연동 신청 이후 [승인]까지 영업일기준 최대 [3일] 정도 소요됩니다.`,
    "cm02_0200.title.RQ_EMAIL" : `신청자 이메일`,
    "cm02_0200.title.RQ_MAIL_CHECK" : `신청자 E-mail을 확인하세요.`,
    "cm02_0200.title.RQ_NAME" : `신청자 명`,
    "cm02_0200.title.RQ_NAME_CHECK" : `신청자 명을 확인하세요.`,
    "cm02_0200.title.RQ_TEL" : `신청자 연락처`,
    "cm02_0200.title.RQ_TELL_CHECK" : `신청자 연락처를 확인하세요.`,
    "cm02_0300.title.RQ_ADD_CHNL_MSG_1" : `※ 원활한 채널 연동을 위해 신청하신 채널의 관리자페이지(엑스트라넷)을 접속 할 수 있는 ID와 비밀번호를 정확하게 기재하여 주시기바랍니다. 올바르지 않은 정보를 입력할 경우 연동이 지연될 수 있습니다.`,
    "cm02_0300.title.RQ_BTN_SEND_MSG_1" : `연동 신청 이후 [연동]까지 영업일기준 최대 [14일] 정도 소요됩니다.`,
    "cm02_0300.title.RQ_CHANNEL" : `신청 채널 명`,
    "cm02_0300.title.RQ_CHANNEL_ID" : `엑스트라넷 ID`,
    "cm02_0300.title.RQ_CHANNEL_PW" : `엑스트라넷 비밀번호`,
    "cm02_0300.title.RQ_ID_CHECK" : `엑스트라넷 ID를 확인하세요.`,
    "cm02_0300.title.RQ_PW_CHECK" : `엑스트라넷 비밀번호를 확인하세요.`,
    "cm02_0400.title.RQ_AGREE_CHECK" : `위 내용을 확인 후 동의 체크하여 주시기 바랍니다.`,
    "cm02_0400.title.RQ_CONTRACT_MSG_1" : `※ 채널 추가 연동`,
    "cm02_0400.title.RQ_CONTRACT_MSG_10" : `- PMS 예약 환경 구축`,
    "cm02_0400.title.RQ_CONTRACT_MSG_11" : `- OTA 요금 및 객실 할당량 동기화`,
    "cm02_0400.title.RQ_CONTRACT_MSG_12" : `- 오픈 및 안정화`,
    "cm02_0400.title.RQ_CONTRACT_MSG_13" : `- 운영지원`,
    "cm02_0400.title.RQ_CONTRACT_MSG_14" : `합계`,
    "cm02_0400.title.RQ_CONTRACT_MSG_15" : `월 청구 비용`,
    "cm02_0400.title.RQ_CONTRACT_MSG_16" : `신청일자`,
    "cm02_0400.title.RQ_CONTRACT_MSG_2" : `(단위 : 원, 부가세포함)`,
    "cm02_0400.title.RQ_CONTRACT_MSG_3" : `구분`,
    "cm02_0400.title.RQ_CONTRACT_MSG_4" : `내역`,
    "cm02_0400.title.RQ_CONTRACT_MSG_5" : `단가`,
    "cm02_0400.title.RQ_CONTRACT_MSG_6" : `수량`,
    "cm02_0400.title.RQ_CONTRACT_MSG_7" : `금액`,
    "cm02_0400.title.RQ_CONTRACT_MSG_8" : `비고`,
    "cm02_0400.title.RQ_CONTRACT_MSG_9" : `CMS 추가채널 연동`,
    "cm02_0500.title.CM02_0500_CHNL_REQ_ERR" : `요청 채널의 연동 진행단계가 없습니다.<br/>관리자에게 문의하시기 바랍니다.`,
    "cm02_0600.title.ACCOUNT" : `거래처`,
    "cm02_0600.title.ACCOUNT_TIP" : `거래처 코드 입력 (숫자만입력)`,
    "cm02_0600.title.ACCOUNT_TOOLTIP" : `PMS메뉴 Company Profile에 등록된 Account의 Company No. (숫자) 를 입력합니다.<br/>(미조회 시 NEW 로 신규 생성)`,
    "cm02_0600.title.CHNL_VAT_TYPE" : `현장결제 부가세 포함`,
    "cm02_0600.title.CM02_0600_CN_HOTEL_ID_CHECK" : `이미 등록된 채널 호텔ID 입니다. 다시 확인해주세요.`,
    "cm02_0600.title.CM02_0600_DELETE_MSG1" : `초기화 버튼 클릭시 모든 단계의 연동정보가 초기화 됩니다.`,
    "cm02_0600.title.CM02_0600_DELETE_MSG2" : `초기화를 진행하시겠습니까?`,
    "cm02_0600.title.COMMITION" : `수수료(%)`,
    "cm02_0600.title.EXTRANET" : `엑스트라넷`,
    "cm02_0600.title.HOTEL_ID" : `호텔 ID`,
    "cm02_0600.title.HOTEL_ID_INPUT" : `채널 호텔 ID 입력`,
    "cm02_0600.title.HOTEL_ID_TOOLTIP" : `채널의 고유 호텔 ID를 말합니다. 채널의 엑스트라넷의 호텔 ID또는 채널에서 제공한 인증 ID (Product ID / EQC Key등 )을 입력해주세요.`,
    "cm02_0600.title.HOTEL_INPUT_CHECK" : `채널 호텔 정보를 입력 하세요.`,
    "cm02_0600.title.MARKET_TYPE" : `마켓타입`,
    "cm02_0600.title.MARKET_TYPE_TOOLTIP" : `채널을 통해서 예약이 발생될때 PMS에 등록될 시장타입(Market type)을 선택합니다`,
    "cm02_0600.title.PAY_CHANNEL" : `채널결제`,
    "cm02_0600.title.PAY_CHANNEL_TOOLTIP" : `손님이 채널을 통해서 미리 결제하고 호텔에 투숙하는 경우를 말합니다. 거래처 코드는 PMS와 연동된 경우에만 입력해주세요.`,
    "cm02_0600.title.PAY_HOTEL" : `현장결제`,
    "cm02_0600.title.PAY_HOTEL_TOOLTIP" : `손님이 호텔에서 직접 결제하는 경우를 말합니다.거래처 코드는 PMS와 연동된 경우에만 입력해주세요.`,
    "cm02_0600.title.PAY_SELECT" : `현장결제 / 채널결제 구분`,
    "cm02_0600.title.PAY_SELECT_TOOLTIP" : `채널에서 현장결제와 채널결제 방식을 제공하는 경우, 예약 유형에 따라 PMS Account를 각각 다르게 설정할 수 있습니다. (단, 채널에서 결제 구분을 다르게 전송해 줄 경우에만 가능합니다.)`,
    "cm02_0600.title.RATE_SEND" : `요금 전송`,
    "cm02_0600.title.RATE_SEND_TOOLTIP" : `채널매니저에서 채널엑스트라넷으로 요금을 전송할때 입금가로 전송하는지 판매가로 전송하는지에 대해 선택할 수 있습니다.`,
    "cm02_0600.title.REQUIRED_ITEM" : `필수항목`,
    "cm02_0600.title.RESET" : `초기화`,
    "cm02_0600.title.SALESPERSON" : `영업직원`,
    "cm02_0600.title.SALESPERSON_TOOLTIP" : `채널을 통해서 예약이 발생될때 PMS에 등록될 영업직원(Sales person)을 선택합니다`,
    "cm02_0600.title.SORC_TYPE_TOOLTIP" : `채널을 통해서 예약이 발생될때 PMS에 등록될 소스타입(Sorce type)을 선택합니다`,
    "cm02_0600.title.STEP_EXTRANET_NONE" : `등록된 채널 엑스트라넷이 없습니다.`,
    "cm02_0600.title.STEP_ID_DELETE" : `채널 호텔 ID 초기화 됐습니다.`,
    "cm02_0600.title.STEP_REQUIRED_CHEK" : `신청일자`,
    "cm02_0600.title.STEP_RQ_ERROR" : `채널 Data 호출 처리시 에러가 발생하였습니다. 관리자에게 문의 하세요.`,
    "cm02_0600.title.VAT_TYPE" : `부가세 포함`,
    "cm02_0600.title.VAT_TYPE_TOOLTIP" : `채널을 통해 예약이 발생될 때 부가세율/서비스율이 나눠지는지에 대한 여부를 선택합니다.`,
    "cm02_0600.title.airbnbConnect" : `권한부여 취득 URL 열기`,
    "cm02_0700.btn.wyndhamCreateRoom" : `WYNDHAM 객실 추가`,
    "cm02_0700.message.noMapping" : `매핑이 안된 객실상품이 존재합니다.<br/>매핑하시기 바랍니다.`,
    "cm02_0800.btn.wyndhamCreateProduct" : `WYNDHAM 상품 추가`,
    "cm02_0850.title.CM02_0850_EXTRA_SETTING" : `추가설정`,
    "cm02_0850.title.CM02_0850_NOT_CHANGE_DATA" : `변경된 Data가 없습니다.`,
    "cm02_0851.button.save" : `저장`,
    "cm02_0851.button.search" : `검색`,
    "cm02_0851.button.searchInitialize" : `초기화`,
    "cm02_0851.title.SaveSuccess" : `저장 처리 되었습니다.`,
    "cm02_0851.title.channelName" : `채널명`,
    "cm02_0851.title.emptyData" : `체크된 채널 서비스코드 매핑 리스트중 빈 항목이 존재합니다.`,
    "cm02_0851.title.notChangeData" : `변경된 데이터가 없습니다.`,
    "cm02_0851.title.reservationList" : `서비스 매핑`,
    "cm02_0851.title.svcMapToolTip" : `채널에서 기본적으로 제공하는 서비스 코드를 매핑할수있습니다.<BR/>예약이 입력될 때 PMS로 서비스 코드를 받으려면 서비스 매핑 진행해주세요.`,
    "cm02_0851.titlen.svcMap" : `채널 서비스코드 매핑`,
    "cm02_0900.title.ALLOT_CLOSE" : `모든 객실 판매 마감 전송`,
    "cm02_0900.title.ALLOT_CLOSE_TIP" : `채널에 연동되어있는 전체 객싩타입이 일괄 마감되어 판매가 중단되었는지 테스트 합니다.`,
    "cm02_0900.title.ALLOT_CLOSE_TIP_CONTENT" : `채널에 연동되어있는 전체 객실타입이 일괄 마감되어 판매가 중단되었는지 테스트 합니다.`,
    "cm02_0900.title.ALLOT_OPEN" : `모든 객실 판매 오픈 전송`,
    "cm02_0900.title.ALLOT_OPEN_TIP" : `채널에 연동되어있는 전체 객싩타입이 일괄 오픈되어 판매가 가능한지 테스트 합니다.`,
    "cm02_0900.title.ALLOT_OPEN_TIP_CONTENT" : `채널에 연동되어있는 전체 객실타입이 일괄 오픈되어 판매가 가능한지 테스트 합니다.`,
    "cm02_0900.title.ALLOT_TEST" : `할당/마감 테스트`,
    "cm02_0900.title.BTN_ALLOT_PAGE" : `[인벤토리 관리] 바로가기`,
    "cm02_0900.title.BTN_COMPLETE" : `완료`,
    "cm02_0900.title.BTN_RATE_PAGE" : `[요금 관리] 바로가기`,
    "cm02_0900.title.BTN_RATE_SEND" : `요금 전송`,
    "cm02_0900.title.BTN_RATE_SEND_TIP" : `입력한 요금이 채널에 정상적으로 반영되었는지 테스트 합니다.`,
    "cm02_0900.title.BTN_RATE_SEND_TIP2" : `입력한 요금이 채널에 정상적으로 반영되었는지 테스트 합니다. 만약 엑스트라넷에 요금반영이 안 될 경우, 하단의 “모든 객실 판매 오픈 전송” 후 재시도 해주세요.`,
    "cm02_0900.title.CM02_0900_DATE_CHECK_2" : `기간은 30일 이상 선택 할 수 없습니다.`,
    "cm02_0900.title.CM02_0900_LAST_SEND_TIME" : `최종 전송 일시 : `,
    "cm02_0900.title.CM02_0900_MESSAGE_3" : `요금 전송 테스트 하지 않은 객실은 할당/마감 전송하지 않습니다. 요금 전송 테스트 후 진행하여 주세요.`,
    "cm02_0900.title.CM02_0900_RATE_SEND_TEST_CHECK" : `요금 전송 테스트 후 진행하여 주세요.`,
    "cm02_0900.title.CM02_0900_SVC_MAP" : `채널 서비스코드 매핑`,
    "cm02_0900.title.CM02_0900_SVC_MAP_BTN" : `서비스 매핑`,
    "cm02_0900.title.CM02_0900_SVC_MAP_TOOLTIP" : `채널에서 기본적으로 제공하는 서비스 코드를 매핑할 수 있습니다.<br/>예약이 입력될 때 PMS로 서비스 코드를 받으려면 서비스매핑을 진행해 주세요.`,
    "cm02_0900.title.CM02_0900_TEST_CHECK" : `요금 및 할당/마감 테스트를 진행해 주세요.`,
    "cm02_0900.title.CM_RA_TY_NAME" : `상품그룹 명 (채널상품 명)`,
    "cm02_0900.title.COMPLETE_MSG1" : `연동이 완료되었습니다.`,
    "cm02_0900.title.COMPLETE_MSG2" : `요금과 인벤토리 관리는 채널매니저를 통해서 진행 해 주세요.`,
    "cm02_0900.title.DATA_SEND_SUCCESS" : `전송 처리 됐습니다.`,
    "cm02_0900.title.DATE_CHECK" : `지난 일자는 선택 할 수 없습니다.`,
    "cm02_0900.title.MESSAGE_1" : `축하합니다! 이제 연동이 거의 다 끝났습니다.`,
    "cm02_0900.title.MESSAGE_2" : `채널의 엑스트라넷에 접속하여 아래 입력된 데이터가 올바로 연동되는지 확인 후 하단의 [완료] 버튼을 클릭하여 연동을 마쳐주세요.`,
    "cm02_0900.title.PERIOD" : `기간`,
    "cm02_0900.title.PM_RM_NAME" : `호텔객실타입`,
    "cm02_0900.title.RATE_TEST" : `요금 테스트`,
    "cm02_0900.title.RATE_TEST_TOOLTIP" : `요금테스트 시 0원으로 입력 하시면 실제 해당 요금으로 판매될 수 있습니다. 테스트 시 요금은 실제 판매되는 요금보다 높은 금액으로 진행 해 주세요.`,
    "cm02_0900.title.RM_RA" : `요금`,
    "cm02_0900.title.SET_RATE" : `직접 입력`,
    "cm02_0900.title.SET_RATE_NULL" : `입력한 요금이 없습니다.`,
    "cm02_0900.title.UNUSED" : `미사용`,
    "cm02_0900.title.USE" : `사용`,
    "cm02_0900.title.addRsvnMemoMsg" : `입력한 내용이 채널에서 전송된 예약의 Inter Memo에 추가됩니다`,
    "cm02_0900.title.beEnterpriseConnect" : `기업체코드 연동`,
    "cm02_0900.title.beEnterpriseControl" : `기업체 관리`,
    "cm02_0900.title.beFaciEquip" : `시설 & 비품`,
    "cm02_0900.title.beHotelInfo" : `호텔정보 설정`,
    "cm02_0900.title.bePackageGroup" : `패키지 그룹`,
    "cm02_0900.title.bePromotionControl" : `프로모션 관리`,
    "cm02_0900.title.beRatePlan" : `요금플랜(프로모션) 설정`,
    "cm02_0900.title.beReservationControl" : `예약 관리`,
    "cm02_0900.title.beRoomInformation" : `객실 정보`,
    "cm02_0900.title.beRoomPackage" : `객실 & 패키지 설명`,
    "cm02_0900.title.beServiceOption" : `서비스 옵션`,
    "cm02_0900.title.bsSnsConnect" : `SNS 연동`,
    "cm02_0900.title.completeNotice" : `반드시 호텔 담당자는 객실 타입 별 요금 확인을 포함한 최종 연동 상태를 확인해야 하며, 이를 확인하지 않았을 경우 발생되는 모든 이슈에 대해서 당사에서는 책임을 지지 않습니다.`,
    "cm02_0900.title.completeNoticeInterpark" : `연동완료 후 인터파크에서 CMS 연동 완료 내용 전달 및 채널 상품의 동기화요청이 필요합니다. (인터파크 담당자에게 요청)`,
    "cm02_0900.title.completeNoticeTidesquare" : `테스트단계시 입력한 정보는 타이드스퀘어 엑스트라넷 내 우측 상단에 위치한 계정정보 에서 '시설명 [SH]' 계정으로 변경 후 확인 부탁 드립니다.`,
    "cm02_0900.title.completeNoticeTripbtoz" : `연동완료 후 트립비토즈에게 CMS 연동 완료 내용 전달 및 CMS 연동작업(약 1분 소요) 요청이 필요합니다. (트립비토즈 담당자에게 요청)`,
    "cm02_1000.title.ADD_INTER_MEMO" : `추가 예약메모`,
    "cm02_1000.title.B2B_ACCOUNT" : `B2B 거래처 구분`,
    "cm02_1000.title.BTN_MANAGE" : `관리`,
    "cm02_1000.title.RCPT_PRINT" : `영수증 출력여부`,
    "cm02_1000.title.REQUIRED_MSG" : `필수 정보를 입력하여 주세요.`,
    "cm02_1000.title.RESERVATION_INFO" : `PMS 정보설정`,
    "cm02_1000.title.SOURCE_TYPE" : `예약경로`,
    "cm02_1100.title.CN_SORC_NAME" : `업체명`,
    "cm02_1100.title.CORP_CUSTM_NO" : `거래처코드`,
    "cm02_1100.title.CORP_MANAGE" : `채널명 B2B 거래처 관리`,
    "cm03_0100.button.no" : `아니오`,
    "cm03_0100.button.productGroupProperty" : `프로덕트 코드 속성`,
    "cm03_0100.button.ratePlanProperty" : `요금플랜 속성`,
    "cm03_0100.button.roomTypeProperty" : `객실타입 속성`,
    "cm03_0100.button.yes" : `예`,
    "cm03_0100.message.channelCallMsg" : `연동된 채널에 등록 되어 있는 모든 객실타입과 플랜이 호출됩니다. 이후 동기화된 OTA 데이터(객실타입 / 플랜) 를 설정 해야 합니다.`,
    "cm03_0100.message.productGroup" : `채널 프로덕트 코드 전송정보를 설정할 수 있습니다. (Rate Plan V2)`,
    "cm03_0100.message.productMsg" : `[연동 채널 호출] 되어있는 객실과 상품을 맵핑시켜야 CMS상에서 데이터가 OTA로 전송됩니다.`,
    "cm03_0100.message.productOrderModifyMsg" : `상품이 매핑되어있는 상태를 한 눈에 볼 수 있고, 노출되는 순서를 변경할 수 있습니다.`,
    "cm03_0100.message.qChannelInfoCall" : `채널들의 정보를 가져오시겠습니까?`,
    "cm03_0100.message.ratePlan" : `요금플랜 채널 전송정보를 설정할 수 있습니다. (Rate Plan V2)`,
    "cm03_0100.message.roomOrderModifyMsg" : `객실이 매핑되어있는 상태를 한 눈에 볼 수 있고, 노출되는 순서를 변경할 수 있습니다.`,
    "cm03_0100.message.roomType" : `객실타입 채널 전송정보를 설정할 수 있습니다.`,
    "cm03_0100.message.serviceCodeRegMsg" : `상품에 구성되어있는 서비스 코드를 관리합니다.`,
    "cm03_0100.message.serviceProductMappingMsg" : `채널의 서비스 상품과 호텔 서비스 상품을 매핑합니다.`,
    "cm03_0100.message.settingListMsg" : `상품을 매핑 및 그룹핑 한 이력을 확인할 수 있습니다.`,
    "cm03_0100.title.channelCall" : `연동 채널 정보 호출`,
    "cm03_0100.title.channelSetting" : `채널 정보 설정`,
    "cm03_0100.title.friday" : `금`,
    "cm03_0100.title.lastUpdateDate" : `최종 업데이트일자 :`,
    "cm03_0100.title.monday" : `월`,
    "cm03_0100.title.noUpdate" : `업데이트 내역이 존재하지 않습니다.`,
    "cm03_0100.title.productManage" : `객실 및 상품 관리`,
    "cm03_0100.title.productOrderModify" : `상품 순서 변경`,
    "cm03_0100.title.roomOrderModify" : `객실 순서 변경`,
    "cm03_0100.title.saturday" : `토`,
    "cm03_0100.title.serviceCodeReg" : `서비스 코드 등록`,
    "cm03_0100.title.serviceProductMapping" : `서비스 상품 매핑`,
    "cm03_0100.title.settingList" : `설정 이력`,
    "cm03_0100.title.sunday" : `일`,
    "cm03_0100.title.thursday" : `목`,
    "cm03_0100.title.tuesday" : `화`,
    "cm03_0100.title.wednesday" : `수`,
    "cm03_0101.button.call" : `호출`,
    "cm03_0101.button.channelInfoCall" : `연동 채널 정보 호출`,
    "cm03_0101.button.no" : `아니오`,
    "cm03_0101.button.yes" : `예`,
    "cm03_0101.message.errorCall" : `채널 Data 호출 처리시 에러가 발생하였습니다.`,
    "cm03_0101.message.qChannelInfoCall" : `채널 정보를 가져오시겠습니까?`,
    "cm03_0101.message.successCall" : `호출 성공`,
    "cm03_0101.title.callResult" : `호출 결과`,
    "cm03_0101.title.channelCall" : `채널 정보 호출`,
    "cm03_0101.title.channelName" : `채널명`,
    "cm03_0101.title.lastCallTime" : `최근 동기화 시간`,
    "cm03_0101.title.noData" : `데이터 없음`,
    "cm03_0200.button.copy" : `복사`,
    "cm03_0200.message.checkDate" : `날짜를 확인해 주세요.`,
    "cm03_0200.message.checkRoomType" : `객실타입을 선택해 주세요.`,
    "cm03_0200.message.daySelect" : `요일을 선택해 주세요.`,
    "cm03_0200.message.inputControlCurrency" : `조정금액을 입력해 주세요.`,
    "cm03_0200.message.inputControlRate" : `조정비율을 입력해 주세요.`,
    "cm03_0200.message.inputRate" : `요금을 입력해 주세요.`,
    "cm03_0200.message.modifyRate1" : `<div style='color:red'>요금 수정 시 하기와 같이 변경 되므로 유의 바랍니다.</div><br />`,
    "cm03_0200.message.modifyRate2" : `V 요금세트(OCC 설정요금 포함)가 해제됩니다. <br />`,
    "cm03_0200.message.modifyRate3" : `V 수정하지 않은 요금은 설정되었던 세트요금이 유지 됩니다.`,
    "cm03_0200.message.modifyRate4" : `<div style='color:red'>요금 수정 시 아래와 같이 변경 되므로 유의 바랍니다.</div><br />`,
    "cm03_0200.message.noCopyData" : `복사할 데이터가 없습니다.`,
    "cm03_0200.message.noZero" : `0은 입력할 수 없습니다.`,
    "cm03_0200.message.personTooltip1" : `*3인부터 10,000원 추가요금이 발생할 경우* <br />기준인원+1인 = 공백 <br />기준인원+2인 = 10,000원`,
    "cm03_0200.message.personTooltip2" : `추가요금 설정 후 <데이터 동기화 - 요금 및 인벤토리 재전송> 기능을 이용하여 동기화 처리 후 엑스트라넷에 요금이 반영되었는지 확인해주세요.`,
    "cm03_0200.message.personTooltip3" : `위와 같이 설정시 2인까지는 요금관리에 입력된 요금으로 전송되며, 3인부터 추가요금 10,000원이 설정됩니다.`,
    "cm03_0200.message.rateLimit" : `최소요금 : @(0) <br /> 최대요금 : @(1) <br /> 입력된 요금을 확인하세요.`,
    "cm03_0200.message.rateLimit2" : `최소요금 : @(0) <br /> 입력된 요금을 확인하세요.`,
    "cm03_0200.message.rateLimit3" : `최대요금 : @(0) <br /> 입력된 요금을 확인하세요.`,
    "cm03_0200.message.saveCopySuccess" : `복사 되었습니다.`,
    "cm03_0200.message.selectChannel" : `채널을 선택해 주세요.`,
    "cm03_0200.message.selectChannelProduct" : `채널상품을 선택해 주세요.`,
    "cm03_0200.message.selectChannelRoom" : `채널객실을 선택해 주세요.`,
    "cm03_0200.message.selectControlType" : `조정타입을 선택해 주세요.`,
    "cm03_0200.message.selectCutoff" : `절삭할 원단위를 선택해 주세요.`,
    "cm03_0200.message.selectProductGroup" : `상품그룹을 선택해 주세요.`,
    "cm03_0200.message.selectRateSet" : `요금세트를 선택해 주세요.`,
    "cm03_0200.message.selectRoomType" : `호텔객실을 선택해 주세요.`,
    "cm03_0200.message.selectType" : `요금 설정, 채널별 요금조정 중에 하나는 선택하셔야 합니다.`,
    "cm03_0200.popup.addGoodsGroup" : `+ 상품그룹추가`,
    "cm03_0200.popup.addPersonCount" : `+ 인원수추가`,
    "cm03_0200.popup.all" : `전체`,
    "cm03_0200.popup.basicPerson" : `기준인원 + @(0)인`,
    "cm03_0200.popup.cancle" : `취소`,
    "cm03_0200.popup.channel" : `채널`,
    "cm03_0200.popup.channelByFeeSet" : `채널별 요금조정`,
    "cm03_0200.popup.channelPersonCount" : `채널별 인원수`,
    "cm03_0200.popup.channelRate" : `채널상품`,
    "cm03_0200.popup.channelRateSelect" : `채널상품을 선택해 주세요.`,
    "cm03_0200.popup.channelRoom" : `채널객실`,
    "cm03_0200.popup.channelRoomSelect" : `채널객실을 선택해 주세요.`,
    "cm03_0200.popup.channelSelect" : `채널을 선택해 주세요.`,
    "cm03_0200.popup.confirm" : `확인`,
    "cm03_0200.popup.controlFee" : `요금 설정`,
    "cm03_0200.popup.controlType" : `조정타입`,
    "cm03_0200.popup.cutoff" : `원단위절삭`,
    "cm03_0200.popup.day" : `일자`,
    "cm03_0200.popup.feeSetting" : `요금 설정`,
    "cm03_0200.popup.goodsAllCopy" : `상품그룹 일괄 설정`,
    "cm03_0200.popup.goodsGroup" : `상품그룹`,
    "cm03_0200.popup.inputControl" : `조정타입을 확인해 주세요.`,
    "cm03_0200.popup.inputCutOff" : `절삭할 원단위를 확인해 주세요.`,
    "cm03_0200.popup.inputNumber" : `조정 숫자를 확인해 주세요.`,
    "cm03_0200.popup.nightCurrency" : `1박 요금 : @(0)`,
    "cm03_0200.popup.nightFee" : `1박 요금 : 통화`,
    "cm03_0200.popup.period" : `기간`,
    "cm03_0200.popup.periodLimit" : `시작일로부터 1년까지 설정 가능합니다.`,
    "cm03_0200.popup.personCount" : `인원수`,
    "cm03_0200.popup.personCountSet" : `인원수 설정`,
    "cm03_0200.popup.personLimit" : `50인까지 추가 가능합니다.`,
    "cm03_0200.popup.personLimit2" : `+3인까지 추가 가능합니다.`,
    "cm03_0200.popup.personLimit3" : `+@(0)인까지 추가 가능합니다.`,
    "cm03_0200.popup.productSelect" : `상품 그룹을 선택해 주세요.`,
    "cm03_0200.popup.roomAllCopy" : `호텔객실 일괄 설정`,
    "cm03_0200.popup.save" : `저장`,
    "cm03_0200.popup.saveSuccess" : `저장 되었습니다.`,
    "cm03_0200.popup.saveSuccessInfo" : `채널상품과 상품그룹에 요금이 중복으로 설정되어 있을 경우 <br />상품그룹의 요금이 적용됩니다.`,
    "cm03_0200.popup.tooltipMessage1" : `대용량 데이터 전송방지를 위해 재전송 가능한 일괄수정 기간은 1년단위로 설정 가능합니다.`,
    "cm03_0200.popup.tooltipMessage2" : `숙박인원수에 따라 요금이 변동될 수 있습니다. <br /> 인원수 요금은 채널의 엑스트라넷 설정 시 적용됩니다.`,
    "cm03_0200.popup.tooltipMessage3" : `* 요금의 증감은 + / - 를 입력하여 설정해주세요.`,
    "cm03_0200.title.SaleStatus" : `판매상태`,
    "cm03_0200.title.addOptions" : `추가옵션`,
    "cm03_0200.title.addSetting" : `추가설정`,
    "cm03_0200.title.alRms" : `판매가능객실`,
    "cm03_0200.title.all" : `전체`,
    "cm03_0200.title.allCopy" : `전체 복사`,
    "cm03_0200.title.allRoomTypeSel" : `항상 모든 객실 선택`,
    "cm03_0200.title.basicPerson" : `기준인원`,
    "cm03_0200.title.cancel" : `취소`,
    "cm03_0200.title.channelApply" : `상품그룹+채널적용`,
    "cm03_0200.title.channelRate" : `채널요금`,
    "cm03_0200.title.channelRateApply" : `채널상품 일괄 설정`,
    "cm03_0200.title.close" : `닫기`,
    "cm03_0200.title.closeCause" : `마감원인`,
    "cm03_0200.title.closed" : `마감`,
    "cm03_0200.title.controlFee" : `요금 관리`,
    "cm03_0200.title.countUnit" : `개`,
    "cm03_0200.title.dayCopy" : `하루복사`,
    "cm03_0200.title.daySelect" : `요일 선택`,
    "cm03_0200.title.decimalInput" : `소수점 입력`,
    "cm03_0200.title.detailCopy" : `상세복사`,
    "cm03_0200.title.detailShow" : `자세히보기`,
    "cm03_0200.title.directInput" : `직접입력`,
    "cm03_0200.title.doRateCopy" : `요금 복사하기`,
    "cm03_0200.title.exposeCondition" : `노출정보`,
    "cm03_0200.title.fri" : `금`,
    "cm03_0200.title.goodsGroup" : `상품그룹`,
    "cm03_0200.title.goodsGroupCondition" : `상품그룹`,
    "cm03_0200.title.hotelRoom" : `호텔객실`,
    "cm03_0200.title.initialize" : `초기화`,
    "cm03_0200.title.inventoryByRate" : `인벤토리관리(상품별)`,
    "cm03_0200.title.limitMessage" : `기간은 [시작일자]기준 최대 한달(31일)까지 조회 가능합니다.`,
    "cm03_0200.title.limitMessage2" : `기간은 [시작일자]기준 최대 90일까지 설정 가능합니다.`,
    "cm03_0200.title.mon" : `월`,
    "cm03_0200.title.occOverClose" : `OCC(%)초과시 마감`,
    "cm03_0200.title.occupancy" : `점유율(%)`,
    "cm03_0200.title.onlyNumber" : `숫자만 입력`,
    "cm03_0200.title.open" : `오픈`,
    "cm03_0200.title.openCloseCount" : `오픈가능객실 N개이하 마감`,
    "cm03_0200.title.openOneMessage" : `오픈 가능 객실 1개 일 때`,
    "cm03_0200.title.overbookingPrevent" : `오버부킹 방지`,
    "cm03_0200.title.periodControlMessage" : `기간까지 요금 조정`,
    "cm03_0200.title.periodCopy" : `기간복사`,
    "cm03_0200.title.periodCopyMessage" : `기간까지 설정 복사`,
    "cm03_0200.title.personCount" : `인원수별 조회`,
    "cm03_0200.title.popCopyMessage1" : `를`,
    "cm03_0200.title.popCopyMessage2" : `로 복사합니다.`,
    "cm03_0200.title.popCopyMessage3" : `요금의 증감은 +/- 로 설정해주세요.`,
    "cm03_0200.title.possibleRoom" : `판매가능객실`,
    "cm03_0200.title.primary" : `필수항목`,
    "cm03_0200.title.productGroupApply" : `상품그룹만 적용`,
    "cm03_0200.title.rate" : `요금`,
    "cm03_0200.title.rateCategory" : `요금 카테고리`,
    "cm03_0200.title.rateCopy" : `요금복사`,
    "cm03_0200.title.rateSet" : `요금세트`,
    "cm03_0200.title.rateSetPop" : `요금설정`,
    "cm03_0200.title.remain" : `잔여`,
    "cm03_0200.title.reservation" : `예약`,
    "cm03_0200.title.saleEnd" : `판매마감`,
    "cm03_0200.title.sat" : `토`,
    "cm03_0200.title.save" : `저장`,
    "cm03_0200.title.search" : `검색`,
    "cm03_0200.title.searchPersonCount" : `@(0)인`,
    "cm03_0200.title.settingMethod" : `설정 방법`,
    "cm03_0200.title.startDate" : `시작일`,
    "cm03_0200.title.sun" : `일`,
    "cm03_0200.title.thu" : `목`,
    "cm03_0200.title.today" : `오늘`,
    "cm03_0200.title.tooltipMessage1" : `대용량 데이터 전송방지를 위해 재전송 가능한 일괄수정 기간은 1년단위로 설정 가능합니다.`,
    "cm03_0200.title.tooltipMessage2" : `아래설정을 하게되면 오버부킹을 방지 할 수 있습니다.`,
    "cm03_0200.title.tooltipMessage4" : `기간은 [시작일자] 기준 최대 15일까지 조회 가능합니다.`,
    "cm03_0200.title.tooltipMessage5" : `채널별 요금조정과 개별의 기능입니다. <br />요금 입력 후 저장을 눌러주세요.`,
    "cm03_0200.title.tooltipMessage6" : `요금 설정과 개별의 기능입니다. <br />요금 입력 후 저장을 눌러주세요.`,
    "cm03_0200.title.totalSaleStatus" : `전체 판매상태`,
    "cm03_0200.title.tue" : `화`,
    "cm03_0200.title.wed" : `수`,
    "cm03_0200.title.weekday" : `주중`,
    "cm03_0200.title.weekend" : `주말`,
    "cm03_0200.title.weekendCopy" : `주중만 복사`,
    "cm03_0200.title.weekendOnlyCopy" : `주말만 복사`,
    "cm03_0200.title.whenFeeCopy" : `요금복사 시`,
    "cm03_0300.message.emptyContent1" : `공백 처리시 객실의 잔여수량이 반영됩니다.`,
    "cm03_0300.message.emptyContent2" : `공백 처리시 객실 잔여에 따라 판매상태가 변경 될 수 있습니다.`,
    "cm03_0300.message.emptyContent3" : `공백 처리시 객실그룹과 호텔객실 중 최소값이 반영됩니다.`,
    "cm03_0300.popup.addMessage1" : `잔여객실수가 1개일 때 판매할 객실유형을 선택하세요.`,
    "cm03_0300.popup.addMessage2" : `잔여객실수에 따른 마감처리를 위해 객실수를 입력하세요.`,
    "cm03_0300.popup.addMessage3" : `객실점유율(%)에 따른 마감처리를 위해 점유율(%)을 입력하세요.`,
    "cm03_0300.popup.addSetting" : `추가 설정`,
    "cm03_0300.popup.alRmsEmpty" : `공백처리`,
    "cm03_0300.popup.beforeSaleClose" : `N일전 판매 마감`,
    "cm03_0300.popup.channel" : `채널`,
    "cm03_0300.popup.channelAllCopy" : `채널 일괄설정`,
    "cm03_0300.popup.channelRoom" : `채널객실`,
    "cm03_0300.popup.chechInClose" : `입실마감`,
    "cm03_0300.popup.checkOutClose" : `퇴실마감`,
    "cm03_0300.popup.close" : `마감`,
    "cm03_0300.popup.daySelect" : `요일 선택`,
    "cm03_0300.popup.freeSale" : `프리세일`,
    "cm03_0300.popup.fri" : `금`,
    "cm03_0300.popup.goodsGroup" : `상품그룹`,
    "cm03_0300.popup.hotelRoom" : `호텔객실`,
    "cm03_0300.popup.maxCountiueDay" : `최대연박기간`,
    "cm03_0300.popup.maxStayDay" : `최대투숙기간`,
    "cm03_0300.popup.message1" : `대용량 데이터 전송방지를 위해 재전송 가능한<br>일괄수정 기간은 1년단위로 설정 가능합니다.`,
    "cm03_0300.popup.minCountiueDay" : `최소연박기간`,
    "cm03_0300.popup.minStayDay" : `최소투숙기간`,
    "cm03_0300.popup.mon" : `월`,
    "cm03_0300.popup.onlyNumber" : `숫자만 입력`,
    "cm03_0300.popup.open" : `오픈`,
    "cm03_0300.popup.overBooking" : `오버부킹`,
    "cm03_0300.popup.period" : `기간`,
    "cm03_0300.popup.restrictionSet" : `제한 설정`,
    "cm03_0300.popup.roomAllSetting" : `호텔객실 일괄설정`,
    "cm03_0300.popup.room_group" : `객실그룹`,
    "cm03_0300.popup.saleClose" : `판매 마감`,
    "cm03_0300.popup.sat" : `토`,
    "cm03_0300.popup.save" : `저장`,
    "cm03_0300.popup.selectChannel" : `채널을 선택하세요.`,
    "cm03_0300.popup.selectChannelRoom" : `채널 객실을 선택하세요.`,
    "cm03_0300.popup.selectHotelRoom" : `호텔 객실을 선택하세요.`,
    "cm03_0300.popup.selectRoomType" : `객실 그룹을 선택하세요.`,
    "cm03_0300.popup.selectWeek" : `요일을 선택하세요.`,
    "cm03_0300.popup.settingByRoom" : `객실그룹별 설정`,
    "cm03_0300.popup.sun" : `일`,
    "cm03_0300.popup.thu" : `목`,
    "cm03_0300.popup.tue" : `화`,
    "cm03_0300.popup.unit" : `개`,
    "cm03_0300.popup.unitDay" : `일`,
    "cm03_0300.popup.useRoomCount" : `판매가능객실`,
    "cm03_0300.popup.wed" : `수`,
    "cm03_0300.title." : `전체`,
    "cm03_0300.title.addOptions" : `추가옵션`,
    "cm03_0300.title.addSetting" : `추가설정`,
    "cm03_0300.title.adjRmCnt" : `오버부킹`,
    "cm03_0300.title.alRms" : `판매가능객실`,
    "cm03_0300.title.allCopy" : `전체 복사`,
    "cm03_0300.title.autoCloseRms" : `객실수마감`,
    "cm03_0300.title.autoClosed" : `Auto`,
    "cm03_0300.title.calcCloseYn" : `전체 판매상태`,
    "cm03_0300.title.channel" : `채널`,
    "cm03_0300.title.channelFee" : `채널요금`,
    "cm03_0300.title.channelRoom" : `채널객실`,
    "cm03_0300.title.close" : `취소`,
    "cm03_0300.title.closeCause" : `마감원인`,
    "cm03_0300.title.closeOccupancy" : `OCC(%) 마감`,
    "cm03_0300.title.closeYN" : `판매상태`,
    "cm03_0300.title.closed" : `마감`,
    "cm03_0300.title.condition" : `조건`,
    "cm03_0300.title.countUnit" : `개`,
    "cm03_0300.title.cutOffDays" : `N일전 마감`,
    "cm03_0300.title.date" : `일자`,
    "cm03_0300.title.dayCopy" : `하루복사`,
    "cm03_0300.title.daySelect" : `요일 선택`,
    "cm03_0300.title.decimalInput" : `소수점 입력`,
    "cm03_0300.title.detailCopy" : `상세복사`,
    "cm03_0300.title.detailShow" : `자세히보기`,
    "cm03_0300.title.feeSetByChannel" : `채널별 요금조정`,
    "cm03_0300.title.fri" : `금`,
    "cm03_0300.title.hotelRoom" : `호텔객실`,
    "cm03_0300.title.intialize" : `초기화`,
    "cm03_0300.title.inventory" : `인벤토리`,
    "cm03_0300.title.inventoryControl" : `인벤토리 관리`,
    "cm03_0300.title.maxLos" : `최대투숙기간`,
    "cm03_0300.title.maxStayThrou" : `최대연박기간`,
    "cm03_0300.title.minLos" : `최소투숙기간`,
    "cm03_0300.title.minStayThrou" : `최소연박기간`,
    "cm03_0300.title.mon" : `월`,
    "cm03_0300.title.noArrYn" : `입실마감`,
    "cm03_0300.title.noDepYn" : `퇴실마감`,
    "cm03_0300.title.occOverClose" : `OCC(%)초과시 마감`,
    "cm03_0300.title.occupancy" : `점유율(%)`,
    "cm03_0300.title.onlyNumber" : `숫자만 입력`,
    "cm03_0300.title.open" : `오픈`,
    "cm03_0300.title.openCloseCount" : `오픈가능객실 N개이하 마감`,
    "cm03_0300.title.openOneMessage" : `오픈 가능 객실 1개 일 때`,
    "cm03_0300.title.overbookingPrevent" : `오버부킹 방지`,
    "cm03_0300.title.period" : `기간`,
    "cm03_0300.title.periodCopy" : `기간복사`,
    "cm03_0300.title.periodCopyMessage" : `기간까지 설정 복사`,
    "cm03_0300.title.primary" : `필수항목`,
    "cm03_0300.title.remaning" : `잔여`,
    "cm03_0300.title.restriction" : `제한`,
    "cm03_0300.title.roomGroup" : `객실그룹`,
    "cm03_0300.title.rsvnRmCnt" : `예약`,
    "cm03_0300.title.saleClosed" : `판매마감`,
    "cm03_0300.title.saleOpen" : `판매오픈`,
    "cm03_0300.title.sat" : `토`,
    "cm03_0300.title.save" : `저장`,
    "cm03_0300.title.search" : `검색`,
    "cm03_0300.title.sendRestrictRms" : `임의잔여객실`,
    "cm03_0300.title.showInfo" : `노출정보`,
    "cm03_0300.title.sun" : `일`,
    "cm03_0300.title.thu" : `목`,
    "cm03_0300.title.tooltipCloseCause" : `마감ㅣ점유율<br/>컷오프ㅣ할당`,
    "cm03_0300.title.tooltipCloseCause1" : `판매마감`,
    "cm03_0300.title.tooltipCloseCause2" : `OCC @(0)(%) 마감`,
    "cm03_0300.title.tooltipCloseCause3" : `@(0)일전 마감`,
    "cm03_0300.title.tooltipCloseCause4" : `가용 객실수 : @(0)`,
    "cm03_0300.title.tooltipMessage1" : `기간은 [시작일자]기준 최대 한달(31일)까지 조회 가능합니다.`,
    "cm03_0300.title.tooltipMessage2" : `대용량 데이터 전송방지를 위해 재전송 가능한 일괄수정 기간은<br>1년단위로 설정 가능합니다.`,
    "cm03_0300.title.tooltipMessage3" : `* 요금의 증감은 + / - 를 입력하여<br>설정해주세요.`,
    "cm03_0300.title.tooltipMessage4" : `대용량 데이터 전송방지를 위해 전송 가능한기간은<br>선택된 일자를 기준으로 1년단위로 설정 가능합니다.`,
    "cm03_0300.title.tooltipMessage5" : `아래설정을 하게되면 오버부킹을 방지 할 수 있습니다.`,
    "cm03_0300.title.tooltipMessage6" : `기간은 [시작일자] 기준 최대 15일까지 조회 가능합니다.`,
    "cm03_0300.title.tue" : `화`,
    "cm03_0300.title.userClosed" : `User`,
    "cm03_0300.title.wed" : `수`,
    "cm03_0300.title.weekday" : `주중`,
    "cm03_0300.title.weekend" : `주말`,
    "cm03_0300.title.weekendCopy" : `주중만 복사`,
    "cm03_0300.title.weekendOnlyCopy" : `주말만 복사`,
    "cm03_0400.button.cancel" : `취소`,
    "cm03_0400.button.complete" : `완료`,
    "cm03_0400.button.prev" : `이전`,
    "cm03_0400.button.save" : `저장`,
    "cm03_0400.button.sortMove" : `순서이동`,
    "cm03_0400.message.noMapping" : `매핑이 안된 상품이 존재합니다.<br /> 매핑하시기 바랍니다.`,
    "cm03_0400.message.noMappingInfo" : `아래 상품그룹과 채널상품 간의 매핑이 안되었습니다.<br />`,
    "cm03_0400.message.success" : `저장 되었습니다.`,
    "cm03_0400.message.success1" : `객실, 상품 매핑 변경 후 요금 및 인벤토리 재전송 기능을 이용하여 할당/요금 재전송 처리를 해주세요.`,
    "cm03_0400.message.success2" : `V 반드시 요금 관리에 입력된 요금이 채널 엑스트라넷에 반영되었는지 확인해 주세요.`,
    "cm03_0400.message.success3" : `채널상품과 상품그룹에 요금이 중복으로 설정되어 있을 경우 상품그룹의 요금이 적용됩니다.`,
    "cm03_0400.message.success4" : `매핑 변경 후 요금 및 인벤토리 재전송 기능을 이용하여 할당/요금 재전송 처리를 해주세요.`,
    "cm03_0400.message.success5" : `( 할당/요금 반영여부 채널 엑스트라넷 필히 확인 )`,
    "cm03_0400.title.notUseSearch" : `미 사용 상품 그룹 포함 조회`,
    "cm03_0400.title.productManage" : `상품 관리`,
    "cm03_0400.title.resendMove" : `[요금 및 인벤토리 재전송] 바로가기`,
    "cm03_0400.title.searchPromotion" : `프로모션 상품 조회`,
    "cm03_0401.button.add" : `추가`,
    "cm03_0401.button.cancel" : `취소`,
    "cm03_0401.button.save" : `저장`,
    "cm03_0401.message.channelServiceDupl" : `중복된 서비스가 존재합니다.`,
    "cm03_0401.message.createServiceCode" : `+ 신규 서비스 코드 생성`,
    "cm03_0401.message.inputChannelRoom" : `채널 객실명 데이터를 확인해 주세요.`,
    "cm03_0401.message.inputServiceCode" : `서비스코드 데이터를 확인해 주세요.`,
    "cm03_0401.message.inputServiceType" : `서비스타입 데이터를 확인해 주세요.`,
    "cm03_0401.message.noModifyData" : `변경된 데이터가 없습니다.`,
    "cm03_0401.message.noServiceCode" : `서비스 코드가 없습니다.`,
    "cm03_0401.message.noServiceCode1" : `아직 등록된 서비스 코드가 없습니다.`,
    "cm03_0401.message.noServiceCode2" : `[추가] 버튼을 클릭하여 서비스 코드를 관리해보세요.`,
    "cm03_0401.message.selectChannel" : `채널을 선택해 주세요.`,
    "cm03_0401.message.selectRoomType" : `객실 타입을 선택해 주세요.`,
    "cm03_0401.message.success" : `저장 되었습니다.`,
    "cm03_0401.title.allApply" : `일괄 적용`,
    "cm03_0401.title.channel" : `채널`,
    "cm03_0401.title.channelApply" : `채널별 적용`,
    "cm03_0401.title.channelRoomName" : `채널 객실명`,
    "cm03_0401.title.delete" : `삭제`,
    "cm03_0401.title.manage" : `서비스 관리`,
    "cm03_0401.title.modify" : `수정`,
    "cm03_0401.title.notUsed" : `미사용`,
    "cm03_0401.title.quantity" : `기준수량`,
    "cm03_0401.title.roomType" : `객실타입`,
    "cm03_0401.title.serviceCode" : `서비스 코드`,
    "cm03_0401.title.serviceManage" : `서비스 관리`,
    "cm03_0401.title.serviceName" : `서비스명`,
    "cm03_0401.title.serviceRate" : `서비스요금\n(1개당)`,
    "cm03_0401.title.serviceType" : `서비스타입`,
    "cm03_0401.title.state" : `상태`,
    "cm03_0401.title.use" : `사용`,
    "cm03_0410.button.cancel" : `취소`,
    "cm03_0410.button.save" : `저장`,
    "cm03_0410.message.inputGroupName" : `상품 그룹명을 입력해 주세요.`,
    "cm03_0410.message.inputRoomType" : `PMS 요금 타입을 선택해 주세요.`,
    "cm03_0410.message.success" : `저장 되었습니다.`,
    "cm03_0410.title.active" : `활성화`,
    "cm03_0410.title.inactive" : `비활성화`,
    "cm03_0410.title.newRoomType" : `상품 그룹`,
    "cm03_0410.title.package" : `PMS 패키지`,
    "cm03_0410.title.promotionProductType" : `프로모션 상품 그룹`,
    "cm03_0410.title.roomGroupName" : `상품 그룹명`,
    "cm03_0410.title.roomType" : `PMS 요금 타입`,
    "cm03_0410.title.useYn" : `사용여부`,
    "cm03_0500.button.add" : `추가`,
    "cm03_0500.button.cancel" : `취소`,
    "cm03_0500.button.groupMapping" : `그룹코드 맵핑`,
    "cm03_0500.button.prev" : `이전`,
    "cm03_0500.button.productRoomMap" : `상품객실매핑`,
    "cm03_0500.button.remove" : `제거`,
    "cm03_0500.button.save" : `저장`,
    "cm03_0500.message.cmsTooltip" : `CMS로 관리하지않는 코드의 경우 요금 /인벤토리는 채널의 엑스트라넷을 통해서 관리해야 합니다.`,
    "cm03_0500.message.compareDate" : `상품판매 시작일이 마감일보다 이후 날짜입니다.`,
    "cm03_0500.message.duplicate" : `중복된 객실코드가 존재합니다.`,
    "cm03_0500.message.inputApiCode" : `채널 API 요금코드를 입력해 주세요.`,
    "cm03_0500.message.inputChannelProductCode" : `채널의 상품코드를 입력해주세요`,
    "cm03_0500.message.inputChannelProductName" : `채널의 상품명을 입력해주세요`,
    "cm03_0500.message.inputProductCode" : `객실코드를 입력해 주세요.`,
    "cm03_0500.message.inputProductName" : `객실타입을 입력해 주세요.`,
    "cm03_0500.message.noModifyData" : `변경된 데이터가 없습니다.`,
    "cm03_0500.message.notDataProductCode" : `상품코드를 입력해 주세요.`,
    "cm03_0500.message.notDataProductName" : `상품명을 입력해 주세요.`,
    "cm03_0500.message.removeDupl" : `저장하려는 상품코드 중에 삭제하려는 상품코드가 존재합니다.`,
    "cm03_0500.message.success" : `저장 되었습니다.`,
    "cm03_0500.title.apiRateCode" : `채널 API 요금코드`,
    "cm03_0500.title.calledRoomType" : `호출된 상품`,
    "cm03_0500.title.channelProduct" : `채널별 프로덕트 관리`,
    "cm03_0500.title.cmsManage" : `CMS 관리 여부`,
    "cm03_0500.title.endDate" : `마감일`,
    "cm03_0500.title.notCalledRoomType" : `호출 되지 않은 상품 생성`,
    "cm03_0500.title.productCode" : `상품코드`,
    "cm03_0500.title.productName" : `상품명`,
    "cm03_0500.title.startDate" : `시작일`,
    "cm03_0501.button.save" : `저장`,
    "cm03_0501.message.inputRateCode" : `채널요금코드를 입력해 주세요.`,
    "cm03_0501.message.noModify" : `변경된 데이터가 없습니다.`,
    "cm03_0501.message.saveSuccess" : `저장 되었습니다.`,
    "cm03_0501.title.productRoomMap" : `상품 객실매핑`,
    "cm03_0501.title.rateCode" : `채널요금코드`,
    "cm03_0501.title.roomCode" : `객실코드`,
    "cm03_0501.title.roomName" : `객실명`,
    "cm03_0600.button.addOcc" : `OCC 추가`,
    "cm03_0600.button.apply" : `적용`,
    "cm03_0600.button.cancel" : `취소`,
    "cm03_0600.button.copy" : `복사`,
    "cm03_0600.button.manage" : `관리`,
    "cm03_0600.button.occNotUsed" : `OCC 미 사용`,
    "cm03_0600.button.occUse" : `OCC 사용`,
    "cm03_0600.button.prev" : `이전`,
    "cm03_0600.button.save" : `저장`,
    "cm03_0600.message.MaxRateLimit" : `최대요금 : @(0) <br /> 입력된 요금을 확인하세요.`,
    "cm03_0600.message.RateLimit" : `최소요금 : @(0) <br /> 최대요금 : @(1) <br /> 입력된 요금을 확인하세요.`,
    "cm03_0600.message.copyContent" : `를 @(0) 로 복사 합니다.`,
    "cm03_0600.message.inputControl" : `조정 입력 값을 확인해 주세요.`,
    "cm03_0600.message.maxRateCheck" : `최저요금이 최고요금 보다 큽니다.`,
    "cm03_0600.message.minRateLimit" : `최소요금 : @(0) <br /> 입력된 요금을 확인하세요.`,
    "cm03_0600.message.noGroupData" : `상품 그룹 정보가 없습니다.`,
    "cm03_0600.message.occAdd" : `5개 범위까지 추가 가능합니다.`,
    "cm03_0600.message.occContent" : `OCC사용을 활성화하려면, PMS에 점유율이 도달하면 자동으로 설정된 요금으로 변경됩니다.`,
    "cm03_0600.message.popupMessage" : `(을)를`,
    "cm03_0600.message.productCopy" : `이전에 생성된 상품 그룹의 요금을 그대로 복사합니다.`,
    "cm03_0600.message.productMap1" : `를 클릭하면 그룹핑 설정이 해제됩니다.`,
    "cm03_0600.message.productMap2" : `현재 그룹핑되어있는 프로덕트 코드입니다. 프로덕트 코드 우측의`,
    "cm03_0600.message.productMapping1" : `현재 선택된 요금그룹에 맵핑되어있는 채널상품입니다. 채널상품명 옆에`,
    "cm03_0600.message.productMapping2" : `표시를 누르면 맵핑이 해제됩니다.`,
    "cm03_0600.message.qGroupTerminate" : `@(0)을 그룹에서 해지하시겠습니까 ?`,
    "cm03_0600.message.rateContent" : `* 요금의 증감은 + / - 를 입력하여 설정해주세요.`,
    "cm03_0600.message.rateSetContent" : `기 생성된 상품그룹의 정보를 복사하여 카테고리별 요금을 설정할 수 있습니다.`,
    "cm03_0600.message.rateSetting" : `채널에 요금전송 시 최저요금 / 최대 요금을 지정하면, 설정되 요금 이하 / 이상으로 요금을 입력했을 경우 전송에러가 발생합니다.`,
    "cm03_0600.message.selControlType" : `조정타입을 선택해 주세요.`,
    "cm03_0600.message.selCutoff" : `원단위절삭 값을 선택해 주세요.`,
    "cm03_0600.message.selProductColumn" : `복사할 컬럼 그룹을 선택해 주세요.`,
    "cm03_0600.message.selProductGroup" : `복사할 상품 그룹을 선택해 주세요.`,
    "cm03_0600.message.serviceCode1" : `채널을 통해서 발생된 예약의 서비스를 분개하려면 [서비스 포함 여부]를 체크하고 [관리] 버튼을 클릭하여 서비스 코드를 입력해주세요.`,
    "cm03_0600.message.serviceCode2" : `해당 기능을 설정하면, 조식포함 상품의 조식과 객실료를 분개하여 시스템에 전송됩니다.`,
    "cm03_0600.message.success" : `저장 되었습니다.`,
    "cm03_0600.messeage.serviceCode1Modi" : `채널을 통해서 발생한 예약의 서비스코드를 분개하려면 [관리]버튼을 클릭하여 서비스코드를 입력해주세요.`,
    "cm03_0600.messeage.serviceCode2Modi" : `해당 기능 설정 시 상품그룹에 매핑된 상품으로 예약이 들어올경우 설정한 서비스코드와 객실료를 분개하여 시스템에 전송합니다.`,
    "cm03_0600.title.OccCopy" : `OCC(%) 복사`,
    "cm03_0600.title.add" : `추가`,
    "cm03_0600.title.addOption" : `추가옵션`,
    "cm03_0600.title.controlType" : `조정타입`,
    "cm03_0600.title.copy" : `로 복사`,
    "cm03_0600.title.createdGroup" : `생성된 상품그룹 명`,
    "cm03_0600.title.cutoff" : `원단위절삭`,
    "cm03_0600.title.doCopy" : `복사하기`,
    "cm03_0600.title.input" : `숫자만 입력`,
    "cm03_0600.title.inputPossibleNumber" : `영문, 숫자 5자리까지 입력 가능합니다.`,
    "cm03_0600.title.maxRate" : `최고 요금 설정`,
    "cm03_0600.title.memoDescription" : `비고`,
    "cm03_0600.title.memoRateName" : `요금세트 명`,
    "cm03_0600.title.minRate" : `최저 요금 설정`,
    "cm03_0600.title.no" : `아니오`,
    "cm03_0600.title.occCheckbox" : `객실 타입별 점유율에 따른 금액 변경`,
    "cm03_0600.title.pageTitle" : `요금세트 생성`,
    "cm03_0600.title.pmsPackageNo" : `PMS 패키지`,
    "cm03_0600.title.pmsRateType" : `PMS 요금타입`,
    "cm03_0600.title.prdGroupName" : `상품그룹 명`,
    "cm03_0600.title.productCopy" : `상품 그룹 복사`,
    "cm03_0600.title.productGroupOff" : `상품그룹 보기`,
    "cm03_0600.title.productGroupOn" : `상품그룹 접기`,
    "cm03_0600.title.productManage" : `상품 그룹 관리`,
    "cm03_0600.title.rateCopy" : `요금복사 시`,
    "cm03_0600.title.rateSet" : `요금 세트`,
    "cm03_0600.title.rateSetCopy" : `요금세트 복사`,
    "cm03_0600.title.second" : `이상`,
    "cm03_0600.title.serviceCodeSetting" : `서비스코드 설정`,
    "cm03_0600.title.standardRate" : `기준요금`,
    "cm03_0600.title.yes" : `예`,
    "cm03_0601.button.add" : `추가`,
    "cm03_0601.button.cancel" : `취소`,
    "cm03_0601.button.save" : `저장`,
    "cm03_0601.message.channelServiceDupl" : `중복된 서비스가 존재합니다.`,
    "cm03_0601.message.createServiceCode" : `+ 신규 서비스 코드 생성`,
    "cm03_0601.message.inputChannelRoom" : `채널 객실명 데이터를 확인해 주세요.`,
    "cm03_0601.message.inputServiceCode" : `서비스코드 데이터를 확인해 주세요.`,
    "cm03_0601.message.inputServiceType" : `서비스타입 데이터를 확인해 주세요.`,
    "cm03_0601.message.noModifyData" : `변경된 데이터가 없습니다.`,
    "cm03_0601.message.noServiceCode" : `서비스 코드가 없습니다.`,
    "cm03_0601.message.noServiceCode1" : `아직 등록된 서비스 코드가 없습니다.`,
    "cm03_0601.message.noServiceCode2" : `[추가] 버튼을 클릭하여 서비스 코드를 관리해보세요.`,
    "cm03_0601.message.selectChannel" : `채널을 선택해 주세요.`,
    "cm03_0601.message.selectRoomType" : `객실 타입을 선택해 주세요.`,
    "cm03_0601.message.success" : `저장 되었습니다.`,
    "cm03_0601.title.allApply" : `일괄 적용`,
    "cm03_0601.title.channel" : `채널`,
    "cm03_0601.title.channelApply" : `채널별 적용`,
    "cm03_0601.title.channelRoomName" : `채널 객실명`,
    "cm03_0601.title.delete" : `삭제`,
    "cm03_0601.title.manage" : `관리`,
    "cm03_0601.title.modify" : `수정`,
    "cm03_0601.title.notUsed" : `미사용`,
    "cm03_0601.title.quantity" : `기준수량`,
    "cm03_0601.title.roomType" : `객실타입`,
    "cm03_0601.title.serviceCode" : `서비스 코드`,
    "cm03_0601.title.serviceManage" : `서비스 관리`,
    "cm03_0601.title.serviceName" : `서비스명`,
    "cm03_0601.title.serviceRate" : `서비스요금\n(1개당)`,
    "cm03_0601.title.serviceType" : `서비스타입`,
    "cm03_0601.title.state" : `상태`,
    "cm03_0601.title.use" : `사용`,
    "cm03_0800.button.cancel" : `취소`,
    "cm03_0800.button.manage" : `관리`,
    "cm03_0800.button.save" : `저장`,
    "cm03_0800.message.noModify" : `변경된 데이터가 없습니다.`,
    "cm03_0800.message.productGroupSort" : `상품 그룹 및 매핑 되어 있는 상품 코드의 순서를 드래그하여 변경할 수 있습니다.`,
    "cm03_0800.message.success" : `저장 되었습니다.`,
    "cm03_0800.title.productGroupSort" : `상품 관리 순서`,
    "cm03_0900.button.cancel" : `취소`,
    "cm03_0900.button.next" : `다음`,
    "cm03_0900.button.prev" : `이전`,
    "cm03_0900.button.save" : `저장`,
    "cm03_0900.button.sortMove" : `순서이동`,
    "cm03_0900.message.noMapping" : `매핑이 안된 객실상품이 존재합니다.<br /> 매핑하시기 바랍니다.`,
    "cm03_0900.message.noMappingInfo" : `아래 객실그룹과 채널객실상품 간의 매핑이 안되었습니다.<br />`,
    "cm03_0900.message.success" : `저장 되었습니다.`,
    "cm03_0900.title.notUseSearch" : `미 사용 객실 그룹 포함 조회`,
    "cm03_0900.title.roomManage" : `객실 관리`,
    "cm03_0910.button.callRoomType" : `PMS 객실정보 호출`,
    "cm03_0910.button.cancel" : `취소`,
    "cm03_0910.button.no" : `아니오`,
    "cm03_0910.button.save" : `저장`,
    "cm03_0910.button.yes" : `예`,
    "cm03_0910.message.inputGroupName" : `객실 그룹명을 입력해 주세요.`,
    "cm03_0910.message.inputRoomType" : `호텔 객실 타입을 선택해 주세요.`,
    "cm03_0910.message.pmsCall" : `PMS의 객실정보를 가져오시겠습니까?`,
    "cm03_0910.message.pmsCallSuccess" : `PMS의 객실정보를 성공적으로 호출하였습니다.`,
    "cm03_0910.message.success" : `저장 되었습니다.`,
    "cm03_0910.title.active" : `활성화`,
    "cm03_0910.title.inactive" : `비활성화`,
    "cm03_0910.title.newRoomType" : `신규 객실타입`,
    "cm03_0910.title.roomGroupName" : `객실 그룹명`,
    "cm03_0910.title.roomType" : `호텔 객실 타입`,
    "cm03_0910.title.useYn" : `사용여부`,
    "cm03_1000.button.add" : `추가`,
    "cm03_1000.button.cancel" : `취소`,
    "cm03_1000.button.remove" : `제거`,
    "cm03_1000.button.save" : `저장`,
    "cm03_1000.message.cmsTooltip" : `CMS로 관리하지않는 코드의 경우 요금 /인벤토리는 채널의 엑스트라넷을 통해서 관리해야 합니다.`,
    "cm03_1000.message.duplicate" : `중복된 객실코드가 존재합니다.`,
    "cm03_1000.message.inputChannelRoomCode" : `채널의 객실코드를 입력해주세요`,
    "cm03_1000.message.inputChannelRoomName" : `채널의 객실타입 명를 입력해주세요`,
    "cm03_1000.message.inputRoomCode" : `객실코드를 입력해 주세요.`,
    "cm03_1000.message.inputRoomName" : `객실타입을 입력해 주세요.`,
    "cm03_1000.message.noModifyData" : `변경된 데이터가 없습니다.`,
    "cm03_1000.message.removeDupl" : `저장하려는 객실코드 중에 삭제하려는 객실코드가 존재합니다.`,
    "cm03_1000.message.success" : `저장 되었습니다.`,
    "cm03_1000.title.calledRoomType" : `호출된 객실타입`,
    "cm03_1000.title.cmsManageYn" : `CMS 관리 여부`,
    "cm03_1000.title.notCalledRoomType" : `호출 되지 않은 객실 타입 생성`,
    "cm03_1000.title.roomCode" : `객실코드`,
    "cm03_1000.title.roomType" : `객실타입`,
    "cm03_1000.title.roomTypeManage" : `채널별 객실 타입 관리`,
    "cm03_1100.button.cancel" : `취소`,
    "cm03_1100.button.prev" : `이전`,
    "cm03_1100.button.save" : `저장`,
    "cm03_1100.message.groupingContent" : `그룹핑되어있는 객실 코드입니다. 객실 코드 우측의`,
    "cm03_1100.message.groupingContent2" : `를 클릭하면 그룹핑 설정이 해제됩니다.`,
    "cm03_1100.message.noGroupData" : `객실 그룹 데이터를 불러오지 못했습니다.`,
    "cm03_1100.message.noModifyMapping" : `해지 처리 불가한 채널입니다.`,
    "cm03_1100.message.qGroupTerminate" : `@(0)을 그룹에서 해지하시겠습니까 ?`,
    "cm03_1100.message.rateCompare" : `최고 요금 값은 최저 요금 값보다 커야 합니다.`,
    "cm03_1100.message.rateContent" : `채널에 요금전송 시 최저요금 / 최대 요금을 지정하면, 설정된 요금 이하 / 이상으로 요금을 입력했을 경우 전송에러가 발생합니다.`,
    "cm03_1100.message.rateNoticeComment1" : `최저/최고 요금 설정 후 요금관리 메뉴에서 요금 등록 시 설정 범위내 요금을 등록해주시기 바랍니다.`,
    "cm03_1100.message.rateNoticeComment2" : `설정된 요금보다 낮은 요금을 등록하거나 높은 요금을 등록할 경우, 전송 에러가 발생하여 객실마감 상태로 보여질 수 있습니다.`,
    "cm03_1100.message.success" : `저장 되었습니다.`,
    "cm03_1100.title.inputNumber" : `숫자만 입력`,
    "cm03_1100.title.maxRate" : `최고 요금 설정`,
    "cm03_1100.title.minRate" : `최저 요금 설정`,
    "cm03_1100.title.no" : `아니오`,
    "cm03_1100.title.pmsRoomType" : `PMS 객실타입`,
    "cm03_1100.title.roomGroup" : `객실 그룹 관리`,
    "cm03_1100.title.yes" : `예`,
    "cm03_1200.button.cancel" : `취소`,
    "cm03_1200.button.manage" : `관리`,
    "cm03_1200.button.save" : `저장`,
    "cm03_1200.message.noModify" : `변경된 데이터가 없습니다.`,
    "cm03_1200.message.roomGroupSort" : `객실 그룹 및 매핑 되어 있는 상품 코드의 순서를 드래그하여 변경할 수 있습니다.`,
    "cm03_1200.message.success" : `저장 되었습니다.`,
    "cm03_1200.title.roomGroupSort" : `객실 그룹 순서`,
    "cm03_2100.button.add" : `추가`,
    "cm03_2100.button.cancel" : `취소`,
    "cm03_2100.button.excel" : `엑셀`,
    "cm03_2100.button.importServiceCode" : `PMS 서비스 코드 가져오기`,
    "cm03_2100.button.init" : `초기화`,
    "cm03_2100.button.no" : `아니오`,
    "cm03_2100.button.remove" : `삭제`,
    "cm03_2100.button.save" : `저장`,
    "cm03_2100.button.search" : `검색`,
    "cm03_2100.button.serviceCodeCall" : `PMS 서비스코드 가져오기`,
    "cm03_2100.button.yes" : `예`,
    "cm03_2100.etc.active" : `사용`,
    "cm03_2100.etc.all" : `전체`,
    "cm03_2100.etc.inactive" : `미사용`,
    "cm03_2100.message.apiServiceCode" : `API를 통해 서비스 코드를 가져 오시겠습니까?`,
    "cm03_2100.message.checkPmsDb" : `호텔의 PMS DB연결을 확인하세요.`,
    "cm03_2100.message.checkServiceName" : `서비스명은 특수문자만 입력할 수 없습니다.`,
    "cm03_2100.message.duplicate" : `중복된 서비스 코드가 존재합니다. \n확인 후 다시 시도 해주세요.`,
    "cm03_2100.message.importApi" : `API를 통해 서비스 코드를 가져 오시겠습니까?`,
    "cm03_2100.message.init" : `데이터가 변경되었습니다. <br />초기화 하시겠습니까?`,
    "cm03_2100.message.inputAmount" : `금액을 입력해 주세요.`,
    "cm03_2100.message.inputBaseQty" : `기준수량을 입력해 주세요.`,
    "cm03_2100.message.inputServiceCode" : `서비스코드를 입력해 주세요.`,
    "cm03_2100.message.inputServiceName" : `서비스명을 입력해 주세요.`,
    "cm03_2100.message.modifyData" : `변경된 데이터가 있습니다. 저장 후 이용해 주십시오.`,
    "cm03_2100.message.noModifyData" : `변경된 데이터가 없습니다.`,
    "cm03_2100.message.pmsCallSuccess" : `PMS의 서비스코드를 성공적으로 호출하였습니다.`,
    "cm03_2100.message.success" : `저장 되었습니다.`,
    "cm03_2100.title.activeYn" : `사용여부`,
    "cm03_2100.title.addService" : `서비스 추가`,
    "cm03_2100.title.alarm" : `알림`,
    "cm03_2100.title.baseQty" : `기준수량`,
    "cm03_2100.title.modify" : `수정`,
    "cm03_2100.title.pmName" : `프로퍼티명`,
    "cm03_2100.title.pmSvcAmt" : `금액`,
    "cm03_2100.title.pmSvcCode" : `서비스코드`,
    "cm03_2100.title.sortOrder" : `정렬순서`,
    "cm03_2100.title.svcCodeReg" : `서비스코드 등록`,
    "cm03_2100.title.svcName" : `서비스명`,
    "cm03_2200.message.inputRevNo" : `채널 예약번호를 입력해 주세요.`,
    "cm03_2200.message.selectChannel" : `채널을 선택해 주세요.`,
    "cm03_2200.message.selectProperty" : `프로퍼티를 선택해 주세요.`,
    "cm03_2200.message.successCall" : `예약번호 : @(0) 이(가) 성공적으로 수신되었습니다.`,
    "cm03_2200.message.successCallRsvn" : `예약번호 : @(0) 이(가) 성공적으로 전송 되었습니다. \nCMS-예약목록으로 이동하여 PMS-전송결과를 확인해 주세요.`,
    "cm03_2200.title.async" : `사용안함`,
    "cm03_2200.title.channelName" : `채널명`,
    "cm03_2200.title.notUsed" : `사용안함`,
    "cm03_2200.title.notice" : `재전송 요청 기능을 지원하는 채널만 호출 할 수 있습니다.`,
    "cm03_2200.title.processResult" : `처리 결과`,
    "cm03_2200.title.processResultDetail" : `상세 결과`,
    "cm03_2200.title.property" : `프로퍼티 명`,
    "cm03_2200.title.qAutoInput" : `재전송된 채널의 예약을 PMS에 자동 입력하시겠습니까?`,
    "cm03_2200.title.resendRequest" : `재전송 요청`,
    "cm03_2200.title.revNumber" : `채널 예약번호`,
    "cm03_2200.title.revResend" : `채널 예약 재전송`,
    "cm03_2200.title.use" : `사용`,
    "cm03_2300.button.inventoryResend" : `요금 및 인벤토리 재전송`,
    "cm03_2300.button.reSend" : `재전송`,
    "cm03_2300.message.inactiveBatch" : `선택한 채널은 전송이 불가능 합니다. 자세한 내용은 산하 C/S팀(1544-4172)으로 연락바랍니다.`,
    "cm03_2300.message.inputPeriod" : `판매 기간을 입력해 주세요.`,
    "cm03_2300.message.interpark" : `인터파크는 데이터 전송이 불가능합니다. 채널(인터파크)로 연락바랍니다.`,
    "cm03_2300.message.maxResend" : `요금 및 인벤토리 재전송은 시작일 기준 최대 90일까지 전송가능합니다.`,
    "cm03_2300.message.maxResendContent" : `기간 설정은 시작일 기준 90일까지 가능하며, 판매중인 전체객실의 요금/할당이 전송됩니다.`,
    "cm03_2300.message.noMapping" : `매핑된 상품, 객실이 없습니다.`,
    "cm03_2300.message.selectChannel" : `채널을 선택해 주세요.`,
    "cm03_2300.message.selectProperty" : `프로퍼티를 선택해 주세요.`,
    "cm03_2300.message.selectRoomType" : `채널 객실을 선택해 주세요.`,
    "cm03_2300.message.successApiCall" : `@(0)의 요금/인벤토리 정보가 @(1) (으)로 정상적으로 전송이 완료되었습니다.`,
    "cm03_2300.message.successCall" : `산하호텔의 요금/인벤토리 정보가 @(0) 로 정상적으로 전송이 완료되었습니다.`,
    "cm03_2300.title.channelName" : `채널명`,
    "cm03_2300.title.inventoryResend" : `요금/ 인벤토리 데이터 재전송`,
    "cm03_2300.title.processDetail" : `상세 결과`,
    "cm03_2300.title.processResult" : `처리 결과`,
    "cm03_2300.title.property" : `프로퍼티 명`,
    "cm03_2300.title.roomName" : `채널 객실명`,
    "cm03_2300.title.salePeriod" : `판매기간`,
    "cm03_2400.message.inventorySend" : `채널매니저에 입력한 요금 및 인벤토리를 연동되어있는 채널로 일괄 재 전송 하는 기능입니다.`,
    "cm03_2400.message.notice" : `1. 채널 예약건 호출<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 채널에 따라 재호출 기간(30분~1일 등)이 만료된 경우 예약건 재호출 불가한 채널이 있기 때문에 호출되지 않는 예약건에 대해서는 수기 입력처리 하시기 바랍니다.<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- PMS 상에서 기존예약건을 수기 변경(룸타입 변경, 금액 변경 등)을 진행 한 경우 예약건 재호출시 채널에서 호출된 정보로 업데이트 됩니다. 호출시 주의 하시기 바랍니다.<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- PMS 코드(마켓타입, 소스타입, 요금타입 등)가 잘못 설정한 경우 PMS 예약건 입력 실패 됩니다. <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 채널서비스코드 매핑이 이루어지지 않은 경우 PMS 예약 전송이 실패됩니다. <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- CMS 미연동 예약 중 취소예약은 호출 되지 않습니다. <br/><br/>`,
    "cm03_2400.message.notice1" : ``,
    "cm03_2400.message.notice2" : `2. 요금 및 인벤토리 재전송<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 현재 CMS에 입력된 기준으로 전송되며, 전송기간이 장기간에 대해서 처리하는 경우 채널에 따라 반영이 늦어질 수 있습니다. <br/><br/>`,
    "cm03_2400.message.notice3" : `처리시 문제 발생의 경우 자세한 처리 부분은 산하 C/S 팀(1544-4172)으로 연락 바랍니다.<br />`,
    "cm03_2400.message.notice4" : ``,
    "cm03_2400.message.notice5" : ``,
    "cm03_2400.message.notice6" : ``,
    "cm03_2400.message.reservationSend" : `연동된 채널에서 발생한 예약이 채널매니저로 전송이 누락었을 경우 채널매니저로 재전송하는 기능입니다.`,
    "cm03_2400.title.async" : `데이터 동기화`,
    "cm03_2400.title.notice" : `주의사항`,
    "cm03_2500.btn.all" : `전체`,
    "cm03_2500.btn.baseFee" : `기준요금`,
    "cm03_2500.btn.cancel" : `취소`,
    "cm03_2500.btn.close" : `닫기`,
    "cm03_2500.btn.days" : `요일`,
    "cm03_2500.btn.delete" : `삭제`,
    "cm03_2500.btn.period" : `기간`,
    "cm03_2500.btn.save" : `저장`,
    "cm03_2500.btn.select" : `선택`,
    "cm03_2500.btn.unSelect" : `선택 해제`,
    "cm03_2500.message.alertNoMapping" : `채널상품이 매핑되지 않은 상품그룹은 요금세트 적용을 할 수 없습니다. <br> 상품관리에서 매핑 현황을 확인해주세요.`,
    "cm03_2500.message.batchAlert1" : `<div style='margin-bottom:10px;'>모든 상품그룹에 요금세트 일괄적용 시 아래와 같이 변경되므로 유의 바랍니다.</div>`,
    "cm03_2500.message.batchAlert2" : `<div>- 기존에 개별 상품그룹에 등록되어 있는 요금세트는 삭제되며,</div>`,
    "cm03_2500.message.batchAlert3" : `<div style='padding-left:10px;margin-bottom:10px;'>일괄적용으로 등록한 요금세트가 적용됩니다.</div>`,
    "cm03_2500.message.batchAlert4" : `<div>- 일괄적용으로 요금세트 'A'를 적용 시,개별 상품그룹에 생성되어 있는 </div>`,
    "cm03_2500.message.batchAlert5" : `<div style='padding-left:10px;'>요금세트 'A'가 해당 상품그룹에 개별 적용됩니다.</div>`,
    "cm03_2500.message.batchAlert6" : `<div style='padding-left:6px;'>(요금세트 'A'가 생성되어 있지 않는 상품그룹은 요금이 적용되지 않습니다.)</div>`,
    "cm03_2500.message.batchAlert7" : `<div style='padding-left:10px;margin-bottom:10px; color:red;'>(일괄 적용의 알파벳은 요금 세트의 정렬 순서를 나타냅니다.)</div>`,
    "cm03_2500.message.multiRegAlert" : `달력에  <i class='badge badge-primary round' style='background-color:#ffBB00;'>M</i> 표시가 있는 날짜는 해당 일자에 2개 이상의 요금세트가 적용된 경우입니다.`,
    "cm03_2500.message.occChngAlert" : `달력에  <i class='badge badge-primary round'>C</i> 표시가 있는 날짜는 요금세트의 객실 타입별 점유율이 설정된 경우입니다.`,
    "cm03_2500.title.allCreate" : `일괄적용`,
    "cm03_2500.title.allCreateMethod" : `요금세트 '일괄적용' 체크 후 세트 적용 시 모든 상품그룹에 일괄 적용됩니다.`,
    "cm03_2500.title.allGoodGroup" : `모든 상품그룹`,
    "cm03_2500.title.allSelect" : `전부선택`,
    "cm03_2500.title.allUnSelect" : `전부선택 해제`,
    "cm03_2500.title.deleteMethod" : `달력에서원하는일자에드래그하여저장하세요.`,
    "cm03_2500.title.deleteSuccess" : `삭제 되었습니다.`,
    "cm03_2500.title.feeSet" : `요금세트`,
    "cm03_2500.title.feeSetApply" : `요금세트적용`,
    "cm03_2500.title.feeTypes" : `요금타입`,
    "cm03_2500.title.fri" : `금`,
    "cm03_2500.title.goodsGroup" : `상품그룹`,
    "cm03_2500.title.maxDate" : `* 최대 설정 기간 단위는 1년 입니다.`,
    "cm03_2500.title.mon" : `월`,
    "cm03_2500.title.occAlert" : `달력에 <i class='badge badge-primary round'>C</i>표시가 있는 일자는 해당 일의 요금세트가 점유율에 따라 자동으로 요금이 변동됩니다.`,
    "cm03_2500.title.percentOver" : `%초과`,
    "cm03_2500.title.periodInsert" : `기간으로입력`,
    "cm03_2500.title.sat" : `토`,
    "cm03_2500.title.saveSuccess" : `저장 되었습니다.`,
    "cm03_2500.title.selectMethod" : `상품그룹과요금세트를선택후해당월을선택하세요.`,
    "cm03_2500.title.sun" : `일`,
    "cm03_2500.title.thr" : `목`,
    "cm03_2500.title.tue" : `화`,
    "cm03_2500.title.wed" : `수`,
    "cm03_2700.message.noModify" : `변경된 데이터가 없습니다.`,
    "cm03_2700.message.roomTypeProperty" : `CMS에 매핑 되어있는 채널 룸타입 정보를 확인하고 수정할 수 있습니다.`,
    "cm03_2700.message.success" : `저장 되었습니다.`,
    "cm03_2700.title.channelName" : `채널명`,
    "cm03_2700.title.content" : `내용`,
    "cm03_2700.title.info" : `정보`,
    "cm03_2700.title.no" : `아니오`,
    "cm03_2700.title.roomType" : `채널 룸 타입`,
    "cm03_2700.title.roomTypeProperty" : `객실타입 속성`,
    "cm03_2700.title.roomTypePropertyInfo" : `객실타입 속성 정보`,
    "cm03_2700.title.yes" : `예`,
    "cm03_2800.message.noModify" : `변경된 데이터가 없습니다.`,
    "cm03_2800.message.success" : `저장 되었습니다.`,
    "cm03_2800.title.chRoomType" : `채널객실`,
    "cm03_2800.title.channelName" : `채널명`,
    "cm03_2800.title.cmRateType" : `상품그룹`,
    "cm03_2800.title.no" : `아니오`,
    "cm03_2800.title.productName" : `상품명`,
    "cm03_2800.title.rateType" : `요금 룸 타입`,
    "cm03_2800.title.rateTypeProperty" : `프로덕트 코드 속성 정보`,
    "cm03_2800.title.yes" : `예`,
    "cm03_2900.popup.goodsAllCopy" : `상품그룹 일괄 설정`,
    "cm03_2900.popup.salesStatus" : `판매 마감`,
    "cm03_2900.title.channelByRate" : `채널별요금`,
    "cm03_2900.title.cmRaAlRmsEmpty" : `공백처리`,
    "cm03_2900.title.fri" : `금`,
    "cm03_2900.title.mon" : `월`,
    "cm03_2900.title.noSetting" : `설정안함`,
    "cm03_2900.title.sat" : `토`,
    "cm03_2900.title.sun" : `일`,
    "cm03_2900.title.thu" : `목`,
    "cm03_2900.title.tooltipMessage5" : `상품별 인벤토리 관리를 지원하는 채널만 노출됩니다.`,
    "cm03_2900.title.tue" : `화`,
    "cm03_2900.title.wed" : `수`,
    "cm03_3100.message.alertRoomgroup" : `객실그룹을 선택해 주세요`,
    "cm03_3100.title.channelGoods" : `채널상품`,
    "cm03_3100.title.channelRoom" : `채널객실`,
    "cm03_3100.title.closed" : `마감처리`,
    "cm03_3100.title.controlRate" : `금액설정`,
    "cm03_3100.title.goodsGroup" : `상품그룹`,
    "cm03_3100.title.hotelAll" : `호텔전체`,
    "cm03_3100.title.hotelRoom" : `호텔객실`,
    "cm03_3100.title.maxRate" : `최대금액`,
    "cm03_3100.title.minRate" : `최소금액`,
    "cm03_3100.title.occupancy" : `점유율`,
    "cm03_3100.title.remain" : `잔여수량`,
    "cm03_3100.title.roomGroup" : `객실그룹`,
    "cm03_3100.title.saleDate" : `판매일자`,
    "cm03_3100.title.tooltipCloseCause1" : `호텔전체객실 잔여수량이 0이어서 마감`,
    "cm03_3100.title.tooltipCloseCause10" : `금액이 설정되지 않거나 0원으로 설정되어 마감`,
    "cm03_3100.title.tooltipCloseCause11" : `객실그룹에 설정된 최소금액보다 설정금액이 작아서 마감`,
    "cm03_3100.title.tooltipCloseCause12" : `객실그룹에 설정된 최대금액보다 설정금액이 커서 마감`,
    "cm03_3100.title.tooltipCloseCause13" : `상품그룹에 설정된 최소금액보다 설정금액이 작아서 마감`,
    "cm03_3100.title.tooltipCloseCause14" : `상품그룹에 설정된 최대금액보다 설정금액이 커서 마감`,
    "cm03_3100.title.tooltipCloseCause15" : `호텔전체객실에 설정된 점유율을 초과하여 마감`,
    "cm03_3100.title.tooltipCloseCause16" : `PMS객실에 설정된 점유율을 초과하여 마감`,
    "cm03_3100.title.tooltipCloseCause2" : `PMS객실 잔여수량이 0이어서 마감`,
    "cm03_3100.title.tooltipCloseCause3" : `객실그룹 잔여수량이 0이어서 마감`,
    "cm03_3100.title.tooltipCloseCause4" : `채널객실 잔여수량이 0이어서 마감`,
    "cm03_3100.title.tooltipCloseCause5" : `호텔전체 마감버튼을 통한 마감`,
    "cm03_3100.title.tooltipCloseCause6" : `PMS객실 마감버튼을 통한 마감`,
    "cm03_3100.title.tooltipCloseCause7" : `객실그룹 마감버튼을 통한 마감`,
    "cm03_3100.title.tooltipCloseCause8" : `채널객실 마감버튼을 통한 마감`,
    "cm03_3100.title.tooltipCloseCause9" : `채널상품 마감버튼을 통한 마감`,
    "cm03_3200.message.inputCondition" : `조회조건 @(0) 값을 입력해 주세요.`,
    "cm03_3200.title.applySet" : `적용세트`,
    "cm03_3200.title.changeDate" : `변경 일자`,
    "cm03_3200.title.channelFee" : `채널상품 요금`,
    "cm03_3200.title.channelProduct" : `채널 상품`,
    "cm03_3200.title.channelRoom" : `채널 객실`,
    "cm03_3200.title.closeYn" : `마감여부`,
    "cm03_3200.title.date" : `판매 일자`,
    "cm03_3200.title.hotelRoom" : `호텔 객실`,
    "cm03_3200.title.id" : `작업자`,
    "cm03_3200.title.no" : `아니오`,
    "cm03_3200.title.productGroup" : `상품 그룹`,
    "cm03_3200.title.productGroupFee" : `상품그룹 요금`,
    "cm03_3200.title.rateChangeHist" : `요금 변경 이력`,
    "cm03_3200.title.type" : `유형`,
    "cm03_3200.title.yes" : `예`,
    "cm03_3500.message.addDay" : `추가일수를 확인해 주세요.`,
    "cm03_3500.message.limitProperty" : `조회시 호텔 10개를 초과 할수 없습니다.`,
    "cm03_3500.message.notProperty" : `선택된 호텔이 없습니다.`,
    "cm03_3500.message.rateRange" : `금액범위를 확인해 주세요.`,
    "cm03_3500.message.startDate" : `시작일자를 확인해 주세요.`,
    "cm03_3500.message.weekendCheck" : `요일선택 조건을 확인해주세요.`,
    "cm03_3500.title.SaleDate" : `판매일자`,
    "cm03_3500.title.addDay" : `추가 일수`,
    "cm03_3500.title.channelName" : `채널명`,
    "cm03_3500.title.channelProduct" : `채널상품명`,
    "cm03_3500.title.channelRoom" : `채널객실명`,
    "cm03_3500.title.hotelName" : `호텔명`,
    "cm03_3500.title.rate" : `요금`,
    "cm03_3500.title.rateRange" : `금액범위`,
    "cm03_3500.title.rateSettingInfo" : `요금 설정 정보`,
    "cm03_3500.title.startDate" : `시작 일자`,
    "com.button.cancel" : `취소`,
    "com.button.complete" : `완료`,
    "com.button.initialize" : `초기화`,
    "com.button.modify" : `수정`,
    "com.button.next" : `다음`,
    "com.button.prev" : `이전`,
    "com.button.save" : `저장`,
    "com.button.search" : `조회`,
    "com.data.notAuthority" : `해당 권한이 없습니다.<br/> 호텔 관리자에게 문의 바랍니다.`,
    "com.data.notUsed" : `사용안함`,
    "com.data.use" : `사용`,
    "com.date.fri" : `금`,
    "com.date.mon" : `월`,
    "com.date.sat" : `토`,
    "com.date.sun" : `일`,
    "com.date.thu" : `목`,
    "com.date.tue" : `화`,
    "com.date.wed" : `수`,
    "com.date.year" : `년`,
    "com.email.grantChangeSuccess" : `권한이 변경되었습니다.`,
    "com.email.sendFail" : `메일 전송에 실패했습니다.`,
    "com.email.sendSuccess" : `성공적으로 전송했습니다.`,
    "com.error.common" : `Data 처리에 오류가 발생했습니다.<br/>시스템관리자에게 문의바랍니다.`,
    "com.error.timeout" : `호텔로 해당 정보의 요청시간이 초과되었습니다.<br/>시스템관리자에게 문의바랍니다.`,
    "com.grid.allSelected" : `전체`,
    "com.grid.filter" : `검색`,
    "com.grid.htmlMsg1" : `검색 결과가 없습니다.`,
    "com.grid.htmlMsg2" : `<span class="text-danger">다른 조회 조건</span>`,
    "com.grid.htmlMsg3" : `으로 검색해 보세요.`,
    "com.grid.manySelected" : `@(0)개 선택됨`,
    "com.grid.noData" : `데이터 없음`,
    "com.popup.customer" : `고객센터 접수`,
    "com.popup.error" : `에러`,
    "com.popup.ok" : `확인`,
    "com.popup.success" : `성공`,
    "com.popup.warning" : `경고`,
    "com.popup.wrongToken" : `잘못된 인증토큰 입니다.`,
    "com.select.sample1" : `영어1`,
    "com.select.sample2" : `영어2`,
    "com.select.sample3" : `영어3`,
    "com.select.sample4" : `영어4`,
    "com.session.expired" : `세션정보가 만료되었습니다.`,
    "com.title.change" : `변경`,
    "com.title.delete" : `삭제`,
    "com.title.firstReg" : `최초입력`,
    "com.title.step" : `단계`,
    "comn.message.auth_excel_assigned" : `엑셀 저장권한이 지정되었습니다.`,
    "comn.message.auth_excel_created" : `엑셀 저장권한이 부여되었습니다.`,
    "comn.message.auth_excel_removed" : `엑셀 저장권한이 제거되었습니다.`,
    "comn.message.auth_group_changed" : `%1에서 %2(으)로의 권한그룹이 변경되었습니다.`,
    "comn.message.auth_group_created" : `%1의 권한그룹이 부여되었습니다.`,
    "comn.message.auth_group_removed" : `%1의 권한그룹이 제거되었습니다.`,
    "comn.message.auth_hotel_changed" : `%1에서 %2(으)로의 호텔권한이 변경되었습니다.`,
    "comn.message.auth_hotel_created" : `%1의 호텔권한이 부여되었습니다.`,
    "comn.message.auth_hotel_removed" : `%1의 호텔권한이 제거되었습니다.`,
    "comn.message.auth_open_assigned" : `화면오픈권한이 지정되었습니다.`,
    "comn.message.auth_open_created" : `화면오픈권한이 부여되었습니다.`,
    "comn.message.auth_open_removed" : `화면오픈권한이 제거되었습니다.`,
    "comn.message.auth_report_assigned" : `보고서 출력권한이 지정되었습니다.`,
    "comn.message.auth_report_created" : `보고서 출력권한이 부여되었습니다.`,
    "comn.message.auth_report_removed" : `보고서 출력권한이 제거되었습니다.`,
    "comn.message.auth_save_assigned" : `저장권한이 지정되었습니다.`,
    "comn.message.auth_save_created" : `저장권한이 부여되었습니다.`,
    "comn.message.auth_save_removed" : `저장권한이 제거되었습니다.`,
    "comn.message.operation_added" : `%1에 %2이(가) 추가되었습니다.`,
    "comn.message.operation_assigned" : `%1을(를) %2(으)로 지정되었습니다.`,
    "comn.message.operation_changed" : `%1을(를) %2에서 %3로 변경되었습니다.`,
    "comn.message.operation_created" : `%1이(가) 생성되었습니다.`,
    "comn.message.operation_deleted" : `%1이(가) 삭제되었습니다.`,
    "comn.message.operation_removed" : `%1 값 %2이(가) 지워졌습니다.`,
    "comn.title.cms_system_name" : `WINGSCMS`,
    "comn.title.interface_complete" : `완료`,
    "comn.title.interface_recovered" : `복구`,
    "comn.title.interface_recovering_ask" : `해당 인터페이스를 복구하시겟습니까 ? `,
    "comn.title.interface_run" : `정상 연동`,
    "comn.title.interface_stop" : `연동 중지`,
    "comn.title.time_interval_day" : `일`,
    "comn.title.time_interval_hour" : `시간`,
    "comn.title.time_interval_minute" : `분`,
    "comn.title.time_interval_second" : `초`,
    "downloadReasonPopup.alert.fieldRequired" : `필수 값`,
    "downloadReasonPopup.alert.modifyData" : `변경된 데이터가 있습니다. 저장 후 이용해 주십시오.`,
    "downloadReasonPopup.alert.noGridData" : `그리드안에 데이터가 존재하지 않습니다.`,
    "downloadReasonPopup.alert.popupLabelReason" : `사유`,
    "downloadReasonPopup.alert.popupLabelUserName" : `사용자명`,
    "downloadReasonPopup.alert.popupTitleDownload" : `다운로드`,
    "downloadReasonPopup.alert.popupValidationReason" : `반드시 사유를 입력받아야 합니다.`,
    "downloadReasonPopup.alert.popupValidationUserName" : `사용자 이름이 비어 있습니다`,
    "error.title.content1" : `페이지를 표시할 수 없습니다.`,
    "error.title.content1Sub1" : `서비스 이용에 불편을 드려 죄송합니다.`,
    "error.title.content1Sub2" : `연결하려는 페이지 서버에 내부적으로  <span class='text-danger'>오류가 발생</span>하여 페이지를  표시할 수 없습니다.`,
    "grid.grand.total" : `합계`,
    "grid.header.age" : `나이1`,
    "grid.header.athlete" : `메달리스트`,
    "grid.header.bronze" : ` 동메달`,
    "grid.header.country" : `나라`,
    "grid.header.receivedate" : `수령일시`,
    "grid.header.silver" : `은메달`,
    "grid.header.sport" : `종목`,
    "grid.header.year" : `연도`,
    "grid.sub.total" : `소계`,
    "header.button.more" : `더보기`,
    "header.message.noData" : `등록된 데이터가 없습니다.`,
    "header.title.emergency" : `긴급`,
    "header.title.important" : `중요`,
    "header.title.langChinese" : `English`,
    "header.title.langEglish" : `中國語`,
    "header.title.langJapanese" : `日本語`,
    "header.title.langKorean" : `한국어`,
    "header.title.languageChange" : `언어선택`,
    "header.title.normal" : `일반`,
    "header.title.notification" : `Notification`,
    "header.title.notificationMessage" : `읽지 않은 메시지 <b>@(0)</b>건이 있습니다.`,
    "header.title.searchProperty" : `검색`,
    "header.title.selectProperty" : `프로퍼티를 선택하세요.`,
    "interLockingStep.title.interlocking" : `연동 채널 관리`,
    "m03_0600.message.productMapping2" : `표시를 누르면 맵핑이 해제됩니다.`,
    "manual.title.attachment" : `첨부파일`,
    "manual.title.close" : `닫기`,
    "manual.title.download" : `다운`,
    "manual.title.item" : `변경항목`,
    "manual.title.settingDate" : `설정일자`,
    "multiLangPopup.button.save" : `저장`,
    "multiLangPopup.title.cancel" : `취소`,
    "multiLangPopup.title.chinese" : `중국어`,
    "multiLangPopup.title.close" : `닫기`,
    "multiLangPopup.title.english" : `영어`,
    "multiLangPopup.title.japanese" : `일본어`,
    "multiLangPopup.title.korean" : `한국어`,
    "multiLangPopup.title.language" : `다국어`,
    "page.title.searchgrid" : `조회 그리드`,
    "pagetitle.button.tutorial" : `튜토리얼`,
    "pagination.etc.items_per_page" : `/ 쪽`,
    "pagination.etc.jump_to" : `이동하기`,
    "pagination.etc.jump_to_confirm" : `확인하다`,
    "pagination.etc.next_3" : `다음 3 페이지`,
    "pagination.etc.next_5" : `다음 5 페이지`,
    "pagination.etc.next_page" : `다음 페이지`,
    "pagination.etc.of" : `of`,
    "pagination.etc.page" : ``,
    "pagination.etc.prev_3" : `이전 3 페이지`,
    "pagination.etc.prev_5" : `이전 5 페이지`,
    "pagination.etc.prev_page" : `이전 페이지`,
    "productManageGrid.button.add" : `추가`,
    "productManageGrid.button.no" : `아니오`,
    "productManageGrid.button.setting" : `설정`,
    "productManageGrid.button.yes" : `예`,
    "productManageGrid.message.abled" : `활성화 하시겠습니까?`,
    "productManageGrid.message.channelName" : `채널에서 호출된 상품을 관리하려면<br/>[채널명]을 선택해주세요.`,
    "productManageGrid.message.channelNameDesc" : `채널에서 호출된 상품을 관리하려면 [채널명]을 선택해 주세요.`,
    "productManageGrid.message.disabled" : `비활성화 하시겠습니까?`,
    "productManageGrid.message.noSetting" : `설정 하지 않음<br />CMS로 관리 하지 않음`,
    "productManageGrid.message.noSettingTooltip" : `CMS로 관리하지않는 코드의 경우<br/>요금 /인벤토리는 채널의<br/>엑스트라넷을 통해서 관리하야합니다.`,
    "productManageGrid.message.productGroup" : `상품 그룹을<br /> 추가해 주세요.`,
    "productManageGrid.message.roomGroup" : `객실 그룹을<br /> 추가해 주세요.`,
    "productManageGrid.message.setting" : `상품 그룹의 요금을 먼저 세팅하여<br/> 편하게 관리 해 보세요.`,
    "searchFilter.button.init" : `초기화`,
    "searchFilter.button.save" : `저장`,
    "searchFilter.button.search" : `검색`,
    "searchFilter.title.all" : `전체`,
    "searchFilter.title.condition" : `검색조건`,
    "searchFilter.title.conditionAdd" : `조건추가`,
    "tutorial.button.allStopView" : `모든 메뉴 <BR/>튜토리얼 그만보기`,
    "tutorial.button.stopView" : `해당 메뉴 <BR/>튜토리얼 그만보기`,
    "tutorial.label.tutorialStopView" : `튜토리얼 그만 보기`,
    "tutorial.title.exit" : `튜토리얼을 종료합니다.`,
    "tutorial.title.newWingsCms" : `새로워진<br/><b>WINGS CMS</b>를 소개합니다.`,
    "tutorial.title.subExit" : `다시 보시려면 메뉴 별<br/>[튜토리얼] 에서 확인 가능합니다.`,
    "zz01_0100.button.login" : `로그인`,
    "zz01_0100.button.searchId" : `아이디 찾기`,
    "zz01_0100.button.searchPassword" : `비밀번호 찾기`,
    "zz01_0100.title.allegro" : `알레그로`,
    "zz01_0100.title.allegroAlert" : `알레그로는 [컴퍼니 ID]가 필요하지 않습니다.`,
    "zz01_0100.title.companyId" : `컴퍼니 ID`,
    "zz01_0100.title.info" : `아래 로그인 정보를 입력하여 접속해 주세요.`,
    "zz01_0100.title.join" : `회원가입`,
    "zz01_0100.title.keepLogin" : `로그인 유지`,
    "zz01_0100.title.noneJoin" : `계정이 없나요?`,
    "zz01_0100.title.password" : `비밀번호`,
    "zz01_0100.title.userId" : `사용자 ID / 이메일`,
    "zz01_0100.title.validationCheck" : `@(0)을 입력해 주세요.`,
    "zz01_0100.title.welcome" : `환영합니다.`,
    "zz01_0200.btn.passwordInit" : `비밀번호 초기화`,
    "zz01_0200.message.noAccess" : `잘못된 접근입니다.`,
    "zz01_0200.title.lockMessage" : `로그인 정보 5회 입력 오류로 인해 <br/> 자동 잠금처리 되었습니다.`,
    "zz01_0200.title.lockNotice" : `아래 <span class='text-primary'>비밀번호 초기화</span> 버튼을 눌러 임시 비밀번호를 발급 받고 로그인을 해주세요.`,
    "zz01_0400.button.login" : `로그인`,
    "zz01_0400.message.info1" : `SanhaIt 통합 로그인 시스템에서는 회원 여러분의 소중한 개인정보를 최선을 다해 보호하도록 노력하겠습니다.`,
    "zz01_0400.message.info2" : `회원 여러분의 개인정보는 동의없이 공개되지 않으며, 개인정보보호정책에 따라 안전하게 관리되고 있습니다.`,
    "zz01_0400.title.userId" : `사용자 ID`,
    "zz01_0500.button.initPw" : `비밀번호 초기화`,
    "zz01_0500.button.login" : `로그인`,
    "zz01_0500.message.info1" : `비밀번호 정책(password Policy)`,
    "zz01_0500.message.info2" : `비밀번호 숫자, 영문, 특수문자 3가지가 조합되어야 하며, 최소 8자 이상 최대 15자 입니다.`,
    "zz01_0500.message.info3" : `비밀번호 변경 주기는 180일 입니다.`,
    "zz01_0500.message.info4" : `기존 사용했던 비밀번호는 사용 불가능합니다.`,
    "zz01_0500.message.validationError" : `모든 정보는 필수로 입력되어야 합니다. 정보를 입력해주세요.`,
    "zz01_0500.title.allegro" : `알레그로`,
    "zz01_0500.title.allegroAlert" : `알레그로는 [컴퍼니 ID]가 필요하지 않습니다.`,
    "zz01_0500.title.companyId" : `컴퍼니 ID`,
    "zz01_0500.title.email" : `등록된 이메일`,
    "zz01_0500.title.forgetPw" : `비밀번호를 잊어버렸나요?`,
    "zz01_0500.title.forgetPwInfo" : `아래 정보를 통해서 비밀번호를 초기화 해 보세요.`,
    "zz01_0500.title.userId" : `사용자ID/이메일`,
    "zz01_0500.validation.validationEmailError" : `올바른 이메일 주소를 입력하세요.`,
    "zz01_0500.validation.validationEmptyError" : `모든 정보는 필수로 입력되어야 합니다. 정보를 입력해주세요.`,
    "zz01_0600.message.nonAgree" : `아직 시스템 관리자가 가입인증을 <br/> 하지 않았습니다.`,
    "zz01_0600.message.nonNotice" : `조금만 기다려주세요. 가입인증은 <span class='text-primary'>1~2일 정도 소요</span>될 수 있습니다.`,
    "zz01_0700.button.login" : `로그인`,
    "zz01_0700.message.info" : `시스템을 다시 이용하시려면 아래 <b class='text-primary'>[로그인]</b> 화면 버튼을 눌러주시기 바랍니다.`,
    "zz01_0700.message.title" : `안전한 웹을 위해 30분 동안 반응이 없어서 <span class='d-sm-block'></span>자동 로그아웃 되었습니다.`,
    "zz01_0700.message.userlogout" : `사용자의 의해서 로그아웃 되었습니다.`,
    "zz01_0800.button.findId" : `아이디 찾기`,
    "zz01_0800.button.login" : `로그인`,
    "zz01_0800.message.validationError" : `모든 정보는 필수로 입력되어야 합니다. 정보를 입력해주세요.`,
    "zz01_0800.title.allegro" : `알레그로`,
    "zz01_0800.title.allegroAlert" : `알레그로는 [컴퍼니 ID]가 필요하지 않습니다.`,
    "zz01_0800.title.companyId" : `컴퍼니 ID`,
    "zz01_0800.title.email" : `등록된 이메일`,
    "zz01_0800.title.forgetId" : `아이디를 잊어버렸나요?`,
    "zz01_0800.title.forgetIdInfo" : `아래 정보를 통해서 아이디를 찾아보세요.`,
    "zz01_0800.title.userName" : `사용자 명`,
    "zz01_0800.validation.validationEmailError" : `올바른 이메일 주소를 입력하세요.`,
    "zz01_0800.validation.validationEmptyError" : `모든 정보는 필수로 입력되어야 합니다. 정보를 입력해주세요.`,
    "zz01_0900.message.choiceInfo" : `접속`,
    "zz01_0900.message.information" : `접속할 프로퍼티를 선택해 주세요.`,
    "zz01_0900.message.logoutButton" : `로그아웃`,
    "zz01_0900.message.noAccess" : `잘못된 접근입니다.`,
    "zz01_0900.message.noSelected" : `접속 프로퍼티를 선택해 주세요.`,
    "zz01_0900.message.selectInfo" : `프로퍼티 선택…`,
    "zz01_0900.title.label1" : `WINGS의 완벽 호환`,
    "zz01_0900.title.label2" : `<b class='text-success'>CMS</b> + <b class='text-warning'>PMS</b> 동시 사용으로 시작하세요`,
    "zz01_1000.button.passwordChange" : `비밀번호 변경`,
    "zz01_1000.etc.passwordChangeInfo" : `비밀번호는 3개월 마다 변경하는것을 추천합니다`,
    "zz01_1000.message.checkInput" : `을(를) 입력해 주세요.`,
    "zz01_1000.message.info01" : `개인정보의 안정성 확보조치 제5조(비밀번호관리)`,
    "zz01_1000.message.noAccess" : `잘못된 접근입니다.`,
    "zz01_1000.message.passwordPolicyInfo02" : `비밀번호는 숫자,영문,특수문자 3가지 조합 시 최소 8자 이상 최대 20자 입니다.`,
    "zz01_1000.message.passwordPolicyInfo03" : `비밀번호 변경 주기는 90일 입니다.`,
    "zz01_1000.message.passwordPolicyInfo04" : `기존 사용했던 비밀번호는 사용 불가합니다.`,
    "zz01_1000.success.changeNewPassword" : `Well done! 비밀번호가 변경되었습니다.`,
    "zz01_1000.title.newPassword" : `신규 비밀번호`,
    "zz01_1000.title.newPasswordCheck" : `신규 비밀번호 확인`,
    "zz01_1000.title.oldPassword" : `현재 비밀번호`,
    "zz01_1000.title.passwordChange" : `비밀번호 변경`,
    "zz01_1000.title.passwordPolicy" : `비밀번호 정책 (Passward Policy)`,
    "zz01_1000.validate.passwordWarning01" : `Warning! 신규비밀번호와 확인이 일치하지 않습니다.`,
    "zz01_1000.validate.passwordWarning02" : `Warning! 비밀번호는 숫자,영문,특수문자 3가지 조합 시 최소 8자 이상 최대 20자 입니다.`,
    "zz01_1000.validate.passwordWarning03" : `Warning! 비밀번호를 다시 확인하세요. 비밀번호를 잘못 입력하셨습니다.`,
    "zz01_1100.button.set" : `비밀번호 재설정`,
    "zz01_1100.message.Error" : `변경에 실패하였습니다.`,
    "zz01_1100.message.Success" : `패스워드가 변경되었습니다. `,
    "zz01_1100.message.info1" : `비밀번호 정책(password Policy)`,
    "zz01_1100.message.info2" : `비밀번호 숫자, 영문, 특수문자 3가지가 조합되어야 하며, 최소 8자 이상 최대 20자 입니다.`,
    "zz01_1100.message.info3" : `비밀번호 변경 주기는 180일 입니다.`,
    "zz01_1100.message.info4" : `기존 사용했던 비밀번호는 사용 불가능합니다.`,
    "zz01_1100.message.validationError" : `모든 정보는 필수로 입력되어야 합니다. 정보를 입력해주세요.`,
    "zz01_1100.message.validationError1" : `비밀번호는 숫자, 영문, 특수문자 3가지가 조합되어야 합니다. 정보를 입력해주세요.`,
    "zz01_1100.message.validationError2" : `동일한 비빌번호를 입력해주세요.`,
    "zz01_1100.title.forgetPw" : `비밀번호를 잊어버렸나요?`,
    "zz01_1100.title.forgetPwInfo" : `신규 비밀번호를 재설정 해 보세요.`,
    "zz01_1100.title.pw" : `신규 비밀번호`,
    "zz01_1100.title.pwComfirm" : `신규 비밀번호 확인`,
    "zz01_1200.title.twofactor" : `OTP Code`,
    "zz02_0300.button.auth" : `인증`,
    "zz02_0300.button.authRequest" : `인증번호 요청`,
    "zz02_0300.button.cancel" : `취소`,
    "zz02_0300.button.change" : `변경`,
    "zz02_0300.button.etiquetteModeNotSet" : `설정안함`,
    "zz02_0300.button.etiquetteModeSet" : `설정`,
    "zz02_0300.button.management" : `관리`,
    "zz02_0300.button.save" : `저장`,
    "zz02_0300.button.smsAlarmReceiveNotSet" : `설정안함`,
    "zz02_0300.button.smsAlarmReceiveSet" : `설정`,
    "zz02_0300.button.userAuthManagement" : `사용자 권한 관리`,
    "zz02_0300.button.userInfo" : `사용자 정보`,
    "zz02_0300.title.alarmSetUp" : `실시간 알람 설정`,
    "zz02_0300.title.alarmSetUpFour" : `에티켓 모드 설정시 밤 9시부터 다음날 오전 9시 까지는 알림이 전송되지 않으며, 이 시간 동안 발생하는 내역은 오전 9시에 취합하여 전송됩니다.`,
    "zz02_0300.title.alarmSetUpOne" : `실시간 알림 수신 설정 시 전송 에러, 시스템 통신 중단 동의 알림이 SMS 로 수신 됩니다. 시스템 통신 중단의 경우 30 분 이상 통신 에러가 발생할 경우 SMS 로 알림이 수신됩니다.`,
    "zz02_0300.title.alarmSetUpThree" : `알림 수신 설정 변경 후 실제 적용까지 야 30 분 정도 시간이 소유될 수 있습니다.`,
    "zz02_0300.title.alarmSetUpTwo" : `국내 휴대폰 번호 사용자만 SMS 수신이 가능합니다.`,
    "zz02_0300.title.authNumber" : `인증번호`,
    "zz02_0300.title.companyId" : `컴퍼니 ID`,
    "zz02_0300.title.email" : `이메일 주소`,
    "zz02_0300.title.etiquetteMode" : `에티켓 모드`,
    "zz02_0300.title.first_registered_date" : `최초 가입일`,
    "zz02_0300.title.lastUpdateDate" : `최종 업데이트`,
    "zz02_0300.title.myUserInfo" : `내 정보`,
    "zz02_0300.title.phone" : `핸드폰 번호`,
    "zz02_0300.title.property" : `사용자 권한`,
    "zz02_0300.title.pwChange" : `비밀번호 변경`,
    "zz02_0300.title.pwInfo" : `비밀번호는 3개월 마다 변경하는 것을 추천합니다.`,
    "zz02_0300.title.smsAlarmReceive" : `SMS 알람 수신`,
    "zz02_0300.title.smsReceiveContact" : `SMS 알람 연락처`,
    "zz02_0300.title.userId" : `로그인 ID`,
    "zz02_0300.title.userInfo" : `사용자 정보`,
    "zz02_0300.title.userName" : `사용자 이름`,
    "zz02_0300.title.user_permission" : `사용자 권한`,
    "zz02_0400.button.auth" : `인증`,
    "zz02_0400.button.authRequest" : `인증번호 요청`,
    "zz02_0400.button.cancel" : `취소`,
    "zz02_0400.button.change" : `변경`,
    "zz02_0400.button.etiquetteModeNotSet" : `설정안함 (24시간 수신)`,
    "zz02_0400.button.etiquetteModeSet" : `설정 (21시~09시 알람 수신 안함)`,
    "zz02_0400.button.management" : `관리`,
    "zz02_0400.button.reAuth" : `재 인증`,
    "zz02_0400.button.save" : `저장`,
    "zz02_0400.button.smsAlarmReceiveNotSet" : `설정안함`,
    "zz02_0400.button.smsAlarmReceiveSet" : `설정`,
    "zz02_0400.title.adminPwd" : `관리자 변경`,
    "zz02_0400.title.adminPwdModify" : `관리자 비밀번호 변경`,
    "zz02_0400.title.alarmSetUp" : `실시간 알람 설정`,
    "zz02_0400.title.alarmSetUpFour" : `에티켓 모드 설정시 밤 9시부터 다음날 오전 9시 까지는 알림이 전송되지 않으며, 이 시간 동안 발생하는 내역은 오전 9시에 취합하여 전송됩니다.`,
    "zz02_0400.title.alarmSetUpOne" : `실시간 알림 수신 설정 시 전송 에러, 시스템 통신 중단 동의 알림이 SMS 로 수신 됩니다. 시스템 통신 중단의 경우 30 분 이상 통신 에러가 발생할 경우 SMS 로 알림이 수신됩니다.`,
    "zz02_0400.title.alarmSetUpThree" : `알림 수신 설정 변경 후 실제 적용까지 야 30 분 정도 시간이 소유될 수 있습니다.`,
    "zz02_0400.title.alarmSetUpTwo" : `국내 휴대폰 번호 사용자만 SMS 수신이 가능합니다.`,
    "zz02_0400.title.authNumber" : `인증번호`,
    "zz02_0400.title.companyId" : `컴퍼니 ID`,
    "zz02_0400.title.email" : `이메일 주소`,
    "zz02_0400.title.etiquetteMode" : `에티켓 모드`,
    "zz02_0400.title.first_registered_date" : `최초 가입일`,
    "zz02_0400.title.inputLabelNumber" : `휴대폰 번호입력`,
    "zz02_0400.title.lastUpdateDate" : `최종 업데이트`,
    "zz02_0400.title.phone" : `핸드폰 번호`,
    "zz02_0400.title.property" : `사용자 권한`,
    "zz02_0400.title.propertyName" : `프로퍼티 명`,
    "zz02_0400.title.pwChange" : `비밀번호 변경`,
    "zz02_0400.title.pwInfo" : `비밀번호는 3개월 마다 변경하는 것을 추천합니다.`,
    "zz02_0400.title.smsAlarmReceive" : `SMS 알람 수신`,
    "zz02_0400.title.smsReceiveContact" : `SMS 수신 연락처`,
    "zz02_0400.title.userId" : `로그인 ID`,
    "zz02_0400.title.userInfo" : `사용자 정보`,
    "zz02_0400.title.userName" : `사용자 이름`,
    "zz02_0400.title.user_permission" : `사용자 권한`,
    "zz02_0400.validate.auth" : `인증완료`,
    "zz02_0400.validate.phoneNumber" : `숫자만 입력하시오.`,
    "zz02_0500.button.passwordChange" : `비밀번호 변경`,
    "zz02_0500.button.set" : `비밀번호 재설정`,
    "zz02_0500.etc.passwordChangeInfo" : `비밀번호는 3개월 마다 변경하는것을 추천합니다`,
    "zz02_0500.message.Error" : `변경에 실패하였습니다.`,
    "zz02_0500.message.Success" : `패스워드가 변경되었습니다. `,
    "zz02_0500.message.info01" : `개인정보의 안정성 확보조치 제5조(비밀번호관리)`,
    "zz02_0500.message.info1" : `비밀번호 정책(password Policy)`,
    "zz02_0500.message.info2" : `비밀번호 숫자, 영문, 특수문자 3가지가 조합되어야 하며, 최소 8자 이상 최대 20자 입니다.`,
    "zz02_0500.message.info3" : `비밀번호 변경 주기는 180일 입니다.`,
    "zz02_0500.message.info4" : `기존 사용했던 비밀번호는 사용 불가능합니다.`,
    "zz02_0500.message.passwordPolicyInfo02" : `비밀번호는 숫자,영문,특수문자 3가지 조합 시 최소 8자 이상 최대 20자 입니다.`,
    "zz02_0500.message.passwordPolicyInfo03" : `비밀전호 변경 주기는 약 90일 입니다.`,
    "zz02_0500.message.passwordPolicyInfo04" : `기존 사용했던 비밀번호는 사용 불가합니다.`,
    "zz02_0500.message.validationError" : `모든 정보는 필수로 입력되어야 합니다. 정보를 입력해주세요.`,
    "zz02_0500.message.validationError1" : `비밀번호는 숫자, 영문, 특수문자 3가지가 조합되어야 합니다. 정보를 입력해주세요.`,
    "zz02_0500.message.validationError2" : `동일한 비빌번호를 입력해주세요.`,
    "zz02_0500.success.changeNewPassword" : `Well done! 비밀번호가 변경되었습니다.`,
    "zz02_0500.success.changePassword" : `비밀번호가 변경되었습니다.`,
    "zz02_0500.title.forgetPw" : `비밀번호를 잊어버렸나요?`,
    "zz02_0500.title.forgetPwInfo" : `신규 비밀번호를 재설정 해 보세요.`,
    "zz02_0500.title.newPassword" : `신규 비밀번호`,
    "zz02_0500.title.newPasswordCheck" : `신규 비밀번호 확인`,
    "zz02_0500.title.oldPassword" : `현재 비밀번호`,
    "zz02_0500.title.passwordChange" : `비밀번호 변경`,
    "zz02_0500.title.passwordPolicy" : `비밀번호 정책 (Passward Policy)`,
    "zz02_0500.title.pw" : `신규 비밀번호`,
    "zz02_0500.title.pwComfirm" : `신규 비밀번호 확인`,
    "zz02_0500.validate.inputPasswordWarning01" : `신규비밀번호와 확인이 일치하지 않습니다.`,
    "zz02_0500.validate.inputPasswordWarning02" : `비밀번호는 숫자,영문,특수문자 3가지 조합 시 최소 8자 이상 최대 20자 입니다.`,
    "zz02_0500.validate.inputPasswordWarning03" : `비밀번호를 다시 확인하세요. 비밀번호를 잘못 입력하셨습니다.`,
    "zz02_0500.validate.passwordWarning01" : `Warning! 신규비밀번호와 확인이 일치하지 않습니다.`,
    "zz02_0500.validate.passwordWarning02" : `Warning! 비밀번호는 숫자,영문,특수문자 3가지 조합 시 최소 8자 이상 최대 20자 입니다.`,
    "zz02_0500.validate.passwordWarning03" : `Warning! 비밀번호를 다시 확인하세요. 비밀번호를 잘못 입력하셨습니다.`,
    "zz02_0600.button.gridCancel" : `취소`,
    "zz02_0600.button.gridSave" : `저장`,
    "zz02_0600.button.init" : `초기화`,
    "zz02_0600.button.search" : `검색`,
    "zz02_0600.button.useAll" : `전체`,
    "zz02_0600.button.useN" : `사용안함`,
    "zz02_0600.button.useY" : `사용`,
    "zz02_0600.button.userAuthGrpPopupNo" : `아니오`,
    "zz02_0600.button.userAuthGrpPopupYes" : `예`,
    "zz02_0600.etc.gridUseN" : `사용안함`,
    "zz02_0600.etc.gridUseY" : `사용`,
    "zz02_0600.message.userAuthGrpPopup" : `사용자 그룹의 권한을 재지정하시겟습니까?`,
    "zz02_0600.title.gridCert" : `인증`,
    "zz02_0600.title.gridExcel" : `엑셀`,
    "zz02_0600.title.gridPrint" : `출력`,
    "zz02_0600.title.gridSave" : `저장`,
    "zz02_0600.title.gridSelect" : `조회`,
    "zz02_0600.title.gridSystemMenuName" : `시스템 메뉴 명`,
    "zz02_0600.title.gridUse" : `사용여부`,
    "zz02_0600.title.gridUser" : `사용자명`,
    "zz02_0600.title.gridUserId" : `사용자 ID`,
    "zz02_0600.title.menuAuth" : `메뉴권한`,
    "zz02_0600.title.propertyName" : `COMPANY ID_프로퍼티명`,
    "zz02_0600.title.use" : `사용여부`,
    "zz02_0600.title.userAuthGrp" : `사용자 권한 그룹`,
    "zz02_0600.title.userGrpAuthPopup" : `사용자 그룹 권한`,
    "zz02_0600.title.userInfo" : `사용자 정보`,
    "zz02_0600.title.userName" : `사용자 명`,
    "zz02_0700.button.cancel" : `취소`,
    "zz02_0700.button.dataReset" : `데이터 초기화`,
    "zz02_0700.button.friday" : `금`,
    "zz02_0700.button.monday" : `월`,
    "zz02_0700.button.no" : `아니오`,
    "zz02_0700.button.notUsed" : `사용안함`,
    "zz02_0700.button.notUsedStatus" : `미사용`,
    "zz02_0700.button.pmsConnCheck" : `PMS 연결 체크`,
    "zz02_0700.button.pmsRoomInfo" : `PMS 객실 정보 호출`,
    "zz02_0700.button.pmsServiceInfo" : `PMS 서비스상품 정보 호출`,
    "zz02_0700.button.roomManagement" : `객실 정보 관리`,
    "zz02_0700.button.saturday" : `토`,
    "zz02_0700.button.save" : `저장`,
    "zz02_0700.button.serviceManagement" : `서비스 정보 관리`,
    "zz02_0700.button.sunday" : `일`,
    "zz02_0700.button.thursday" : `목`,
    "zz02_0700.button.tuesday" : `화`,
    "zz02_0700.button.use" : `사용`,
    "zz02_0700.button.wednesday" : `수`,
    "zz02_0700.button.yes" : `예`,
    "zz02_0700.message.checkPmsDb" : `호텔의 PMS DB연결을 확인하세요.`,
    "zz02_0700.message.dataInit" : `@(0) 호텔의 CMS연동을 해지 하시겠습니까?`,
    "zz02_0700.message.dataInitSuccess" : `CMS연동이 해지 되었습니다.`,
    "zz02_0700.message.dataReset" : `숙소에서 사용하고 있는 PMS가 변경 될 경우, 또는 CMS의 연동을 해지 할 경우 해당 버튼을 통해 모든 데이터를 삭제합니다.`,
    "zz02_0700.message.dataResetMsg" : `데이터를 초기화하면 현재 연동 되어 있는 모든 채널이 연동해지되고, 예약, 요금, 인벤토리 관리를 채널의 엑스트라넷을 통해서 진행해야 합니다.`,
    "zz02_0700.message.duplRoom" : `중복된 객실 타입이 존재합니다.`,
    "zz02_0700.message.inputEmailCheck" : `이메일 입력 데이터를 확인해 주세요.`,
    "zz02_0700.message.inputLatCheck" : `위도 입력 데이터를 확인해 주세요.`,
    "zz02_0700.message.inputLonCheck" : `경도 입력 데이터를 확인해 주세요.`,
    "zz02_0700.message.noModify" : `변경된 데이터가 없습니다.`,
    "zz02_0700.message.pmsCallSuccess" : `PMS의 객실정보를 성공적으로 호출하였습니다.`,
    "zz02_0700.message.pmsConnectionFailed" : `연결에 실패하였습니다.`,
    "zz02_0700.message.pmsConnectionSuccess" : `연결에 성공하였습니다.`,
    "zz02_0700.message.pmsServiceCallSuccess" : `PMS의 서비스상품 정보를 성공적으로 호출하였습니다.`,
    "zz02_0700.message.requireInput" : `@(0) 을(를) 입력해 주세요.`,
    "zz02_0700.message.requireManager" : `@(0) 을(를) 입력해 주세요. 관리자에게 문의 바랍니다.`,
    "zz02_0700.message.roomInfo" : `총 객실 수 : @(0)개`,
    "zz02_0700.message.roomInfoMsg" : `잔여객실수량을 정확하게 관리하려면 객실 정보를 업데이트 해주세요.`,
    "zz02_0700.message.saveSuccess" : `Well done! 저장되었습니다.`,
    "zz02_0700.message.serviceInfoMsg" : `채널의 서비스상품과 매핑을 위해 호텔의 서비스 상품을 업데이트 해주세요.`,
    "zz02_0700.message.success" : `정상 처리 되었습니다.`,
    "zz02_0700.title.add" : `추가`,
    "zz02_0700.title.adjRms" : `오버부킹객실수 사용`,
    "zz02_0700.title.adjRmsTooltip" : `오버부킹에 설정한 객실수만큼 추가하여 체널에 예역가능수량을 전송합니다.`,
    "zz02_0700.title.alarm" : `알림`,
    "zz02_0700.title.autoCloseYn" : `PMS 할당연동`,
    "zz02_0700.title.autoToolTip" : `1. AUTO ON 시 PMS의 잔여 객실 수만큼 자동 할당되며, 특정 기간만 AUTO 적용은 불가능합니다. <br/><br/>2. PMS를 사용하지 않고, CMS만 사용중인 경우 ‘객실 정보’에 등록된 객실 수가 적용됩니다. <br/><br/>3. AUTO OFF 시 직전 판매가능객실이 유지됩니다.`,
    "zz02_0700.title.businessNumber" : `사업자 번호`,
    "zz02_0700.title.channelManager" : `채널 매니저 정보`,
    "zz02_0700.title.closeMinRmRa" : `최저 객실료 사용`,
    "zz02_0700.title.closeMinRmRaTooltip" : `입력된 요금이 설정된 최저객실료보다 작은 경우 자동으로 예약마감이 처리가 됩니다.`,
    "zz02_0700.title.companyId" : `Company ID`,
    "zz02_0700.title.contractCount" : `계약 채널 수`,
    "zz02_0700.title.contractStatus" : `계약 상태`,
    "zz02_0700.title.currency" : `통화`,
    "zz02_0700.title.currencyUnit" : `통화 소수점`,
    "zz02_0700.title.cutOffDays" : `예약마감일 사용(도착일기준)`,
    "zz02_0700.title.cutOffDaysTooltip" : `도착일 기준으로 설정된 일수 이전부터 예약등록이 불가합니다.`,
    "zz02_0700.title.emailAddress" : `대표 이메일 주소`,
    "zz02_0700.title.faxNumber" : `팩스 번호`,
    "zz02_0700.title.grade" : `성급`,
    "zz02_0700.title.hotelSetting" : `호텔 설정`,
    "zz02_0700.title.importApi" : `PMS의 객실정보를 가져오시겠습니까?`,
    "zz02_0700.title.inputData" : `데이터를 입력해 주세요.`,
    "zz02_0700.title.labelPmsAgentUrl" : `PMS Agent URL`,
    "zz02_0700.title.latLonMessage" : `위도 경도를 통해서 숙소의 위치를 식별합니다.`,
    "zz02_0700.title.latitude" : `위도`,
    "zz02_0700.title.longitude" : `경도`,
    "zz02_0700.title.marketType" : `시장타입`,
    "zz02_0700.title.maxAdultCov" : `최대성인수`,
    "zz02_0700.title.maxChildCov" : `최대어린이수`,
    "zz02_0700.title.maxLos" : `최대투숙기간 제한 사용 (체크인 기준)`,
    "zz02_0700.title.maxLosTooltip" : `입실일자기준으로 예약이 가능한 최대박수, 초과 시 예약등록이 불가합니다.`,
    "zz02_0700.title.maxSta bn yThrou" : `최대 투숙기간 제한 사용 (체크인 기준)`,
    "zz02_0700.title.maxStayThrou" : `최대 연박 제한 사용 (투숙기간 기준)`,
    "zz02_0700.title.maxStayThrouTooltip" : `투숙일자 기준으로 예약이 가능한 최대박수, 초과 시 예약등록이 불가합니다.`,
    "zz02_0700.title.minAdultCov" : `기준성인수`,
    "zz02_0700.title.minLos" : `최소투숙기간 제한 사용 (체크인 기준)`,
    "zz02_0700.title.minLosTooltip" : `입실일자 기준으로 예약이 가능한 최소박수, 미만 시 예약등록이 불가합니다.`,
    "zz02_0700.title.minStayThrou" : `최소 연박 제한 사용 (투숙기간 기준)`,
    "zz02_0700.title.minStayThrouTooltip" : `투숙일자 기준으로 예약이 가능한 최소박수, 미만 시 예약등록이 불가합니다.`,
    "zz02_0700.title.modify" : `수정`,
    "zz02_0700.title.noArr" : `입실 제한 사용`,
    "zz02_0700.title.noArrTooltip" : `설정된 일자에 도착하는 예약등록이 불가합니다.`,
    "zz02_0700.title.noDep" : `퇴실 제한 사용`,
    "zz02_0700.title.noDepTooltip" : `설정된 일자에 입실일자 예약등록이 불가합니다.`,
    "zz02_0700.title.phoneNumber" : `대표 전화 번호`,
    "zz02_0700.title.pmsSetting" : `PMS 정보설정`,
    "zz02_0700.title.printYn" : `영수증출력여부`,
    "zz02_0700.title.propertyId" : `프로퍼티 ID`,
    "zz02_0700.title.propertyName" : `프로퍼티명`,
    "zz02_0700.title.radioOff" : `OFF`,
    "zz02_0700.title.radioOn" : `ON`,
    "zz02_0700.title.rateType" : `Rate Type`,
    "zz02_0700.title.region" : `국가/언어`,
    "zz02_0700.title.requireRoom" : `숙소정보(필수)`,
    "zz02_0700.title.reservationConstraint" : `예약제한 기능 설정 정보`,
    "zz02_0700.title.reservationPath" : `예약경로`,
    "zz02_0700.title.roomAddInfo" : `숙소 부가 정보`,
    "zz02_0700.title.roomInformation" : `객실 정보 (필수)`,
    "zz02_0700.title.roomName" : `객실명`,
    "zz02_0700.title.roomNumber" : `객실수`,
    "zz02_0700.title.roomType" : `객실타입`,
    "zz02_0700.title.salesEmp" : `영업직원`,
    "zz02_0700.title.sendRestrictRms" : `임의 객실수 전송 사용`,
    "zz02_0700.title.sendRestrictRmsTooltip" : `잔여 객실의 숫자를 임의로 지정하여 채널에 전송할때 사용하는 기능입니다. <br/>(* 예 : 현재 10개의 객실이 남아있으나, 판매채널의 마감임박 표시를 띄우기 위해 잔여객실을 3객실로 임의 전송) <br/>임의로 지정한 객실 수보다 실제 잔여객실이 적어지면 자동으로 실제 잔여 객실 수가 표현됩니다.`,
    "zz02_0700.title.serviceInfoImportApi" : `API를 통해 서비스 코드를 가져 오시겠습니까?`,
    "zz02_0700.title.serviceInfomation" : `서비스 정보`,
    "zz02_0700.title.status" : `상태`,
    "zz02_0700.title.svc" : `서비스(%)`,
    "zz02_0700.title.syncFunc" : `PMS 잔여객실 동기화 사용`,
    "zz02_0700.title.syncFuncTooltip" : `잔여객실현황 화면에서 PMS 잔여객실 동기화 기능의 사용여부를 설정합니다.`,
    "zz02_0700.title.systemInit" : `시스템 초기화`,
    "zz02_0700.title.systemManagementSetupInfo" : `시스템관리자 설정 정보`,
    "zz02_0700.title.systemStatus" : `시스템 상태`,
    "zz02_0700.title.tax" : `부가세(%)`,
    "zz02_0700.title.taxAndSvc" : `부가세(%), 서비스(%)`,
    "zz02_0700.title.totalRoomCnt" : `총 객실수`,
    "zz02_0700.title.vatYn" : `부가세 포함 여부`,
    "zz02_0700.title.weekendSetting" : `주말설정`,
    "zz03_0100.btn.buttonTitleMoreView" : `더보기`,
    "zz03_0100.button.accept" : `고객센터 접수`,
    "zz03_0100.button.confirm" : `확인`,
    "zz03_0100.button.confirmNo" : `아니오`,
    "zz03_0100.button.confirmYes" : `예`,
    "zz03_0100.button.resend" : `재전송`,
    "zz03_0100.button.tutorial" : `튜토리얼`,
    "zz03_0100.button.tutorialAdmin" : `튜토리얼관리자`,
    "zz03_0100.etc.arrvDate" : `투숙일자`,
    "zz03_0100.etc.channelReservationNumber" : `채널 예약 번호`,
    "zz03_0100.etc.cnPmName" : `채널명`,
    "zz03_0100.etc.hotelReservationNumber" : `호텔 예약 번호`,
    "zz03_0100.etc.inhtGestName" : `투숙객 정보`,
    "zz03_0100.etc.lastChngDt" : `업데이트 시간`,
    "zz03_0100.etc.pmCmRsSendStatusCnName" : `전송결과`,
    "zz03_0100.etc.tablecmRsNo" : `CMS#`,
    "zz03_0100.message.errorCall" : `채널 Data 호출 처리시 에러가 발생하였습니다.`,
    "zz03_0100.message.qChannelInfoCall" : `채널 정보를 호출 하시겠습니까?`,
    "zz03_0100.message.resendAlertConfirmMessage" : `재전송 결과 (@(0)/3) <br/><br/>데이터가 정상적으로 PMS로 전송되지 못햇습니다. <br/><br/>미전송 예약은 PMS에 수기로 입력 해 주시기 바랍니다. <br/>이 문제에 대해서 산하정보기술로 오류 내용을 보내시려면 [오류 내용 보내기] 버튼을 클릭 해 주세요. <br/>수집된 데이터는 오직 문제를 해결하는데만 사용됩니다. <br/>`,
    "zz03_0100.message.resendAlertMessage" : `재전송 결과 (@(0)/3) <br/><br/>데이터가 정상적으로 PMS로 전송되지 못햇습니다. <br/><br/>[재전송] 버튼을 클릭 하면, 다시한번 재전송 시도를 합니다. <br/>이 문제에 대해서 산하정보기술로 오류 내용을 보내시려면 [오류 내용 보내기] 버튼을 클릭 해 주세요. <br/>수집된 데이터는 오직 문제를 해결하는데만 사용됩니다. <br/>`,
    "zz03_0100.message.successCall" : `정상적으로 호출 처리 되었습니다.<br /> 객실관리 페이지로 이동합니다.`,
    "zz03_0100.message.toolTipMessage" : `CMS에서 PMS로 예약정보<br />전송실패 시 [재전송] 버튼이 <br />활성화 됩니다. 해당 버튼을 눌러 <br />예약정보를 재전송 해주세요.`,
    "zz03_0100.title.ReservationStatus" : `실시간 예약 리스트`,
    "zz03_0100.title.apiCall" : `호출`,
    "zz03_0100.title.channelName" : `채널`,
    "zz03_0100.title.channelSync" : `최근 채널 정보 동기화`,
    "zz03_0100.title.compareWeek" : `지난 7일 대비`,
    "zz03_0100.title.currency" : `원`,
    "zz03_0100.title.dashboard" : `대시보드`,
    "zz03_0100.title.date" : `일`,
    "zz03_0100.title.errorEmailSuccess" : `오류 내용이 전송 되었습니다.`,
    "zz03_0100.title.friday" : `금`,
    "zz03_0100.title.home" : `홈`,
    "zz03_0100.title.important" : `중요`,
    "zz03_0100.title.monday" : `월`,
    "zz03_0100.title.nightUnit" : `박`,
    "zz03_0100.title.noData" : `등록된 데이터가 없습니다.`,
    "zz03_0100.title.noHistory" : `데이터 없음`,
    "zz03_0100.title.normal" : `일반`,
    "zz03_0100.title.notice" : `공지사항`,
    "zz03_0100.title.openStatus" : `연동 상태`,
    "zz03_0100.title.popupSuccess" : `성공`,
    "zz03_0100.title.realTimeReservationList" : `실시간 예약 리스트`,
    "zz03_0100.title.realTimeReservationListMeg" : `*실시간예약리스트는각채널로부터[3분]간격으로업데이트됩니다.`,
    "zz03_0100.title.recent" : `최근기록`,
    "zz03_0100.title.recentSyncTime" : `최종 업데이트 일시`,
    "zz03_0100.title.resendMessage" : `<div style='text-align:right;margin-top:-20px;margin-bottom:20px;'>데이터가 정상적으로 PMS로 전송되지 못했습니다.</span></div><div>[재전송] 버튼을 클릭하면, 다시 한번 재전송 시도를 합니다.</div><div>[고객센터 접수] 버튼을 클릭 시 자동으로 산하정보기술 고객센터로 메일 접수가 됩니다.</div><br/><div>접수내용은 사용자가 이메일에서 확인 가능합니다.</div>`,
    "zz03_0100.title.resendMessage_1" : `재전송 결과 (1/3) <br/><br/>데이터가 정상적으로 PMS로 전송되지 못햇습니다. <br/><br/>[재전송] 버튼을 클릭 하면, 다시한번 재전송 시도를 합니다. <br/>이 문제에 대해서 산하정보기술로 오류 내용을 보내시려면 [오류 내용 보내기] 버튼을 클릭 해 주세요. <br/>수집된 데이터는 오직 문제를 해결하는데만 사용됩니다. <br/>`,
    "zz03_0100.title.reservationStatus" : `예약 현황`,
    "zz03_0100.title.revNo" : `예약수`,
    "zz03_0100.title.revNumber" : `건 예약수`,
    "zz03_0100.title.revNumberUnit" : `건`,
    "zz03_0100.title.saturday" : `토`,
    "zz03_0100.title.saveSuccess" : `긴급`,
    "zz03_0100.title.successMessage" : `예약번호 : @(0)이(가) PMS로 전송이 완료되었습니다.`,
    "zz03_0100.title.sunday" : `일`,
    "zz03_0100.title.sync" : `동기화`,
    "zz03_0100.title.thursday" : `목`,
    "zz03_0100.title.toDayADR" : `오늘 ADR`,
    "zz03_0100.title.toDayLoDge" : `오늘 발생한 숙박 일`,
    "zz03_0100.title.toDayReserVation" : `오늘 발생한 예약 건`,
    "zz03_0100.title.toDaySales" : `오늘 매출`,
    "zz03_0100.title.totRev" : `매출`,
    "zz03_0100.title.total" : `전체`,
    "zz03_0100.title.tuesday" : `화`,
    "zz03_0100.title.wednesday" : `수`,
    "zz03_0100.title.weeklySaleList" : `주간 판매 현황`,
    "zz03_0100.title.weeklySaleTop" : `주간 판매 현황 (TOP 3 채널)`,
    "zz03_0210.button.init" : `초기화`,
    "zz03_0210.button.search" : `검색`,
    "zz03_0210.label.disposer" : `처리자`,
    "zz03_0210.label.division" : `구분`,
    "zz03_0210.label.period" : `기간`,
    "zz03_0210.label.property" : `프로퍼티 명`,
    "zz03_0210.message.required" : `필수항목을 선택해 주세요.`,
    "zz03_0210.title.detailLog" : `상세내역`,
    "zz03_0210.title.disposer" : `처리자명`,
    "zz03_0210.title.division" : `구분`,
    "zz03_0210.title.gridDetailChannelName" : `채널명`,
    "zz03_0210.title.gridDetailDisposer" : `처리자`,
    "zz03_0210.title.gridDetailDisposerIp" : `처리자IP`,
    "zz03_0210.title.gridDetailHotelRoomName" : `호텔객실명`,
    "zz03_0210.title.gridDetailProcessDate" : `처리일시`,
    "zz03_0210.title.gridDetailProductGroupName" : `상품그룹명`,
    "zz03_0210.title.gridDetailPropertyName" : `프로퍼티명`,
    "zz03_0210.title.gridDetailSaleDate" : `판매일자`,
    "zz03_0210.title.gridDetailWorkLog" : `작업내역`,
    "zz03_0210.title.pageTitle" : `최근 기록`,
    "zz03_0210.title.processComment" : `처리내용`,
    "zz03_0210.title.processDate" : `처리일시`,
    "zz03_0210.title.recentLog" : `최근기록`,
    "zz03_0400.button.list" : `목록`,
    "zz03_0400.message.noCompany" : `COMPANY ID가 없습니다. 관리자에게 문의해 주세요.`,
    "zz03_0400.title.friday" : `금`,
    "zz03_0400.title.impSortAsc" : `중요도순(일반 > 긴급)`,
    "zz03_0400.title.impSortDesc" : `중요도순(긴급 > 일반)`,
    "zz03_0400.title.important" : `중요`,
    "zz03_0400.title.monday" : `월`,
    "zz03_0400.title.next" : `다음글`,
    "zz03_0400.title.noData" : `등록된 데이터가 없습니다.`,
    "zz03_0400.title.normal" : `일반`,
    "zz03_0400.title.notice" : `공지사항`,
    "zz03_0400.title.prev" : `이전글`,
    "zz03_0400.title.recentSort" : `최신순`,
    "zz03_0400.title.saturday" : `토`,
    "zz03_0400.title.saveSuccess" : `긴급`,
    "zz03_0400.title.sunday" : `일`,
    "zz03_0400.title.thursday" : `목`,
    "zz03_0400.title.total" : `전체`,
    "zz03_0400.title.tuesday" : `화`,
    "zz03_0400.title.wednesday" : `수`,
    "zz04_0000.btn.no" : `아니오`,
    "zz04_0000.btn.yes" : `예`,
    "zz04_0000.message.failAccessAuth" : `잘못된 인증키 입니다. <br/> 다시 시도해 주세요.`,
    "zz04_0000.message.title" : `기존에 로그인한 정보가 있습니다. <br/> <span class='text-primary'>로그아웃</span> 후 진행하시겠습니까?`,
    "zz04_0100.button.combineMember" : `통합회원 가입`,
    "zz04_0100.message.multiProperty" : `프로퍼티가 2개 이상일 경우 <span class='text-primary'>한번에 설정</span>할 수 있습니다.`,
    "zz04_0100.message.selectProperty" : `가입할 프로퍼티를 선택해 주세요.`,
    "zz04_0100.message.selectedProperty" : `프로퍼티 선택…`,
    "zz04_0100.message.subTitle" : `동시 사용으로 시작하세요`,
    "zz04_0100.message.title" : `WINGS의 완벽 호환`,
    "zz04_0200.btn.joinComplete" : `가입완료`,
    "zz04_0200.btn.login" : `로그인`,
    "zz04_0200.btn.passwordInit" : `비밀번호 초기화`,
    "zz04_0200.message.checkInput" : `을(를) 입력해 주세요.`,
    "zz04_0200.message.chkEmail" : `잘못된 이메일 형식입니다.`,
    "zz04_0200.message.chkPassword" : `비밀번호는 숫자, 영문, 특수문자 3가지 조합 시 최소 8자 최대 20자 입니다.`,
    "zz04_0200.message.chkPasswordConfirm" : `비밀번호와 비밀번호 확인이 다릅니다.`,
    "zz04_0200.message.chkUserId" : `사용자 ID는 숫자, 영문 2가지가 조합된 최소 4자리 이상 최대 12자 입니다.`,
    "zz04_0200.message.companyId" : `컨퍼니 ID`,
    "zz04_0200.message.email" : `이메일`,
    "zz04_0200.message.password" : `비밀번호`,
    "zz04_0200.message.passwordConfirm" : `비밀번호 확인`,
    "zz04_0200.message.phoneNumber" : `휴대폰 번호`,
    "zz04_0200.message.subWelcome" : `회원가입 요청이 완료되었습니다.`,
    "zz04_0200.message.svc" : `서비스(%)`,
    "zz04_0200.message.tax" : `부가세(%)`,
    "zz04_0200.message.taxContain" : `부가세 포함 여부`,
    "zz04_0200.message.tooltip" : `호텔에서 예약이 발생될 때 <br/>부가세율/ 서비스율이 <br/>나눠지는지에 대한 여부를 <br/>선택합니다.`,
    "zz04_0200.message.userFirstName" : `사용자 명 (성)`,
    "zz04_0200.message.userId" : `사용자 ID`,
    "zz04_0200.message.userInfo" : `사용자 정보`,
    "zz04_0200.message.userLastName" : `사용자 명 (이름)`,
    "zz04_0200.message.userName" : `사용자 명`,
    "zz04_0200.title.accountExist" : `이미 계정이 있나요?`,
    "zz04_0200.title.subWelcome" : `<span class='text-success'>간편 회원가입</span>을 통해 쉽고, 빠르게 온라인 예약 관리하세요.`,
    "zz04_0200.title.welcome" : `환영합니다.`,
    "zz04_0300.btn.joinComplete" : `가입완료`,
    "zz04_0300.btn.login" : `로그인`,
    "zz04_0300.btn.passwordInit" : `비밀번호 초기화`,
    "zz04_0300.message.checkInput" : `을(를) 입력해 주세요.`,
    "zz04_0300.message.chkEmail" : `잘못된 이메일 형식입니다.`,
    "zz04_0300.message.chkPassword" : `비밀번호는 숫자, 영문, 특수문자 3가지 조합 시 최소 8자 최대 20자 입니다.`,
    "zz04_0300.message.chkPasswordConfirm" : `비밀번호와 비밀번호 확인이 다릅니다.`,
    "zz04_0300.message.chkUserId" : `사용자 ID는 숫자, 영문 2가지가 조합된 최소 4자리 이상 최대 12자 입니다.`,
    "zz04_0300.message.companyId" : `컨퍼니 ID`,
    "zz04_0300.message.email" : `이메일`,
    "zz04_0300.message.password" : `비밀번호`,
    "zz04_0300.message.passwordConfirm" : `비밀번호 확인`,
    "zz04_0300.message.phoneNumber" : `휴대폰 번호`,
    "zz04_0300.message.subWelcome" : `회원가입 요청이 완료되었습니다.`,
    "zz04_0300.message.userFirstName" : `사용자 명 (성)`,
    "zz04_0300.message.userId" : `사용자 ID`,
    "zz04_0300.message.userInfo" : `사용자 정보`,
    "zz04_0300.message.userLastName" : `사용자 명 (이름)`,
    "zz04_0300.message.userName" : `사용자 명`,
    "zz04_0300.title.accountExist" : `이미 계정이 있나요?`,
    "zz04_0300.title.subWelcome" : `<span class='text-success'>간편 회원가입</span>을 통해 쉽고, 빠르게 온라인 예약 관리하세요.`,
    "zz04_0300.title.welcome" : `환영합니다.`,
    "zz04_0400.btn.joinComplete" : `가입완료`,
    "zz04_0400.btn.login" : `로그인`,
    "zz04_0400.btn.passwordInit" : `비밀번호 초기화`,
    "zz04_0400.message.checkInput" : `을(를) 입력해 주세요.`,
    "zz04_0400.message.chkEmail" : `잘못된 이메일 형식입니다.`,
    "zz04_0400.message.chkPassword" : `비밀번호는 숫자, 영문, 특수문자 3가지 조합 시 최소 8자 최대 15자 입니다.`,
    "zz04_0400.message.chkPasswordConfirm" : `비밀번호와 비밀번호 확인이 다릅니다.`,
    "zz04_0400.message.chkUserId" : `사용자 ID는 숫자, 영문 2가지가 조합된 최소 4자리 이상 최대 12자 입니다.`,
    "zz04_0400.message.coperateNo" : `사업자 번호 (숫자만 입력)`,
    "zz04_0400.message.email" : `이메일`,
    "zz04_0400.message.hotelName" : `호텔 명`,
    "zz04_0400.message.nation" : `국가`,
    "zz04_0400.message.password" : `비밀번호`,
    "zz04_0400.message.passwordConfirm" : `비밀번호 확인`,
    "zz04_0400.message.phoneNumber" : `휴대폰 번호`,
    "zz04_0400.message.subWelcome" : `회원가입 요청이 완료되었습니다.`,
    "zz04_0400.message.userFirstName" : `사용자 명 (성)`,
    "zz04_0400.message.userId" : `사용자 ID`,
    "zz04_0400.message.userInfo" : `사용자 정보`,
    "zz04_0400.message.userLastName" : `사용자 명 (이름)`,
    "zz04_0400.message.userName" : `사용자 명`,
    "zz04_0400.title.accountExist" : `이미 계정이 있나요?`,
    "zz04_0400.title.subWelcome" : `<span class='text-success'>간편 회원가입</span>을 통해 쉽고, 빠르게 온라인 예약 관리하세요.`,
    "zz04_0400.title.welcome" : `환영합니다.`,
    "zz04_0500.btn.login" : `로그인`,
    "zz04_0500.message.info01" : `모든 회원가입 절차가 완료되었습니다.`,
    "zz04_0500.message.info02" : `시스템 관리자가 <span class='text-success'>가입승인 처리</span> 후 접속하실 수 있습니다.`,
    "zz04_0500.message.info03" : `가입승인 완료 후 이메일로 안내해 드리겠습니다.`,
    "zz04_0500.message.joinComplate" : `회원가입 완료되었습니다.`,
    "zz04_0500.message.userInfo" : `사용자 정보`,
    "zz04_0500.title.companyId" : `컴퍼니 ID`,
    "zz04_0500.title.email" : `이메일`,
    "zz04_0500.title.hotelName" : `호텔 명`,
    "zz04_0500.title.mobileNo" : `휴대폰 번호`,
    "zz04_0500.title.userId" : `사용자 ID`,
    "zz04_0500.title.userName" : `사용자 명`,
    "zz04_0600.btn.hotelSubInfo" : `호텔 부가 정보`,
    "zz04_0600.btn.login" : `로그인`,
    "zz04_0600.message.info01" : `모든 회원가입 절차가 완료되었습니다.`,
    "zz04_0600.message.info02" : `이제 <span class='text-success'>모든 서비스</span>을 이용할 수 있습니다.`,
    "zz04_0600.message.info03" : `가입승인 완료 후 이메일로 안내해 드리겠습니다.`,
    "zz04_0600.message.joinComplate" : `회원가입 완료되었습니다.`,
    "zz04_0600.message.recommend" : `[추천]`,
    "zz04_0600.message.subNotice" : `우리 호텔에 대한 부가정보를 입력 <span class='d-sm-block'/>하고 더 정확한 데이터를 관리해보세요!!`,
    "zz04_0600.message.userInfo" : `사용자 정보`,
    "zz04_0600.title.companyId" : `컴퍼니 ID`,
    "zz04_0600.title.email" : `이메일`,
    "zz04_0600.title.hotelName" : `호텔 명`,
    "zz04_0600.title.mobileNo" : `휴대폰 번호`,
    "zz04_0600.title.userId" : `사용자 ID`,
    "zz04_0600.title.userName" : `사용자 명`,
    "zz04_0700.btn.accept" : `고객센터 접수`,
    "zz04_0700.message.acceptComplate" : `접수가 완료되었습니다. 처리가 완료되면, 호텔 대표번호로 연락 드리겠습니다.`,
    "zz04_0700.message.notFoundMessage" : `<span class='text-primary'>[고객센터 접수]</span> 버튼을 클릭하시면 시스템 관리자가 <br/>검토 후 호텔 대표번호로 연락 드리겠습니다.`,
    "zz04_0700.message.notFoundProperty" : `선택하신 프로퍼티 정보를 찾을 수 없습니다.`,
    "zz10_0100.button.add" : `+ 추가`,
    "zz10_0100.button.addPopup" : `추가`,
    "zz10_0100.button.attach" : `+ 첨부`,
    "zz10_0100.button.cancel" : `취소`,
    "zz10_0100.button.init" : `초기화`,
    "zz10_0100.button.manage" : `관리`,
    "zz10_0100.button.save" : `저장`,
    "zz10_0100.button.search" : `검색`,
    "zz10_0100.etc.active" : `사용`,
    "zz10_0100.etc.all" : `전체`,
    "zz10_0100.etc.inactive" : `사용안함`,
    "zz10_0100.message.attachRemove" : `첨부파일을 삭제 후 다시 시도해 주세요.`,
    "zz10_0100.message.channelReg" : `채널을 먼저 생성해 주세요.`,
    "zz10_0100.message.commnetDescription" : `연동된 채널의 특이사항을 입력해주세요. 해당사항은 사용자에게 노출되지않습니다.`,
    "zz10_0100.message.duplicationServcie" : `@(0) 코드가 중복된 서비스코드 입니다. <br />서비스코드를 변경하시기 바랍니다.`,
    "zz10_0100.message.duplicationSource" : `@(0) 코드가 중복된 소스코드 입니다. <br />소스코드를 변경하시기 바랍니다.`,
    "zz10_0100.message.inputChannelCode" : `채널코드는 필수값 입니다.`,
    "zz10_0100.message.inputChannelName" : `저장 되었습니다.`,
    "zz10_0100.message.inputChannelNameRequire" : `채널명은 필수값 입니다.`,
    "zz10_0100.message.inputEmail" : `이메일은 필수값 입니다.`,
    "zz10_0100.message.inputInterLocking" : `연동주체는 필수값 입니다.`,
    "zz10_0100.message.inputManagerName" : `담당자명은 필수값 입니다.`,
    "zz10_0100.message.inputRateMappingMethod" : `요금 매핑 방법은 필수값 입니다.`,
    "zz10_0100.message.inputRateTypeRequire" : `요금타입별 할당전송은 필수값 입니다.`,
    "zz10_0100.message.inputRevApiBatch" : `예약 API 배치 구분은 필수값 입니다.`,
    "zz10_0100.message.inputServiceCode" : `서비스코드는 필수값 입니다.`,
    "zz10_0100.message.inputServiceName" : `서비스명은 필수값 입니다.`,
    "zz10_0100.message.inputSourceCode" : `소스코드는 필수값 입니다.`,
    "zz10_0100.message.inputSourceName" : `소스명은 필수값 입니다.`,
    "zz10_0100.message.inputSourceType" : `소스타입은 필수값 입니다.`,
    "zz10_0100.message.inputSrcManageRequire" : `소스관리는 필수값 입니다.`,
    "zz10_0100.message.inputSvcCodeRequire" : `서비스 코드 사용유무는 필수값 입니다.`,
    "zz10_0100.message.inputUseLimitFunc" : `사용제한은 필수값 입니다.`,
    "zz10_0100.message.limitUpload" : `업로드 가능한 파일 크기는 20MB 입니다.`,
    "zz10_0100.message.manualFile" : `매뉴얼 파일을 먼저 등록해 주세요.`,
    "zz10_0100.message.noModify" : `변경된 데이터가 없습니다.`,
    "zz10_0100.message.pmsCallSuccess" : `PMS 소스정보를 성공적으로 호출하였습니다.`,
    "zz10_0100.message.qAttachRemove" : `첨부파일을 삭제하시겠습니까?`,
    "zz10_0100.message.rejectExt" : `업로드 불가능한 확장자 입니다.`,
    "zz10_0100.message.saveSuccess" : `저장 되었습니다.`,
    "zz10_0100.message.searchChannelName" : `채널명 조회조건은 필수값 입니다.`,
    "zz10_0100.message.sourceApiCall" : `PMS 소스정보을 가져오시겠습니까?`,
    "zz10_0100.message.urlDesription" : `등록된 관리자 URL을 통해 사용자가 접속할 수 있습니다.`,
    "zz10_0100.title.addSetting" : `추가 설정`,
    "zz10_0100.title.attachment" : `첨부파일`,
    "zz10_0100.title.buyPrice" : `입금가`,
    "zz10_0100.title.call" : `호출`,
    "zz10_0100.title.callUrl" : `Process call URL`,
    "zz10_0100.title.callUrlTest" : `Process call URL(TEST)`,
    "zz10_0100.title.channelCode" : `채널코드`,
    "zz10_0100.title.channelName" : `채널명`,
    "zz10_0100.title.channelRate" : `채널 할당 / 요금`,
    "zz10_0100.title.close" : `닫기`,
    "zz10_0100.title.comment" : `특이사항`,
    "zz10_0100.title.contentType" : `Content 타입`,
    "zz10_0100.title.download" : `다운`,
    "zz10_0100.title.interLocking" : `연동주체`,
    "zz10_0100.title.interlocingAlarm" : `연동알람`,
    "zz10_0100.title.interlockingComplete" : `연동완료`,
    "zz10_0100.title.interlockingStep" : `연동단계`,
    "zz10_0100.title.interlockingTerminate" : `연동해지`,
    "zz10_0100.title.item" : `변경항목`,
    "zz10_0100.title.managerEmail" : `이메일`,
    "zz10_0100.title.managerInfo" : `담당자 정보`,
    "zz10_0100.title.managerName" : `담당자명`,
    "zz10_0100.title.managerPhone" : `전화번호`,
    "zz10_0100.title.manual" : `매뉴얼`,
    "zz10_0100.title.methodType" : `Method 타입`,
    "zz10_0100.title.modify" : `수정`,
    "zz10_0100.title.no" : `아니오`,
    "zz10_0100.title.openStep" : `오픈단계`,
    "zz10_0100.title.operManage" : `운영 관리`,
    "zz10_0100.title.operSetting" : `운영 설정`,
    "zz10_0100.title.payChannel" : `채널결제`,
    "zz10_0100.title.payPlace" : `현장결제`,
    "zz10_0100.title.paySetting" : `결제 구분 설정`,
    "zz10_0100.title.priceGubun" : `요금구분`,
    "zz10_0100.title.processCall" : `Process Call`,
    "zz10_0100.title.rateMapMethod" : `요금 매핑 방법`,
    "zz10_0100.title.rateTransition" : `채널 할당 / 요금 전송 URL(TEST)`,
    "zz10_0100.title.rateTransitionUrl" : `채널 할당 / 요금 전송 URL`,
    "zz10_0100.title.rateType" : `요금타입별 <br />할당전송`,
    "zz10_0100.title.rateTypeSend" : `요금타입별 할당전송`,
    "zz10_0100.title.revApiBatch" : `예약 API 배치 구분`,
    "zz10_0100.title.revGubun" : `예약구분`,
    "zz10_0100.title.sellPrice" : `판매가`,
    "zz10_0100.title.serviceCode" : `서비스 코드`,
    "zz10_0100.title.serviceCodeUse" : `서비스 코드 사용유무`,
    "zz10_0100.title.serviceManage" : `서비스 관리`,
    "zz10_0100.title.serviceName" : `서비스명`,
    "zz10_0100.title.serviceNotUse" : `서비스 사용안함`,
    "zz10_0100.title.serviceType" : `서비스타입`,
    "zz10_0100.title.serviceUse" : `서비스 사용`,
    "zz10_0100.title.settingDate" : `설정일자`,
    "zz10_0100.title.settingInfo" : `운영 설정 정보`,
    "zz10_0100.title.soapType" : `SOAP 타입`,
    "zz10_0100.title.sortOrder" : `정렬순서`,
    "zz10_0100.title.sourceCode" : `소스코드`,
    "zz10_0100.title.sourceManage" : `소스관리`,
    "zz10_0100.title.sourceName" : `소스명`,
    "zz10_0100.title.sourceType" : `소스타입`,
    "zz10_0100.title.status" : `상태`,
    "zz10_0100.title.storeManage" : `B2B 거래처 관리`,
    "zz10_0100.title.testSettingInfo" : `테스트 설정 정보`,
    "zz10_0100.title.url" : `관리자URL`,
    "zz10_0100.title.useLimitFunc" : `사용제한 기능`,
    "zz10_0100.title.useOrNot" : `사용유무`,
    "zz10_0100.title.useYn" : `사용여부`,
    "zz10_0100.title.yes" : `예`,
    "zz10_0200.button.cancel" : `취소`,
    "zz10_0200.button.detail" : `상세`,
    "zz10_0200.button.init" : `초기화`,
    "zz10_0200.button.remove" : `삭제`,
    "zz10_0200.button.save" : `저장`,
    "zz10_0200.button.search" : `검색`,
    "zz10_0200.etc.active" : `사용`,
    "zz10_0200.etc.all" : `전체`,
    "zz10_0200.etc.inactive" : `사용안함`,
    "zz10_0200.etc.notUse" : `미사용`,
    "zz10_0200.message.exceedData" : `2000자 이내로 입력해 주세요.`,
    "zz10_0200.message.inputPageUrl" : `페이지 URL을 먼저 입력 후 저장하셔야 합니다.`,
    "zz10_0200.message.noData" : `@(0) 데이터를 입력해 주세요.`,
    "zz10_0200.message.noModifyData" : `변경된 데이터가 없습니다.`,
    "zz10_0200.message.saveSuccess" : `저장 되었습니다.`,
    "zz10_0200.title.cancel" : `취소`,
    "zz10_0200.title.chName" : `채널명`,
    "zz10_0200.title.chinese" : `중국어`,
    "zz10_0200.title.detail" : `채널명 주의사항`,
    "zz10_0200.title.english" : `영어`,
    "zz10_0200.title.japanese" : `일본어`,
    "zz10_0200.title.korean" : `한국어`,
    "zz10_0200.title.modify" : `수정`,
    "zz10_0200.title.notice" : `주의사항`,
    "zz10_0200.title.pageUrl" : `페이지URL`,
    "zz10_0200.title.procStep" : `연동단계`,
    "zz10_0200.title.useYn" : `사용여부`,
    "zz10_0200.title.vietnam" : `베트남어`,
    "zz10_0300.title.pageName" : `고객사 현황`,
    "zz10_0300.title.tag1PageName" : `계약 현황`,
    "zz10_0300.title.tag2PageName" : `가입현황`,
    "zz10_0300.title.tag3PageName" : `채널 연동 현황`,
    "zz10_0301.buttom.searchInit" : `초기화`,
    "zz10_0301.buttom.searchSearch" : `검색`,
    "zz10_0301.button.contCancel" : `취소`,
    "zz10_0301.button.contExcel" : `엑셀`,
    "zz10_0301.button.contSave" : `저장`,
    "zz10_0301.etc.gridAutoSelectItemAll" : `전체`,
    "zz10_0301.etc.gridSelectItemN" : `사용안함`,
    "zz10_0301.etc.gridSelectItemY" : `사용`,
    "zz10_0301.success.contSave" : `계약 정보가 수정되었습니다.`,
    "zz10_0301.title.contActive" : `실사용 \n여부`,
    "zz10_0301.title.contAdditionContract" : `추가계약`,
    "zz10_0301.title.contArea" : `지역`,
    "zz10_0301.title.contChannelCnt" : `계약 채널수`,
    "zz10_0301.title.contChannelEndDate" : `사용종료일자`,
    "zz10_0301.title.contChannelRegDate" : `착수요청일자`,
    "zz10_0301.title.contChannelStateDate" : `채널연동일자`,
    "zz10_0301.title.contCompanyId" : `companyId`,
    "zz10_0301.title.contCondition" : `계약조건`,
    "zz10_0301.title.contContractManager" : `계약담당`,
    "zz10_0301.title.contFreeChannel" : `무료채널`,
    "zz10_0301.title.contLinkageChannelCnt" : `현 연동 \n채널수`,
    "zz10_0301.title.contModify" : `수정`,
    "zz10_0301.title.contPms" : `PMS 사용`,
    "zz10_0301.title.contPropertyName" : `프로퍼티명`,
    "zz10_0301.title.contTerm" : `계약기간`,
    "zz10_0301.title.contUsageStatus" : `계약여부`,
    "zz10_0301.title.pageName" : `고객사 현황`,
    "zz10_0301.title.searchActive" : `실사용여부`,
    "zz10_0301.title.searchProperty" : `프로퍼티명`,
    "zz10_0301.title.sesrchContract" : `계약상태`,
    "zz10_0301.title.sesrchContractAll" : `전체`,
    "zz10_0301.title.sesrchContractN" : `사용안함`,
    "zz10_0301.title.sesrchContractY" : `사용`,
    "zz10_0301.validation.fieldValid" : `@(0) 을(를) 입력해 주세요.`,
    "zz10_0302.buttom.searchInit" : `초기화`,
    "zz10_0302.buttom.searchSearch" : `검색`,
    "zz10_0302.button.PMSInfo" : `PMS 정보`,
    "zz10_0302.button.PMSRegist" : `PMS 등록`,
    "zz10_0302.button.contActiveN" : `사용안함`,
    "zz10_0302.button.contActiveY" : `사용`,
    "zz10_0302.button.contApprov" : `승인`,
    "zz10_0302.button.contConnection" : `연결됨`,
    "zz10_0302.button.contFailed" : `실패`,
    "zz10_0302.button.contFailed_1" : `연결실패`,
    "zz10_0302.button.contReject" : `거절`,
    "zz10_0302.button.popupCancel" : `취소`,
    "zz10_0302.button.popupInfoButtonN" : `아니요`,
    "zz10_0302.button.popupInfoButtonY" : `예`,
    "zz10_0302.button.popupPmsConnect" : `PMS 연결 확인`,
    "zz10_0302.button.popupSave" : `저장`,
    "zz10_0302.etc.popupInfoTitle" : `안내`,
    "zz10_0302.message.popupMessageApprov" : `현재 체크된 가입을 승인하시겠습니까?`,
    "zz10_0302.message.popupMessageApprov_1" : `현재 체크된 가입을 승인하시겠습니까?`,
    "zz10_0302.message.popupMessageConnectionSuccess" : `연결되었습니다.`,
    "zz10_0302.message.popupMessagePmsSave" : `PMS 정보를 저장하시겠습니까?`,
    "zz10_0302.message.popupMessageReject" : `현재 체크된 가입을 거절하시겠습니까?`,
    "zz10_0302.message.popupMessageReject_1" : `현재 체크된 가입을 거절하시겠습니까?`,
    "zz10_0302.message.popupMessageconnectionFailed" : `연결에 실패했습니다.`,
    "zz10_0302.success.contRejectSave" : `거절 처리되었습니다.`,
    "zz10_0302.success.contSave" : `승인 처리되었습니다.`,
    "zz10_0302.title.PMSInfo" : `PMS 정보`,
    "zz10_0302.title.RegEmailAddr" : `가입자 이메일 주소`,
    "zz10_0302.title.RegId" : `가입자 아이디`,
    "zz10_0302.title.RegPhoneNumber" : `가입자 휴대폰 번호`,
    "zz10_0302.title.approv" : `가입 일시`,
    "zz10_0302.title.approv_1" : `완료 일자`,
    "zz10_0302.title.connectionYn" : `연결여부`,
    "zz10_0302.title.contCompanyId" : `Company ID`,
    "zz10_0302.title.contPms" : `PMS 사용`,
    "zz10_0302.title.contPmsTpye" : `PMS 사용`,
    "zz10_0302.title.contPmsTpye_1" : `PMS 종류`,
    "zz10_0302.title.contPropertyName" : `프로퍼티명`,
    "zz10_0302.title.contRegDate" : `가입 일시`,
    "zz10_0302.title.contRegDate_1" : `가입 일자`,
    "zz10_0302.title.filterProperty" : `프로퍼티명`,
    "zz10_0302.title.filterRegEmail" : `가입자 이메일주소`,
    "zz10_0302.title.filterRegId" : `가입자ID`,
    "zz10_0302.title.filterRegName" : `가입자명`,
    "zz10_0302.title.filterRegPhone" : `가입자 휴대포번호`,
    "zz10_0302.title.filterStatus" : `상태`,
    "zz10_0302.title.firstRegName" : `최초 가입자명`,
    "zz10_0302.title.notApplicable" : `해당없음`,
    "zz10_0302.title.popupBizCode" : `BIZ_CODE`,
    "zz10_0302.title.popupCompanyId" : `COMPANY_ID`,
    "zz10_0302.title.popupDbInfo" : `DB 정보`,
    "zz10_0302.title.popupIp" : `IP`,
    "zz10_0302.title.popupPmsInfo" : `PMS 정보`,
    "zz10_0302.title.popupPropertyNo" : `PROPERTY_NO`,
    "zz10_0302.title.popupPwd" : `PWD`,
    "zz10_0302.title.popupSid" : `SID`,
    "zz10_0302.title.popupTitle" : `프로퍼티명 정보 등록`,
    "zz10_0302.title.popupTitle_1" : `@(0) 정보 등록`,
    "zz10_0302.title.popupUserId" : `USER_ID`,
    "zz10_0302.title.popupportNo" : `PORT NO`,
    "zz10_0302.title.searchProperty" : `프로퍼티명`,
    "zz10_0302.title.sesrchStatus" : `상태`,
    "zz10_0302.title.status" : `상태`,
    "zz10_0302.vaildation.popupVaildEmptyInput" : `DB 정보 입력란 비여있습니다.`,
    "zz10_0302.validation.popupInvalid" : `데이터가 비여있습니다.`,
    "zz10_0303.buttom.searchInit" : `초기화`,
    "zz10_0303.buttom.searchSearch" : `검색`,
    "zz10_0303.title.contChannelCnt" : `계약 채널수`,
    "zz10_0303.title.contChannelEndDate" : `사용종료일`,
    "zz10_0303.title.contChannelStateDate" : `첫 채널 연동일`,
    "zz10_0303.title.contCondition" : `계약조건`,
    "zz10_0303.title.contLinkageChannelCnt" : `현 연동 \n채널수`,
    "zz10_0303.title.contPms" : `PMS`,
    "zz10_0303.title.contPropertyName" : `프로퍼티명`,
    "zz10_0303.title.searchProperty" : `프로퍼티명`,
    "zz10_0303.title.sesrchContract" : `상태`,
    "zz10_0400.button.agreement" : `승인`,
    "zz10_0400.button.cancel" : `취소`,
    "zz10_0400.button.detail" : `상세`,
    "zz10_0400.button.init" : `초기화`,
    "zz10_0400.button.management" : `관리`,
    "zz10_0400.button.save" : `저장`,
    "zz10_0400.button.search" : `검색`,
    "zz10_0400.button.totalAgreement" : `일괄 승인`,
    "zz10_0400.message.agreeRequest" : `승인처리가 완료되었습니다`,
    "zz10_0400.message.inputChannelName" : `채널명은 필수값 입니다.`,
    "zz10_0400.message.inputId" : `아이디 값이 없습니다.`,
    "zz10_0400.message.inputPwd" : `비밀번호 값이 없습니다.`,
    "zz10_0400.message.noRequest" : `승인 요청할 데이터를 선택해 주십시오.`,
    "zz10_0400.message.openStopConfirm" : `@(0) <br />@(1) (@(2)) <br />@(3) 하시겠습니까?`,
    "zz10_0400.message.saveSuccess" : `저장 되었습니다.`,
    "zz10_0400.message.successProcess" : `정상 처리 되었습니다.`,
    "zz10_0400.title.addContract" : `추가 계약`,
    "zz10_0400.title.addRegistration" : `추가 신청서`,
    "zz10_0400.title.all" : `전체`,
    "zz10_0400.title.channelAgreeProcess" : `채널 연동 승인 절차`,
    "zz10_0400.title.channelInfo" : `채널 정보`,
    "zz10_0400.title.channelName" : `채널 명`,
    "zz10_0400.title.companyId" : `컴퍼니ID`,
    "zz10_0400.title.contractChannel" : `계약 채널 수`,
    "zz10_0400.title.contractDownload" : `계약서 내려받기`,
    "zz10_0400.title.download" : `계약서 다운로드`,
    "zz10_0400.title.extranetId" : `엑스트라넷 ID`,
    "zz10_0400.title.extranetPwd" : `엑스트라넷 비밀번호`,
    "zz10_0400.title.information" : `정보`,
    "zz10_0400.title.interlockingCancel" : `연동 취소`,
    "zz10_0400.title.interlockingComplete" : `연동 완료`,
    "zz10_0400.title.interlockingCount" : `현 연동 채널수`,
    "zz10_0400.title.interlockingProgress" : `연동 진행 중`,
    "zz10_0400.title.interlockingRelease" : `연동 해지`,
    "zz10_0400.title.no" : `아니오`,
    "zz10_0400.title.openStop" : `연동 중단`,
    "zz10_0400.title.propertyName" : `프로퍼티 명`,
    "zz10_0400.title.regDate" : `신청 일자`,
    "zz10_0400.title.regEmail" : `신청자 이메일 주소`,
    "zz10_0400.title.regName" : `신청자 명`,
    "zz10_0400.title.regPhone" : `신청자 휴대폰 번호`,
    "zz10_0400.title.remainChannel" : `남은 채널 수`,
    "zz10_0400.title.status" : `상태`,
    "zz10_0400.title.yes" : `예`,
    "zz10_0401.button.download" : `다운로드`,
    "zz10_0401.title.addChannel" : `※ 추가 채널`,
    "zz10_0401.title.addChannelLock" : `CMS 추가채널 연동`,
    "zz10_0401.title.addChannelLock1" : `- PMS 예약 연동 환경 구축`,
    "zz10_0401.title.addChannelLock2" : `- OTA 요금 및 객실 할당량 동기화`,
    "zz10_0401.title.addChannelLock3" : `- 오픈 및 안정화`,
    "zz10_0401.title.addChannelLock4" : `- 운영지원`,
    "zz10_0401.title.addChannelRate" : `※ 추가 채널 연동 비용`,
    "zz10_0401.title.addContractApplication" : `추가 채널 연동 신청서`,
    "zz10_0401.title.agreement" : `위 내용 확인 후, 추가 연동 신청에 동의합니다. (동의함)`,
    "zz10_0401.title.amount" : `수량`,
    "zz10_0401.title.applicationDate" : `신청일자`,
    "zz10_0401.title.applicationUnit" : `(단위 : 원, 부가세포함)`,
    "zz10_0401.title.channelApplicationInfo" : `※ 채널 연동 신청자 정보`,
    "zz10_0401.title.channelName" : `※ 채널 명`,
    "zz10_0401.title.contractDownloadViewer" : `추가 채널 연동 신청서 미리보기`,
    "zz10_0401.title.fileName" : `추가채널연동신청서`,
    "zz10_0401.title.gubun" : `구분`,
    "zz10_0401.title.history" : `내역`,
    "zz10_0401.title.price" : `금액`,
    "zz10_0401.title.ratePerMonth" : `월 비용 청구`,
    "zz10_0401.title.remark" : `비고`,
    "zz10_0401.title.roomName" : `숙소명`,
    "zz10_0401.title.sum" : `합계`,
    "zz10_0401.title.unitPrice" : `단가`,
    "zz10_0600.title.cmsChannel" : `CMS-채널 연동`,
    "zz10_0600.title.cmsPms" : `CMS-PMS 연동`,
    "zz10_0600.title.history" : `API 서비스 제어`,
    "zz10_0610.buttom.init" : `초기화`,
    "zz10_0610.buttom.search" : `검색`,
    "zz10_0610.button.buttonN" : `아니요`,
    "zz10_0610.button.buttonY" : `예`,
    "zz10_0610.button.popupRequestBtn" : `요청`,
    "zz10_0610.etc.required" : `필수항목`,
    "zz10_0610.message.repairMessage" : `복구하시겠습니까 ?`,
    "zz10_0610.success.buttonMessageSuccess" : `성공적으로 복구 진행했습니다.`,
    "zz10_0610.success.popupMessageSuccessRequest" : `연동중단요청을 성공적으로  진행했습니다.`,
    "zz10_0610.title.gridHeaderChannelName" : `프로퍼티`,
    "zz10_0610.title.gridHeaderLinkageChange" : `연동중단요청`,
    "zz10_0610.title.gridHeaderLinkageStatus" : `연동상태`,
    "zz10_0610.title.gridHeaderLinkageStopPeriod" : `연동중지기간`,
    "zz10_0610.title.gridHeaderStatusChange" : `상태변경`,
    "zz10_0610.title.gridHeaderStop" : `중지`,
    "zz10_0610.title.popupChannelName" : `채널`,
    "zz10_0610.title.popupPeriod" : `기간`,
    "zz10_0610.title.popupPropertyName" : `프로퍼티`,
    "zz10_0610.title.popupStopReason" : `사유`,
    "zz10_0610.title.searchActiveAll" : `전체`,
    "zz10_0610.title.searchActiveN" : `연동중단`,
    "zz10_0610.title.searchActiveY" : `정상연동`,
    "zz10_0610.title.searchActiveYn" : `연동상태`,
    "zz10_0610.title.searchChannel" : `채널`,
    "zz10_0610.title.searchProperty" : `프로퍼티`,
    "zz10_0610.validation.popupMessageWarningReaon" : `사유가 입력 되지 않았습니다.`,
    "zz10_0620.buttom.init" : `초기화`,
    "zz10_0620.buttom.search" : `검색`,
    "zz10_0620.button.buttonN" : `아니요`,
    "zz10_0620.button.buttonY" : `예`,
    "zz10_0620.button.popupRequestBtn" : `요청`,
    "zz10_0620.etc.required" : `필수항목`,
    "zz10_0620.message.repairMessage" : `복구하시겠습니까 ?`,
    "zz10_0620.success.buttonMessageSuccess" : `성공적으로 복구 진행했습니다.`,
    "zz10_0620.success.popupMessageSuccessRequest" : `연동중단요청을 성공적으로  진행했습니다.`,
    "zz10_0620.title.gridHeaderChannelName" : `채널`,
    "zz10_0620.title.gridHeaderLinkageChange" : `연동중단요청`,
    "zz10_0620.title.gridHeaderLinkageStatus" : `연동상태`,
    "zz10_0620.title.gridHeaderLinkageStopPeriod" : `연동중지기간`,
    "zz10_0620.title.gridHeaderStop" : `중지`,
    "zz10_0620.title.popupChannelName" : `채널`,
    "zz10_0620.title.popupPeriod" : `기간`,
    "zz10_0620.title.popupPropertyName" : `프로퍼티 명`,
    "zz10_0620.title.popupStopReason" : `사유`,
    "zz10_0620.title.searchActiveAll" : `전체`,
    "zz10_0620.title.searchActiveN" : `연동중단`,
    "zz10_0620.title.searchActiveY" : `정상연동`,
    "zz10_0620.title.searchActiveYn" : `연동상태`,
    "zz10_0620.title.searchProperty" : `채널`,
    "zz10_0620.validation.popupMessageWarningReaon" : `사유가 입력 되지 않았습니다.`,
    "zz10_1600.msg.user_created" : `사용자가 생성되었습니다.`,
    "zz10_1600.msg.user_deleted" : `사용자가 삭제되었습니다.`,
    "zz11_0100.button.detailCodeCancel" : `취소`,
    "zz11_0100.button.detailCodeSuccess" : `저장`,
    "zz11_0100.button.detailUseN" : `아니오`,
    "zz11_0100.button.detailUseY" : `예`,
    "zz11_0100.button.initCode" : `초기화`,
    "zz11_0100.button.masterCodeCancel" : `취소`,
    "zz11_0100.button.masterCodeSuccess" : `저장`,
    "zz11_0100.button.masterUse" : `사용여부`,
    "zz11_0100.button.masterUseN" : `아니오`,
    "zz11_0100.button.masterUseY" : `예`,
    "zz11_0100.button.popupCodeDivMove" : `예`,
    "zz11_0100.button.popupCodeDivStop" : `아니요`,
    "zz11_0100.button.searchCode" : `검색`,
    "zz11_0100.button.use" : `사용여부`,
    "zz11_0100.button.useAll" : `전체`,
    "zz11_0100.button.useN" : `사용안함`,
    "zz11_0100.button.useY" : `사용`,
    "zz11_0100.etc.itemAll" : `전체`,
    "zz11_0100.message.popupCodeDivBody" : `데이터가 변경되었습니다. <br />변경된 데이터를 무시하겠습니까 ?`,
    "zz11_0100.success.saveMessage" : `마스터 코드가 정상적으로 저장되었습니다.`,
    "zz11_0100.title.codeDiv" : `코드구분`,
    "zz11_0100.title.codeDivCodeDiv" : `코드구분`,
    "zz11_0100.title.codeDivCodeDivName" : `코드구분 명`,
    "zz11_0100.title.detailCode" : `상세 코드`,
    "zz11_0100.title.detailCodeName" : `상세코드명`,
    "zz11_0100.title.gridCode" : `코드`,
    "zz11_0100.title.gridCodeName" : `코드명`,
    "zz11_0100.title.gridCol01" : `컬럼1`,
    "zz11_0100.title.gridCol02" : `컬럼2`,
    "zz11_0100.title.gridCol03" : `컬럼3`,
    "zz11_0100.title.gridCol04" : `컬럼4`,
    "zz11_0100.title.gridCol05" : `컬럼5`,
    "zz11_0100.title.gridCol06" : `컬럼6`,
    "zz11_0100.title.gridCol07" : `컬럼7`,
    "zz11_0100.title.gridCol08" : `컬럼8`,
    "zz11_0100.title.gridCol09" : `컬럼9`,
    "zz11_0100.title.gridCol10" : `컬럼10`,
    "zz11_0100.title.gridModify" : `수정`,
    "zz11_0100.title.gridOrder" : `순서`,
    "zz11_0100.title.gridUse" : `사용여부`,
    "zz11_0100.title.masterCode" : `마스터 코드`,
    "zz11_0100.title.masterCodeCmt" : `코드상세정보`,
    "zz11_0100.title.masterCodeDiv" : `코드구분`,
    "zz11_0100.title.masterCodeName" : `코드구분 명`,
    "zz11_0100.title.masterUpperCodeDiv" : `상위구분코드`,
    "zz11_0100.title.materCode" : `마스터코드`,
    "zz11_0100.title.popupCode" : `코드`,
    "zz11_0100.title.popupCodeDivTitle" : `경고창`,
    "zz11_0100.title.popupCodeName" : `코드명`,
    "zz11_0100.title.popupCol01" : `컬럼1`,
    "zz11_0100.title.popupCol02" : `컬럼2`,
    "zz11_0100.title.popupCol03" : `컬럼3`,
    "zz11_0100.title.popupCol04" : `컬럼4`,
    "zz11_0100.title.popupCol05" : `컬럼5`,
    "zz11_0100.title.popupCol06" : `컬럼6`,
    "zz11_0100.title.popupCol07" : `컬럼7`,
    "zz11_0100.title.popupCol08" : `컬럼8`,
    "zz11_0100.title.popupCol09" : `컬럼9`,
    "zz11_0100.title.popupCol10" : `컬럼10`,
    "zz11_0100.title.popupOrder" : `순서`,
    "zz11_0100.title.popupUse" : `사용여부`,
    "zz11_0100.title.programManagement" : `공통코드관리`,
    "zz11_0200.message.noModify" : `변경된 데이터가 없습니다.`,
    "zz11_0200.message.success" : `정상 처리 되었습니다.`,
    "zz11_0200.title.authGroup" : `사용자 권한 그룹`,
    "zz11_0200.title.authManagement" : `권한 그룹 관리`,
    "zz11_0200.title.cancel" : `취소`,
    "zz11_0200.title.cert" : `인증`,
    "zz11_0200.title.excel" : `엑셀`,
    "zz11_0200.title.menuAuth" : `메뉴 권한`,
    "zz11_0200.title.menuName" : `시스템 메뉴명`,
    "zz11_0200.title.print" : `출력`,
    "zz11_0200.title.save" : `저장`,
    "zz11_0200.title.search" : `조회`,
    "zz11_0300.button.cancel" : `취소`,
    "zz11_0300.button.init" : `초기화`,
    "zz11_0300.button.save" : `저장`,
    "zz11_0300.button.search" : `검색`,
    "zz11_0300.etc.notUse" : `사용안함`,
    "zz11_0300.etc.use" : `사용`,
    "zz11_0300.message.idValid" : `프로그램 ID 값이 없습니다.`,
    "zz11_0300.message.modifyData" : `변경된 데이터가 있습니다. 저장 후 이용해 주십시오.`,
    "zz11_0300.message.nameValid" : `프로그램 명 값이 없습니다.`,
    "zz11_0300.message.noModifyData" : `변경된 데이터가 없습니다.`,
    "zz11_0300.message.programManage" : `프로그램 관리`,
    "zz11_0300.message.saveSuccess" : `저장 되었습니다.`,
    "zz11_0300.message.typeValid" : `프로그램 타입 값이 없습니다.`,
    "zz11_0300.title.modify" : `수정`,
    "zz11_0300.title.programId" : `프로그램 ID`,
    "zz11_0300.title.programIdName" : `프로그램명/ID`,
    "zz11_0300.title.programName" : `프로그램 명`,
    "zz11_0300.title.programType" : `프로그램 타입`,
    "zz11_0300.title.programUrl" : `프로그램 URL`,
    "zz11_0300.title.useYn" : `사용여부`,
    "zz11_0400.button.attach" : `+ 첨부`,
    "zz11_0400.button.initial" : `초기화`,
    "zz11_0400.button.manage" : `관리`,
    "zz11_0400.button.save" : `저장`,
    "zz11_0400.button.search" : `검색`,
    "zz11_0400.button.tutorial" : `튜토리얼 설정`,
    "zz11_0400.message.attachRemove" : `첨부파일을 삭제 후 다시 시도해 주세요.`,
    "zz11_0400.message.channelReg" : `채널을 먼저 생성해 주세요.`,
    "zz11_0400.message.existChild" : `하위 메뉴가 존재하는 메뉴는 페이지 타입으로 변경이 불가능 합니다.`,
    "zz11_0400.message.limitUpload" : `업로드 가능한 파일 크기는 20MB 입니다.`,
    "zz11_0400.message.manualFile" : `매뉴얼 파일을 먼저 등록해 주세요.`,
    "zz11_0400.message.manualReg" : `매뉴얼을 먼저 등록해 주세요.`,
    "zz11_0400.message.menuMove" : `메뉴 또는 폴더를 이동하시겠습니까?`,
    "zz11_0400.message.notMoveMenu" : `페이지 메뉴 하위로는 이동이 불가능 합니다.`,
    "zz11_0400.message.qAttachRemove" : `첨부파일을 삭제하시겠습니까?`,
    "zz11_0400.message.rejectExt" : `업로드 불가능한 확장자 입니다.`,
    "zz11_0400.message.requireName" : `메뉴명을 입력해 주세요.`,
    "zz11_0400.message.requirePath" : `프로그램 경로를 입력해 주세요.`,
    "zz11_0400.message.requireType" : `메뉴구분을 입력해 주세요.`,
    "zz11_0400.message.success" : `정상 처리 되었습니다.`,
    "zz11_0400.title.cancel" : `취소`,
    "zz11_0400.title.chinese" : `중국어`,
    "zz11_0400.title.close" : `닫기`,
    "zz11_0400.title.english" : `영어`,
    "zz11_0400.title.gubun" : `메뉴구분`,
    "zz11_0400.title.icon" : `아이콘`,
    "zz11_0400.title.inputMultiLang" : `다국어를 입력해 주세요.`,
    "zz11_0400.title.japanese" : `일본어`,
    "zz11_0400.title.korean" : `한국어`,
    "zz11_0400.title.language" : `다국어`,
    "zz11_0400.title.manual" : `매뉴얼`,
    "zz11_0400.title.manualTitle" : `메뉴명 매뉴얼 관리`,
    "zz11_0400.title.menuInfo" : `메뉴정보`,
    "zz11_0400.title.menuManagement" : `시스템 메뉴 관리`,
    "zz11_0400.title.menuName" : `메뉴명`,
    "zz11_0400.title.no" : `아니오`,
    "zz11_0400.title.noIcon" : `아이콘 없음`,
    "zz11_0400.title.noPath" : `경로 없음`,
    "zz11_0400.title.notUse" : `사용안함`,
    "zz11_0400.title.path" : `프로그램 경로`,
    "zz11_0400.title.total" : `전체`,
    "zz11_0400.title.tree" : `메뉴트리`,
    "zz11_0400.title.upperMenu" : `상위메뉴`,
    "zz11_0400.title.use" : `사용`,
    "zz11_0400.title.useYn" : `사용여부`,
    "zz11_0400.title.yes" : `예`,
    "zz11_0401.label.inputUse" : `사용여부`,
    "zz11_0401.label.tutorialComment" : `비고`,
    "zz11_0401.label.tutorialTitle" : `튜토리얼 제목`,
    "zz11_0401.success.saveMessage" : `튜토리얼 정보가 정상적으로 저장되었습니다.`,
    "zz11_0401.title.chinese" : `중국어`,
    "zz11_0401.title.english" : `영어`,
    "zz11_0401.title.gridUse" : `사용여부`,
    "zz11_0401.title.japanese" : `일본어`,
    "zz11_0401.title.korean" : `한국어`,
    "zz11_0401.title.language" : `다국어`,
    "zz11_0401.title.materCode" : `튜토리얼 `,
    "zz11_0401.title.sortOrder" : `순서`,
    "zz11_0401.title.subTitle" : `단계설정`,
    "zz11_0401.title.teamName" : `단체명`,
    "zz11_0600.title.activeYn" : `사용여부`,
    "zz11_0600.title.cancel" : `취소`,
    "zz11_0600.title.companyId" : `컴퍼니 ID`,
    "zz11_0600.title.dormancyStatus" : `휴면상태`,
    "zz11_0600.title.email" : `이메일`,
    "zz11_0600.title.emailSuccessMessage" : `<div>해당 사용자 이메일 주소로 비밀번호 초기화 메일이 발송되었습니다.</div><div>메일을 통해서 비밀번호를 재설정 해 주세요</div>`,
    "zz11_0600.title.emailSuccessMessage_1" : `<div>@(0)님에게 비밀번호 초기화 메일이 발송되었습니다.</div><div>메일을 통해서 비밀번호를 재설정 해 주세요</div>`,
    "zz11_0600.title.history" : `히스토리`,
    "zz11_0600.title.initialize" : `초기화`,
    "zz11_0600.title.joinDate" : `가입일자`,
    "zz11_0600.title.joinEmail" : `가입자 이메일 주소`,
    "zz11_0600.title.joinMobileNo" : `가입자 휴대폰번호`,
    "zz11_0600.title.joinPeriod" : `가입기간`,
    "zz11_0600.title.mobileNo" : `휴대폰 번호`,
    "zz11_0600.title.moreInfo" : `더보기`,
    "zz11_0600.title.moreInfo_1" : `보기`,
    "zz11_0600.title.no" : `아니오`,
    "zz11_0600.title.optionAll" : `전체`,
    "zz11_0600.title.optionsNotUsed" : `사용안함`,
    "zz11_0600.title.optionsUsed" : `사용`,
    "zz11_0600.title.passwordInit" : `비밀번호 초기화`,
    "zz11_0600.title.passwordSend" : `전송`,
    "zz11_0600.title.passwordSend_1" : `초기화`,
    "zz11_0600.title.paswordInitMessage" : `해당 사용자의 비밀번호를 초기화 하시겠습니까?`,
    "zz11_0600.title.property" : `프로퍼티`,
    "zz11_0600.title.propertyName" : `프로퍼티명`,
    "zz11_0600.title.save" : `저장`,
    "zz11_0600.title.search" : `검색`,
    "zz11_0600.title.userControl" : `회원관리`,
    "zz11_0600.title.userId" : `사용자 ID`,
    "zz11_0600.title.userName" : `사용자명`,
    "zz11_0600.title.yes" : `예`,
    "zz11_0600.validation.emailValidationMessage" : `계정에 이메일이 존재하지 않거나 이메일 형식이 맞지 않습니다.`,
    "zz11_0601.title.changeOptions" : `변경항목`,
    "zz11_0601.title.close" : `닫기`,
    "zz11_0601.title.controlDate" : `설정일자`,
    "zz11_0601.title.history" : `히스토리`,
    "zz11_0601.title.messageBlank" : `공백`,
    "zz11_0601.title.userName" : `사용자명`,
    "zz11_0700.button.view" : `보기`,
    "zz11_0700.title.all" : `전체`,
    "zz11_0700.title.companyId" : `컴퍼니 ID`,
    "zz11_0700.title.connectHotel" : `연결된 호텔`,
    "zz11_0700.title.email" : `이메일`,
    "zz11_0700.title.propertyName" : `프로퍼티명`,
    "zz11_0700.title.useYn" : `사용여부`,
    "zz11_0700.title.userId" : `사용자 ID`,
    "zz11_0700.title.userName" : `사용자명`,
    "zz11_0700.title.userPmAuth" : `호텔 권한 관리`,
    "zz11_0701.message.condition" : `COMPANY ID, 프로퍼티 ID, 프로퍼티명`,
    "zz11_0701.message.saveSuccess" : `저장 되었습니다.`,
    "zz11_0701.title.companyId" : `컴퍼니 ID`,
    "zz11_0701.title.hotelAuthReg" : `호텔 권한 등록`,
    "zz11_0701.title.pmCode" : `프로퍼티 ID`,
    "zz11_0701.title.pmName" : `프로퍼티명`,
    "zz11_0701.title.save" : `저장`,
    "zz12_0100.title.emailAlertSet" : `이메일 알림 설정`,
    "zz12_0100.title.joinList" : `신청리스트`,
    "zz12_0100.title.sendFormat" : `전송포멧`,
    "zz12_0100_1.message.noMoreFormat" : `추가할 수 있는 이메일 양식이 없습니다.`,
    "zz12_0100_1.title.activeYn" : `사용여부`,
    "zz12_0100_1.title.all" : `전체`,
    "zz12_0100_1.title.buttonAdd" : `+ 추가`,
    "zz12_0100_1.title.buttonFile" : `+ 첨부`,
    "zz12_0100_1.title.cancel" : `취소`,
    "zz12_0100_1.title.content" : `내용`,
    "zz12_0100_1.title.contentFile" : `첨부파일`,
    "zz12_0100_1.title.distribute" : `구분`,
    "zz12_0100_1.title.fileSizeOver" : `업로드 가능한 파일 크기는 10MB 입니다.`,
    "zz12_0100_1.title.initalize" : `초기화`,
    "zz12_0100_1.title.innerMemo" : `내부메모`,
    "zz12_0100_1.title.inputCheck" : `(을)를 입력해 주세요.`,
    "zz12_0100_1.title.noUse" : `사용안함`,
    "zz12_0100_1.title.primary" : `필수항목`,
    "zz12_0100_1.title.save" : `저장`,
    "zz12_0100_1.title.saveSuccess" : `저장 되었습니다.`,
    "zz12_0100_1.title.search" : `검색`,
    "zz12_0100_1.title.settingDate" : `설정기간`,
    "zz12_0100_1.title.settingEndDate" : `설정 종료일자`,
    "zz12_0100_1.title.settingStartDate" : `설정 시작일자`,
    "zz12_0100_1.title.title" : `제목`,
    "zz12_0100_1.title.unKnownExt" : `업로드 불가능한 확장자 입니다.`,
    "zz12_0100_1.title.use" : `사용`,
    "zz12_0100_2.message.success" : `정상적으로 등록되었습니다.`,
    "zz12_0100_2.title.activeYn" : `사용여부`,
    "zz12_0100_2.title.afterData" : `변경 후`,
    "zz12_0100_2.title.all" : `전체`,
    "zz12_0100_2.title.beforeData" : `변경 전`,
    "zz12_0100_2.title.blank" : `빈값`,
    "zz12_0100_2.title.businessName" : `업체명`,
    "zz12_0100_2.title.cancel" : `취소`,
    "zz12_0100_2.title.changeHistory" : `변경내역`,
    "zz12_0100_2.title.changeItem" : `변경항목`,
    "zz12_0100_2.title.changeMessage" : `@(0)(이)가 '@(1)'에서 '@(2)'(으)로 변경되었습니다.`,
    "zz12_0100_2.title.chargeName" : `담당자명`,
    "zz12_0100_2.title.close" : `닫기`,
    "zz12_0100_2.title.createSendType" : `+ 신규 전송타입 추가`,
    "zz12_0100_2.title.createService" : `+ 신규 서비스 코드 생성`,
    "zz12_0100_2.title.emailAddress" : `이메일 주소`,
    "zz12_0100_2.title.emailType" : `이메일 타입`,
    "zz12_0100_2.title.export" : `내보내기`,
    "zz12_0100_2.title.history" : `히스토리`,
    "zz12_0100_2.title.import" : `가져오기`,
    "zz12_0100_2.title.inputCheck" : `(을)를 입력해 주세요.`,
    "zz12_0100_2.title.intialize" : `초기화`,
    "zz12_0100_2.title.modify" : `수정`,
    "zz12_0100_2.title.more" : `더보기`,
    "zz12_0100_2.title.noUse" : `사용안함`,
    "zz12_0100_2.title.noneData" : `공백`,
    "zz12_0100_2.title.noneServiceCode" : `서비스 코드가 없습니다.`,
    "zz12_0100_2.title.primary" : `필수항목`,
    "zz12_0100_2.title.save" : `저장`,
    "zz12_0100_2.title.search" : `검색`,
    "zz12_0100_2.title.sendType" : `전송타입`,
    "zz12_0100_2.title.serviceCode" : `서비스 코드`,
    "zz12_0100_2.title.settingDate" : `설정일자`,
    "zz12_0100_2.title.settingPeriod" : `설정기간`,
    "zz12_0100_2.title.use" : `사용`,
    "zz12_0100_2.title.userId" : `사용자 ID`,
    "zz12_0100_2.valid.emailFormatter" : `이메일 형식이 잘못되었습니다.`,
    "zz13_0100.title.dataTransferHistory" : `데이터 전송`,
    "zz13_0100.title.history" : `히스토리`,
    "zz13_0100.title.residualRoomChange" : `잔여객실변경`,
    "zz13_0100.title.workHistory" : `작업내역`,
    "zz13_0500.message.frequentlyQuestion" : `자주하는 질문`,
    "zz13_0500.message.moveDirectMenu" : `바로가기`,
    "zz13_0500.message.moveMenu" : `관련 메뉴 바로가기`,
    "zz13_0500.message.noData" : `조회된 데이터가 없습니다.`,
    "zz13_0600.message.agreeExtension" : `허용된 업로드 확장자가 아닙니다.`,
    "zz13_0600.message.agreeSize" : `허용된 이미지 사이즈를 초과했습니다.`,
    "zz13_0600.message.inputContent" : `@(0) 내용을 입력해 주세요.`,
    "zz13_0600.message.inputDataEmpty" : `@(0) 데이터가 비어 있습니다.`,
    "zz13_0600.message.inputQna" : `등록하실 내용을 입력해 주세요.`,
    "zz13_0600.message.inputSubject" : `@(0) 제목을 입력해 주세요.`,
    "zz13_0600.message.inputSystemMenu" : `연결 메뉴를 선택해 주세요.`,
    "zz13_0600.message.multiLangEmpty" : `다국어가 비어 있습니다.`,
    "zz13_0600.message.saveSuccess" : `저장 되었습니다.`,
    "zz13_0600.title.add" : `+ 추가`,
    "zz13_0600.title.all" : `전체`,
    "zz13_0600.title.cancel" : `취소`,
    "zz13_0600.title.chinese" : `중국어`,
    "zz13_0600.title.content" : `내용`,
    "zz13_0600.title.english" : `영어`,
    "zz13_0600.title.init" : `초기화`,
    "zz13_0600.title.inputContent" : `내용을 입력해 주세요`,
    "zz13_0600.title.inputSubject" : `제목을 입력해 주세요`,
    "zz13_0600.title.japanese" : `일본어`,
    "zz13_0600.title.korean" : `한국어`,
    "zz13_0600.title.menuName" : `연결메뉴`,
    "zz13_0600.title.notUse" : `사용 안함`,
    "zz13_0600.title.qnaReg" : `Q&A 등록`,
    "zz13_0600.title.qnaType" : `구분`,
    "zz13_0600.title.save" : `저장`,
    "zz13_0600.title.search" : `검색`,
    "zz13_0600.title.subject" : `제목`,
    "zz13_0600.title.use" : `사용`,
    "zz13_0600.title.useYn" : `사용여부`,
    "zz13_0700.message.inputPmRm" : `호텔객실 조회조건이 없습니다.`,
    "zz13_0700.message.inputProcessTime" : `처리일시 조회조건이 없습니다.`,
    "zz13_0700.message.inputSaleDate" : `판매일 조회조건이 없습니다.`,
    "zz13_0700.title.alRms" : `판매가능\n객실수`,
    "zz13_0700.title.cancelCmsOtaNo" : `취소된 OTA 예약번호`,
    "zz13_0700.title.cmsAlrms" : `CMS 판매가능 객실수`,
    "zz13_0700.title.cmsOtaNo" : `인입된 OTA 예약번호`,
    "zz13_0700.title.cmsSelledRoom" : `CMS 예약된 객실수`,
    "zz13_0700.title.createTime" : `변경시간`,
    "zz13_0700.title.createType" : `유형`,
    "zz13_0700.title.directClosed" : `수기마감`,
    "zz13_0700.title.hotelClosed" : `호텔 전체 마감`,
    "zz13_0700.title.hotelRoom" : `호텔객실`,
    "zz13_0700.title.occClosed" : `OCC 마감(%)`,
    "zz13_0700.title.occClosed2" : `점유율마감`,
    "zz13_0700.title.openReservation" : `예약 보기`,
    "zz13_0700.title.overBook" : `오버부킹`,
    "zz13_0700.title.pmsAlrms" : `PMS 판매가능 객실수`,
    "zz13_0700.title.pmsSellledRoom" : `PMS 예약된 객실수`,
    "zz13_0700.title.processId" : `처리자`,
    "zz13_0700.title.processTime" : `처리일시`,
    "zz13_0700.title.remainRoom" : `잔여\n객실수`,
    "zz13_0700.title.remark" : `비고`,
    "zz13_0700.title.revOverBook" : `오버부킹 여부`,
    "zz13_0700.title.roomClosed" : `호텔 객실 마감`,
    "zz13_0700.title.saleDate" : `판매일`,
    "zz13_0800.title.all" : `전체`,
    "zz13_0800.title.processContent" : `취급 내용`,
    "zz13_0800.title.processCount" : `취급 건수`,
    "zz13_0800.title.processPage" : `취급 화면`,
    "zz13_0800.title.processTime" : `처리시간`,
    "zz13_0800.title.processor" : `처리자`,
    "zz13_0800.title.propertyName" : `프로퍼티명`,
    "zz13_0800.title.searchDate" : `조회일자`,
    "zz13_0800.title.searchItem" : `항목`,
    "zz13_0800.title.userId" : `사용자 ID`,
    "zz20_0000.title.authChangeHistory" : `사용자권한변경이력`,
    "zz20_0000.title.baseTitle" : `시스템 관리 히스토리`,
    "zz20_0000.title.programHistory" : `프로그램사용이력`,
    "zz20_0000.title.userLoginHistory" : `사용자로그인이력`,
    "zz20_0100.btn.authentication" : `인증`,
    "zz20_0100.button.cancel" : `취소`,
    "zz20_0100.button.close" : `닫기`,
    "zz20_0100.button.download" : `다운로드`,
    "zz20_0100.button.excel" : `엑셀`,
    "zz20_0100.button.init" : `초기화`,
    "zz20_0100.button.save" : `저장`,
    "zz20_0100.button.search" : `검색`,
    "zz20_0100.message.dateFormat" : `날짜 형식 문제`,
    "zz20_0100.message.downloadReason" : `다운로드 사유를 입력하셔야 합니다.`,
    "zz20_0100.message.inputDate" : `날짜 조회 조건은 필수 입니다.`,
    "zz20_0100.message.inputProperty" : `프로퍼티 조건은 필수 입니다.`,
    "zz20_0100.message.maxPeriod" : `최대 조회 기간은 3개월 입니다.`,
    "zz20_0100.message.noData" : `데이터가 없습니다.`,
    "zz20_0100.message.requireDate" : `날짜를 입력해 주세요.`,
    "zz20_0100.message.requireReason" : `사유를 반드시 입력해야만 다운로드를 할 수 있습니다.`,
    "zz20_0100.title.accessDate" : `접속 일자`,
    "zz20_0100.title.accessIp" : `접속 IP`,
    "zz20_0100.title.detail" : `상세조건`,
    "zz20_0100.title.downloadReason" : `사유`,
    "zz20_0100.title.hotelName" : `호텔명`,
    "zz20_0100.title.loginDate" : `로그인 일시`,
    "zz20_0100.title.loginPeriod" : `로그인 일자`,
    "zz20_0100.title.property" : `프로퍼티 명`,
    "zz20_0100.title.updateList" : `변경 내역`,
    "zz20_0100.title.userId" : `사용자 ID`,
    "zz20_0100.title.userId_userName" : `사용자ID/명`,
    "zz20_0100.title.userIp" : `접속 IP`,
    "zz20_0100.title.userName" : `사용자 명`,
    "zz20_0200.btn.authentication" : `인증`,
    "zz20_0200.btn.download" : `다운로더`,
    "zz20_0200.btn.excel" : `엑셀`,
    "zz20_0200.btn.remove" : `지움`,
    "zz20_0200.btn.search" : `조회`,
    "zz20_0200.buttom.init" : `초기화`,
    "zz20_0200.buttom.search" : `검색`,
    "zz20_0200.etc.paging_number" : `쪽`,
    "zz20_0200.etc.required" : `필수항목`,
    "zz20_0200.title.accessIp" : `접속 IP`,
    "zz20_0200.title.accessPeriod" : `프로그램 사용/접속 기간`,
    "zz20_0200.title.first_reg_user_ip" : `사용자 IP`,
    "zz20_0200.title.hotal_manager" : `프로퍼터 명`,
    "zz20_0200.title.proc_dt" : `접속일자`,
    "zz20_0200.title.proc_reason" : `처리내용`,
    "zz20_0200.title.program_name" : `프로그램 명`,
    "zz20_0200.title.user_operation_cn_name" : `처리구분`,
    "zz20_0200.title.userid" : `사용자 ID`,
    "zz20_0200.title.userid_username" : `사용자ID/명`,
    "zz20_0200.title.username" : `사용자 명`,
    "zz20_0200.validate.remove" : `데이터 유효성을 다시 확인해주세요.`,
    "zz20_0200.validation.connDate" : `접속일자은 시작일로 부터 3개월을 넘을 수 없습니다.`,
    "zz20_0200.validation.inputProperty" : `검색 필수 값이 필요합니다.`,
    "zz20_0300.buttom.init" : `초기화`,
    "zz20_0300.buttom.search" : `검색`,
    "zz20_0300.etc.allColumn" : `전체`,
    "zz20_0300.etc.required" : `필수항목`,
    "zz20_0300.message.inputProperty" : `프로퍼티 조건은 필수 입니다.`,
    "zz20_0300.title.authChangeHistory" : `권한 변경 내역`,
    "zz20_0300.title.connDt" : `접속일자`,
    "zz20_0300.title.filterConnDt" : `처리기간`,
    "zz20_0300.title.filterConnIp" : `접속 IP`,
    "zz20_0300.title.filterProgramName" : `프로그램 명`,
    "zz20_0300.title.filterPropertyName" : `프로퍼티 명`,
    "zz20_0300.title.filterUserName" : `사용자 명`,
    "zz20_0300.title.menuName" : `메뉴 명`,
    "zz20_0300.title.procDt" : `처리 일자`,
    "zz20_0300.title.procName" : `처리자`,
    "zz20_0300.title.propertyName" : `프로퍼터 명`,
    "zz20_0300.title.userid" : `사용자 ID`,
    "zz20_0300.title.username" : `사용자 명`,
    "zz20_0300.validation.connDate" : `접속일자은 시작일로 부터 3개월을 넘을 수 없습니다.`,
    "zz20_0400.title.Reportmanagement" : `관리`,
    "zz20_0400.title.baseTitle" : `트랜젝션 보고서`,
    "zz20_0400.title.monthReport" : `월간`,
    "zz20_0400.title.monthReportEmptyMessage" : `@(0)년 @(1)월의 실적 자료가 없습니다.`,
    "zz20_0400.title.yearReport" : `년간`,
    "zz20_0400.title.yearReportEmptyMessage" : `@(0)년의 실적 자료가 없습니다.`,
    "zz20_0401.buttom.init" : `초기화`,
    "zz20_0401.buttom.search" : `검색`,
    "zz20_0401.etc.counter" : `건`,
    "zz20_0401.etc.required" : `필수항목`,
    "zz20_0401.title.cancellation" : `Cancellation`,
    "zz20_0401.title.channel" : `Channel`,
    "zz20_0401.title.channelName" : `채널명`,
    "zz20_0401.title.connectedHotels" : `connected hotels`,
    "zz20_0401.title.creation" : `Creation`,
    "zz20_0401.title.modification" : `Modification`,
    "zz20_0401.title.occuDt" : `발생기간`,
    "zz20_0401.title.sliderTitar" : `월간 트랜젝션 실적`,
    "zz20_0401.title.totalCount" : `Total Count`,
    "zz20_0401.title.totalRoomRate" : `Total Room Rate`,
    "zz20_0401.title.transations" : `Booking Transactions`,
    "zz20_0402.buttom.init" : `초기화`,
    "zz20_0402.buttom.search" : `검색`,
    "zz20_0402.etc.counter" : `건`,
    "zz20_0402.etc.required" : `필수항목`,
    "zz20_0402.title.april" : `April`,
    "zz20_0402.title.april_1" : `Apr`,
    "zz20_0402.title.august" : `August`,
    "zz20_0402.title.august_1" : `Aug`,
    "zz20_0402.title.cancellation" : `October`,
    "zz20_0402.title.category" : `Category`,
    "zz20_0402.title.channel" : `Channel`,
    "zz20_0402.title.channelName" : `채널명`,
    "zz20_0402.title.creation" : `February`,
    "zz20_0402.title.december_1" : `Dec`,
    "zz20_0402.title.february" : `February`,
    "zz20_0402.title.february_1" : `Feb`,
    "zz20_0402.title.january" : `January`,
    "zz20_0402.title.january_1" : `Jan`,
    "zz20_0402.title.july" : `July`,
    "zz20_0402.title.july_1" : `Jul`,
    "zz20_0402.title.june" : `June`,
    "zz20_0402.title.june_1" : `Jun`,
    "zz20_0402.title.march" : `March`,
    "zz20_0402.title.march_1" : `Mar`,
    "zz20_0402.title.may" : `May`,
    "zz20_0402.title.may_1" : `May`,
    "zz20_0402.title.modification" : `March`,
    "zz20_0402.title.nobember" : `Nobember`,
    "zz20_0402.title.nobember_1" : `Nov`,
    "zz20_0402.title.occuDt" : `발생기간`,
    "zz20_0402.title.occuDt_1" : `발생연도`,
    "zz20_0402.title.october" : `October`,
    "zz20_0402.title.october_1" : `Oct`,
    "zz20_0402.title.september" : `September`,
    "zz20_0402.title.september_1" : `Sep`,
    "zz20_0402.title.sliderTitar" : `년간 트랜젝션 실적`,
    "zz20_0402.title.totalHotel" : `Total Hotel`,
    "zz20_0402.title.transations" : `January`,
    "zz20_0403.buttom.close" : `닫기`,
    "zz20_0403.buttom.gridCancel" : `취소`,
    "zz20_0403.buttom.gridSave" : `저장`,
    "zz20_0403.buttom.init" : `초기화`,
    "zz20_0403.buttom.more" : `더보기`,
    "zz20_0403.buttom.popupCancel" : `취소`,
    "zz20_0403.buttom.popupSave" : `저장`,
    "zz20_0403.buttom.search" : `검색`,
    "zz20_0403.etc.required" : `필수항목`,
    "zz20_0403.title.active" : `사용여부`,
    "zz20_0403.title.channel" : `채널`,
    "zz20_0403.title.channelName" : `채널명`,
    "zz20_0403.title.company" : `회사`,
    "zz20_0403.title.contractDate" : `계약일자`,
    "zz20_0403.title.contractTerms" : `계약 조건`,
    "zz20_0403.title.contractorInfo" : `계약자 정보`,
    "zz20_0403.title.contractorName" : `계약자 명`,
    "zz20_0403.title.department" : `부서명`,
    "zz20_0403.title.email" : `이메일`,
    "zz20_0403.title.endDate" : `적용 종료 일자`,
    "zz20_0403.title.gridUseActiveY" : `사용`,
    "zz20_0403.title.gridUseActiven" : `사용안함`,
    "zz20_0403.title.memo" : `메모`,
    "zz20_0403.title.office_phone" : `사무실 번호`,
    "zz20_0403.title.orderFrequency" : `청구 주기`,
    "zz20_0403.title.phone" : `휴대폰 번호`,
    "zz20_0403.title.popuptitle" : `채널 명 계약자 정보`,
    "zz20_0403.title.startDate" : `적용 시작 일자`,
    "zz20_0403.title.surtax" : `부가세 (%)`,
    "zz20_0403.title.surtaxType" : `부가세 타입`,
    "zz20_0403.title.useActiveAll" : `전체`,
    "zz20_0403.title.useActiveN" : `사용안함`,
    "zz20_0403.title.useActiveY" : `사용`,
    "zz20_0403.title.useActiveYn" : `사용여부`,
    "zz20_0403.valid.close" : `성공적으로 저장되었습니다.`,
    "zz20_0403.valid.emailFormatter" : `이메일 형식이 잘못되었습니다.`,
    "zz20_0403.valid.emptyInput" : `계약자 정보 입력란에 입력되지 않은 정보가 있습니다.`,
    "zz20_0403.valid.emptyInputContract" : `정보 입력란에 입력되지 않은 정보가 있습니다.`,
    "zz20_0403.valid.emptyInputContract_1" : `@(0)을(를) 입력해 주세요.`,
    "zz20_0403.valid.emptyInputEmail" : `계약자 정보에 이메일이 없습니다.`,
    "zz20_0403.valid.emptyInput_1" : `계약자 정보에 계약자 명이 없습니다.`,
    "zz20_0403.valid.emptyUpdateDate" : `변경된 데이터가 없습니다.`,
    "zz20_0403.valid.numberFormatter" : `휴대폰 번호 또는 사무실 번호에 숫자 아닌 값이 들어있습니다.`,
    "zz20_0403.valid.secuuessMessage" : `성공적으로 저장되었습니다.`,
}
import React, {Fragment} from 'react';
import {
    Button, CardTitle, CustomInput,
    Form,
    FormGroup,
    Label,
    Input,
    UncontrolledButtonDropdown,
    DropdownToggle,
    Dropdown,
    DropdownMenu,
    CardBody,
    InputGroup, InputGroupAddon,
} from 'reactstrap';
import {BootstrapNativeSelect, BootstrapDatePicker, BootstrapGroupRadio, BootstrapAutoComplete, BootstrapTimePicker, PopupDatePicker, BootstrapAutoCompleteSelectModeFiltered} from '../../components/ComponentsList';
import {
    faCalendarAlt,

} from '@fortawesome/free-solid-svg-icons';

import {SEARCHFILTER_LANG} from './searchFilter_lang';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '../../assets/picky/picky.css';
import {nullToBlank} from "../../utils/commUtils";
import { format } from 'date-fns';
import $ from 'jquery';
import parse from 'html-react-parser';

const SEARCH_FILTER = (props) => {
    let MULTI_LANG = {};

    if (props.locale) {
        const { formatMessage } = props.locale;
        MULTI_LANG = SEARCHFILTER_LANG(formatMessage);
    }

    const {searchData, searchAddData, detect, initFunc, searchFunc, onAuth, ...options} = props;

    const DATE_FORMAT = "yyyy-MM-dd";
    let CONDITION_NUMBER = 0;

    const [setting] = React.useState(searchData);
    const [addCombo] = React.useState(searchAddData);

    //AutoCombo 동적 생성
    const createAutoState = () => {
        const autoTemp = {};

        setting.map((item,index) => {
            if (item.cd_comp === "autoCombo")  {
                autoTemp[item.stateName] = item.value;
            } else if (item.cd_comp === "DateTimePeriod") {
                if(nullToBlank(item.value) !== "") {
                    const dateVal = item.value.split("||");
                    const stateNm = item.stateName.split(",");

                    nullToBlank(dateVal[0]);

                    const startDt = nullToBlank(dateVal[0]) === "" ? null : new Date(nullToBlank(dateVal[0]));
                    const endDt = nullToBlank(dateVal[1]) === "" ? null : new Date(nullToBlank(dateVal[1]));
                    const startTm = nullToBlank(dateVal[2]) === "" ? "" : dateVal[2].trim();
                    const endTm = nullToBlank(dateVal[3]) === "" ? "" : dateVal[3].trim();

                    autoTemp[stateNm[0]] = startDt;
                    autoTemp[stateNm[1]] = endDt;
                    autoTemp[stateNm[2]] = startTm;
                    autoTemp[stateNm[3]] = endTm;
                }
            } else if (item.cd_comp === "DatePeriod" || item.cd_comp === "oneDatePeriod") {
                if(nullToBlank(item.value) !== "") {
                    const dateVal = item.value.split("~");
                    const stateNm = item.stateName.split(",");
                    autoTemp[stateNm[0]] = nullToBlank(dateVal[0]) === "" ? "" : new Date(nullToBlank(dateVal[0]));
                    autoTemp[stateNm[1]] = nullToBlank(dateVal[1]) === "" ? "" : new Date(nullToBlank(dateVal[1]));
                }
            }
        });

        return autoTemp;
    }

    const [autoSelect, setAutoSelect] = React.useState(createAutoState());

    //State 동적 생성
    const createState = () => {
        const temp = {};
        CONDITION_NUMBER = setting.length - 1;
        setting.map((item,index) => {
            if (item.cd_comp === "singleCombo") {
                temp[item.stateName] = item.value;
            } else if (item.cd_comp === "DatePeriod" || item.cd_comp === "oneDatePeriod") {
                if(nullToBlank(item.value) !== "") {
                    const dateVal = item.value.split("~");
                    const stateNm = item.stateName.split(",");
                    if (nullToBlank(dateVal[0]) == "") {
                        temp[stateNm[0]] = "";
                    } else {
                        temp[stateNm[0]] = new Date(dateVal[0].trim());
                    }

                    if (nullToBlank(dateVal[1]) == "") {
                        temp[stateNm[1]] = "";
                    } else {
                        temp[stateNm[1]] = new Date(dateVal[1].trim());
                    }
                }
            } else if (item.cd_comp === "text")  {
                temp[item.stateName] = item.value;
            } else if (item.cd_comp === "radio")  {
                temp[item.stateName] = item.value;
            } else if (item.cd_comp === "autoCombo")  {
                temp[item.stateName] = item.value;
            } else if (item.cd_comp === "DateTimePeriod") {
                if(nullToBlank(item.value) !== "" ) {
                    const dateVal = item.value.split("||");
                    const stateNm = item.stateName.split(",");

                    nullToBlank(dateVal[0]);

                    const startDt = nullToBlank(dateVal[0]) === "" ? null : new Date(nullToBlank(dateVal[0]));
                    const endDt = nullToBlank(dateVal[1]) === "" ? null : new Date(nullToBlank(dateVal[1]));
                    const startTm = nullToBlank(dateVal[2]) === "" ? "" : dateVal[2].trim();
                    const endTm = nullToBlank(dateVal[3]) === "" ? "" : dateVal[3].trim();

                    temp[stateNm[0]] = startDt;
                    temp[stateNm[1]] = endDt;
                    temp[stateNm[2]] = startTm;
                    temp[stateNm[3]] = endTm;
                }
            } else if (item.cd_comp === "checkbox") {
                const chk = item.value.split(",");
                const sName = item.stateName.split(",");

                for (let k=0; k<chk.length; k++) {
                    temp[sName[k]] = chk[k] == "true" ? true : false;
                }
            } else if (item.cd_comp === "YearPeriod") {
                const dateVal = item.value.split("~");
                const stateNm = item.stateName.split(",");
                temp[stateNm[0]] = new Date(dateVal[0].trim());
                temp[stateNm[1]] = new Date(dateVal[1].trim());
            } if (item.cd_comp === "YearDate") {
                temp[item.stateName] = new Date(item.value);
            }
        });

        return temp;
    }

    const [state, setState] = React.useState(createState());

    React.useEffect(() => {
        detect(setting, state, addCombo);
    }, [state]);

    React.useEffect(() => {
        if (!props.isOpen) {
            setting.map(item => {
                if (item.cd_comp == "autoCombo") {
                    state[item.stateName] = autoSelect[item.stateName] == undefined ? [] : autoSelect[item.stateName];
                }
            })

            setState({...state});
        }
    }, [props.isOpen]);

    const handleChange = (name) => event => {
        setState({ ...state, [name]: event.target.value });
    }

    const onTextBlur = (name) => event => {
        setState({ ...state, [name]: event.target.value });
    }

    const AutoSelectChange = name => event => {
        autoSelect[name] = event;
    }

    const YearSelectChange = name => event => {
        setState({ ...state, [name]: event });
    }

    const AutoClose = name => {
        setState({ ...state, [name]:  autoSelect[name]});
    }

    const onCheckedChange = (item, idx) => event => {
        let chkArray = item.stateName.split(",");

        state[chkArray[idx]] = event.target.checked;

        if (idx > 0) {
            let trueCnt = 0;
            let totalVal = false;

            for (let i=1; i < chkArray.length; i++) {
                if (state[chkArray[i]]) {
                    trueCnt++;
                }
            }

            if (chkArray.length - 1 === trueCnt){
                totalVal = true;
            }

            setState({...state, [chkArray[0]] : totalVal});
        } else {
            for (let i=1; i < chkArray.length; i++) {
                state[chkArray[i]] = event.target.checked;
            }

            setState({...state});
        }
    }

    //DropDown Component
    const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);

    const dropDownOpenHandler = () => {
        setIsDropDownOpen(!isDropDownOpen);
    }

    const DropdownItems = () => {
        const visibleItem = addCombo.filter(data => data.visible == true);
        return (
            visibleItem.map((item,index) => (
                <li className="dropdown-item" key={index} onClick={() => dropDownItemClick(item, index)}>{item.label}</li>
            ))
        )
    }

    //DropDown 메뉴 아이템 선택
    const dropDownItemClick = (item, idx) => {
        CONDITION_NUMBER++;
        createKeyCheck();

        if (item.cd_comp === "DatePeriod") {
            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, value : `${new Date()} ~ ${new Date()}`, dupl : item.dupl, order : true, fix : false, stateName : item.stateName}
            );
        } else if (item.cd_comp === "text") {
            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, value : "", dupl : item.dupl, order : true, fix : false, stateName : item.stateName, maxLength : item.maxLength}
            );
        } else if  (item.cd_comp === "singleCombo") {
            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, combo_data : item.combo_data, value : nullToBlank(item.value) == "" ? "" : item.value, dupl : item.dupl, order : true, fix : false, stateName : item.stateName}
            );
        } else if (item.cd_comp === "radio")  {
            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, combo_data : item.combo_data, value : "", dupl : item.dupl, order : true, fix : false, stateName : item.stateName}
            );
        } else if (item.cd_comp === "autoCombo")  {
            if (item.multi) {
                setting.push(
                    {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, combo_data : item.combo_data, value : nullToBlank(item.value) == "" ? [] : item.value, dupl : item.dupl, order : true, fix : false, stateName : item.stateName, multi : item.multi}
                );
            } else {
                setting.push(
                    {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, combo_data : item.combo_data, value : nullToBlank(item.value) == "" ? {value:'',label:''} : item.value, dupl : item.dupl, order : true, fix : false, stateName : item.stateName, multi : item.multi}
                );
            }

            autoSelect[item.stateName] = item.value;
            setAutoSelect({...autoSelect});
        } else if (item.cd_comp === "DateTimePeriod") {
            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, value : `${new Date()} || ${new Date()} || 00:00 || 23:59`, dupl : item.dupl, order : true, fix : false, stateName : item.stateName}
            );
        } else if (item.cd_comp === "checkbox") {
            const chkLen = item.combo_data.length;
            let chkValue = "";

            if (nullToBlank(item.value) == "") {
                for (let a=0; a<chkLen; a++) {
                    if (a==0) {
                        chkValue = "false";
                    } else {
                        chkValue += ",false";
                    }
                }
            } else {
                chkValue = item.value;
            }

            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, value : chkValue, dupl : item.dupl, order : true, fix : false, stateName : item.stateName, combo_data : item.combo_data}
            );
        } else if (item.cd_comp === "YearPeriod") {
            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, value : `${new Date()} ~ ${new Date()}`, dupl : item.dupl, order : true, fix : false, stateName : item.stateName}
            );
        } else if  (item.cd_comp === "YearDate") {
            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, value : `${new Date()}`, dupl : item.dupl, order : true, fix : false, stateName : item.stateName}
            );
        } else if (item.cd_comp === "oneDatePeriod") {
            setting.push(
                {key : CONDITION_NUMBER ,cd_title : item.label, cd_require : false, cd_comp : item.cd_comp, value : `${new Date()} ~ ${new Date()}`, dupl : item.dupl, order : true, fix : false, stateName : item.stateName}
            );
        }

        setIsDropDownOpen(!isDropDownOpen);
        item.visible = false;
        setState(createState());
    }

    // 추가한 조건 삭제
    const conditionRemove = (item, idx) => {
        const find = setting.findIndex(child => {
            return child.key === item.key;
        });

        //하위 조건 삭제
        if (item.dupl) {
            const childRemoveIndex = [];
            setting.forEach((data, index) => {
                if (data.parent === item.key) {
                    childRemoveIndex.push(index);
                }
            })

            for (let i = childRemoveIndex.length-1; i >= 0; i--) {
                setting.splice(childRemoveIndex[i], 1);
            }
        }

        setting.splice(find, 1);

        const addComboFind = addCombo.findIndex(child => {
            return child.stateName === item.stateName;
        });

        addCombo[addComboFind].visible = true;

        // if (item.cd_comp === "DatePeriod") {
        //     addCombo.splice(addCombo.length, 0, {
        //         label : item.cd_title,
        //         cd_comp : item.cd_comp,
        //         dupl : item.dupl,
        //         stateName : item.stateName,
        //         visible : true
        //     });

        // } else if (item.cd_comp === "text") {
        //     addCombo.splice(addCombo.length, 0, {
        //         label : item.cd_title,
        //         cd_comp : item.cd_comp,
        //         dupl : item.dupl,
        //         stateName : item.stateName,
        //         maxLength : item.maxLength,
        //         visible : true
        //     });
        // } else if (item.cd_comp === "singleCombo") {
        //     addCombo.splice(addCombo.length, 0, {
        //         label : item.cd_title,
        //         cd_comp : item.cd_comp,
        //         dupl : item.dupl,
        //         stateName : item.stateName,
        //         combo_data : item.combo_data,
        //         visible : true
        //     });
        // } else if (item.cd_comp === "radio")  {
        //     addCombo.splice(addCombo.length, 0, {
        //         label : item.cd_title,
        //         cd_comp : item.cd_comp,
        //         dupl : item.dupl,
        //         stateName : item.stateName,
        //         combo_data : item.combo_data,
        //         visible : true
        //     });
        // } else if (item.cd_comp === "autoCombo")  {
        //     addCombo.splice(addCombo.length, 0, {
        //         label : item.cd_title,
        //         cd_comp : item.cd_comp,
        //         dupl : item.dupl,
        //         stateName : item.stateName,
        //         combo_data : item.combo_data,
        //         multi : item.multi,
        //         visible : true
        //     });
        // } else if (item.cd_comp === "DateTimePeriod") {
        //     addCombo.splice(addCombo.length, 0, {
        //         label : item.cd_title,
        //         cd_comp : item.cd_comp,
        //         dupl : item.dupl,
        //         stateName : item.stateName,
        //         visible : true
        //     });
        // }

        setState(createState());
    }

    // 중복 조건 추가 버튼 클릭시
    const onAddDuplicate = (item, idx) => {
        CONDITION_NUMBER++;
        createKeyCheck();

        if (item.cd_comp === "text") {
            setting.push(
                {
                    key : CONDITION_NUMBER ,
                    cd_title : item.cd_title + CONDITION_NUMBER,
                    cd_require : false,
                    cd_comp : item.cd_comp,
                    value : "",
                    dupl : item.dupl,
                    order : false,
                    fix : false,
                    stateName : item.stateName + CONDITION_NUMBER,
                    parent : item.key,
                    maxLength : item.maxLength
                }
            );
        }

        setState(createState());
    }

    //데이터 키 체크
    const createKeyCheck = () => {
        const childKeyCheck = setting.filter(data => data.key == CONDITION_NUMBER).length;

        if (childKeyCheck > 0) {
            CONDITION_NUMBER++;
        }
    };

    //중복으로 하위에 추가된 조건 삭제
    const onChildRemove = (item) => {
        const childFind = setting.findIndex(child => {
            return child.key === item.key;
        });

        setting.splice(childFind, 1);
        setState(createState());
    }

    const componentPath = () => {
        const path = [];

        const notChild = setting.filter(data => data.parent === undefined);

        notChild.map(data => {
            if (data.cd_comp === "DateTimePeriod") {
                let pdStateSlice = data.stateName.split(",");

                path.push(pdStateSlice[0].trim());
                path.push(pdStateSlice[2].trim());
                path.push(pdStateSlice[1].trim());
                path.push(pdStateSlice[3].trim());
            } else if (data.cd_comp === "DatePeriod" || data.cd_comp === "checkbox" || data.cd_comp === "YearPeriod") {
                let stateSlice = data.stateName.split(",");

                for (let j=0; j<stateSlice.length; j++) {
                    path.push(stateSlice[j].trim());
                }
            } else if (data.cd_comp === "text") {
                path.push(data.stateName.trim());

                setting.filter(child => child.parent === data.key).map(list => {
                    path.push(list.stateName.trim());
                });

            } else if (data.cd_comp === "oneDatePeriod") {
                let stateSlice = data.stateName.split(",");
                path.push(stateSlice[0].trim());
            } else {
                path.push(data.stateName.trim());
            }
        });

        return path;
    }

    //엔터키 컴포넌트 이동
    const onKeyDownHandler = (current, flag = false) => event => {
        if (event.keyCode === 9) {
            event.preventDefault();
        }

        if (event.keyCode === 13 || event.keyCode === 9) {
            const CP_PATH = componentPath();
            const pathfind = CP_PATH.findIndex(child => {
                return child === current;
            });
            
            if (!flag) {
                setTimeout(function() {
                    let id ="#"+CP_PATH[pathfind+1];

                    $(id).focus();
                    $(id).select();

                    $(id).focus();
                    $(id).select();
                }, 10);
            } else {
                event.preventDefault();
                setTimeout(function() {
                    $("#"+CP_PATH[pathfind+1]).focus();
                    $("#"+CP_PATH[pathfind+1]).select();
                }, 10);
            }
        }
    }

    const CreateCondition = props => {

        const settingParent = setting.filter(data => data.parent === undefined);

        return (
            settingParent.map((item,index) => {

                if (item.cd_comp === "singleCombo") {

                    return (
                        <li key={index}>
                            <div className="table-cell label">
                                <Label>{item.cd_title}
                                {
                                    item.cd_require == true &&
                                    <div className="badge badge-dot badge-dot-sm badge-danger">
                                        필수항목
                                    </div>
                                }
                                </Label>
                                {
                                    item.fix == false &&
                                    <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                                }
                            </div>
                            <div className="table-cell">
                                <BootstrapNativeSelect
                                    id={item.stateName}
                                    options={item.combo_data}
                                    onChangeFunc={handleChange(item.stateName)}
                                    value={state[item.stateName]}
                                    onKeyDown={onKeyDownHandler(item.stateName, true)}
                                />
                            </div>
                        </li>
                    )
                }
                else if (item.cd_comp === "DatePeriod") {
                    const nameList = item.stateName.split(",");

                    return (
                        <li key={index}>
                            <div className="table-cell label">
                            <Label>{item.cd_title}
                            {
                                item.cd_require == true && item.requireException != true &&
                                <div className="badge badge-dot badge-dot-sm badge-danger">
                                    필수항목
                                </div>
                            }
                            </Label>
                            {
                                item.fix == false &&
                                <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                            }
                            </div>
                            <div className="table-cell">
                                <div className="date-area">
                                    <InputGroup className="start">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                        <BootstrapDatePicker
                                            id={nameList[0]}
                                            dateClass={'form-control'}
                                            dateFormat={DATE_FORMAT}
                                            value={nullToBlank(state[nameList[0]]) == "" ? null : state[nameList[0]]}
                                            onChangeFunc={AutoSelectChange(nameList[0])}
                                            compareMaxValue={state[nameList[1]]}
                                            onBlurFunc={onPickerBlurhandler(nameList[0], 'from', nameList[1], item.cd_require)}
                                            onKeyDownFunc={onKeyDownHandler(nameList[0])}
                                            selectFuncExec={!item.cd_require ? true : false}
                                        />
                                    </InputGroup>
                                    <span className="sign">~</span>
                                    <InputGroup className="end">
                                        <InputGroupAddon addonType="prepend" >
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                            <BootstrapDatePicker
                                                id={nameList[1]}
                                                dateClass={'form-control'}
                                                dateFormat={DATE_FORMAT}
                                                popperPlacement={"bottom-end"}
                                                value={nullToBlank(state[nameList[1]]) == "" ? null : state[nameList[1]]}
                                                onChangeFunc={AutoSelectChange(nameList[1])}
                                                compareMinValue={state[nameList[0]]}
                                                onBlurFunc={onPickerBlurhandler(nameList[1], 'to', nameList[0], item.cd_require)}
                                                onKeyDownFunc={onKeyDownHandler(nameList[1])}
                                                selectFuncExec={!item.cd_require ? true : false}
                                            />
                                    </InputGroup>
                                </div>
                            </div>
                        </li>
                    )
                } else if (item.cd_comp === "text") {
                    let child = [];

                    if (item.dupl) {
                        child = setting.filter(data => data.parent === item.key);
                    }

                    return (
                        <li key={index}>
                            <div className="table-cell label">
                                <Label>{parse(item.cd_title)}
                                {
                                    item.cd_require == true &&
                                    <div className="badge badge-dot badge-dot-sm badge-danger">
                                        필수항목
                                    </div>
                                }
                                </Label>
                                {
                                    item.fix == false &&
                                    <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                                }
                            </div>
                            {
                                item.dupl == true ?
                                <div className="table-cell clone">
                                    <div className="Con">
                                        <Input
                                            id={item.stateName}
                                            type="text"
                                            onBlur={onTextBlur(item.stateName)}
                                            defaultValue={state[item.stateName]}
                                            maxLength={item.maxLength}
                                            onKeyDown={onKeyDownHandler(item.stateName)}
                                        />
                                        <div className="add-icon min-icon right" onClick={() => onAddDuplicate(item, index)}/>
                                    </div>
                                    {
                                        child.length > 0 &&
                                        child.map((item,index) => {
                                            return (
                                                <div className="Con" key={index}>
                                                    <Input
                                                        id={item.stateName}
                                                        type="text"
                                                        onBlur={onTextBlur(item.stateName)}
                                                        defaultValue={state[item.stateName]}
                                                        maxLength={item.maxLength}
                                                        onKeyDown={onKeyDownHandler(item.stateName)}
                                                    />
                                                    <div className="remove-icon min-icon right" onClick={() => onChildRemove(item)}/>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                :

                                <div className="table-cell">
                                    <Input
                                        id={item.stateName}
                                        type="text"
                                        onBlur={onTextBlur(item.stateName)}
                                        defaultValue={state[item.stateName]}
                                        maxLength={item.maxLength}
                                        onKeyDown={onKeyDownHandler(item.stateName)}
                                    />
                                </div>
                            }
                        </li>
                    )
                } else if (item.cd_comp === "radio") {
                    return (
                        <li key={index}>
                            <div className="table-cell label">
                                <Label>{item.cd_title}
                                {
                                    item.cd_require == true &&
                                    <div className="badge badge-dot badge-dot-sm badge-danger">
                                        필수항목
                                    </div>
                                }
                                </Label>
                                {
                                    item.fix == false &&
                                    <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                                }
                            </div>
                            <div className="table-cell">
                                <BootstrapGroupRadio
                                    //id={item.stateName}
                                    options={item.combo_data}
                                    radioClass="custom-control-inline font-size-082"
                                    rdName={"radio"+index}
                                    onChangeFunc={handleChange(item.stateName)}
                                    value={state[item.stateName]}
                                    onKeyDown={onKeyDownHandler(item.stateName)}
                                />
                            </div>
                        </li>
                    )
                } else if (item.cd_comp === "autoCombo") {
                    return (
                        <li key={index} style={item.noShow ? {display: 'none'} : {}}>
                            <div className="table-cell label">
                                <Label>{parse(item.cd_title)}
                                    {
                                        item.cd_require == true &&
                                        <div className="badge badge-dot badge-dot-sm badge-danger">
                                            필수항목
                                            </div>
                                    }
                                </Label>
                                {
                                    item.fix == false &&
                                    <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)} />
                                }
                            </div>
                            <div className="table-cell">

                                <BootstrapAutoCompleteSelectModeFiltered
                                    id={item.stateName}
                                    options={item.combo_data}
                                    selectList={state[item.stateName]}
                                    onChangeFunc={AutoSelectChange(item.stateName)}
                                    multiple={item.multi}
                                    includeSelectAll={item.multi == true ? true : false}
                                    includeFilter={true}
                                    placeholder=""
                                    onClose={() => AutoClose(item.stateName)}
                                    keepOpen={item.multi === true ? true : false}
                                    selectAllText={MULTI_LANG.LABEL_TITLE_ALL}
                                />
                            </div>
                        </li>
                    )
                } else if (item.cd_comp === "DateTimePeriod") {
                    const nameList = item.stateName.split(",");

                    return (
                        <li key={index}>
                            <div className="table-cell label">
                                <Label>{item.cd_title}
                                {
                                    item.cd_require == true &&
                                    <div className="badge badge-dot badge-dot-sm badge-danger">
                                        필수항목
                                    </div>
                                }
                                </Label>
                                {
                                    item.fix == false &&
                                    <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                                }
                            </div>
                            <div className="table-cell">
                                <div className="date-area">
                                    <InputGroup className="start">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                        <BootstrapDatePicker
                                            id={nameList[0]}
                                            dateClass={'form-control'}
                                            value={state[nameList[0]]}
                                            onChangeFunc={AutoSelectChange(nameList[0])}
                                            onBlurFunc2={onDatePickerBlurhandler(nameList[0], 'from', nameList[1], nameList[2], nameList[3])}
                                            dateFormat={DATE_FORMAT}
                                            compareMaxValue={state[nameList[1]]}
                                            compareMaxTimeValue={state[nameList[3]]}
                                            timeValue={state[nameList[2]]}
                                            onKeyDownFunc={onKeyDownHandler(nameList[0])}
                                        />
                                        <div className="input-group-append">
                                            <BootstrapTimePicker
                                                timeClass="ml-2 w_60"
                                                onBlurFunc={onTimePickerBlurhandler(nameList[2], 'from', nameList[3], nameList[0], nameList[1])}
                                                defaultValue={state[nameList[2]]}
                                                id={nameList[2]}
                                                onKeyDownFunc={onKeyDownHandler(nameList[2])}
                                            />
                                        </div>
                                    </InputGroup>
                                    <span className="sign">~</span>
                                    <InputGroup className="start">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                        <BootstrapDatePicker
                                            id={nameList[1]}
                                            dateClass={'form-control'}
                                            value={state[nameList[1]]}
                                            onChangeFunc={AutoSelectChange(nameList[1])}
                                            onBlurFunc2={onDatePickerBlurhandler(nameList[1], 'to', nameList[0], nameList[2], nameList[3])}
                                            dateFormat={DATE_FORMAT}
                                            compareMinValue={state[nameList[0]]}
                                            compareMinTimeValue={state[nameList[2]]}
                                            timeValue={state[nameList[3]]}
                                            popperPlacement={"bottom-end"}
                                            onKeyDownFunc={onKeyDownHandler(nameList[1])}
                                        />
                                        <div className="input-group-append">
                                            <BootstrapTimePicker
                                                id={nameList[3]}
                                                timeClass="ml-2 w_60"
                                                onBlurFunc={onTimePickerBlurhandler(nameList[3], 'to', nameList[2], nameList[0], nameList[1])}
                                                defaultValue={state[nameList[3]]}
                                                onKeyDownFunc={onKeyDownHandler(nameList[3])}
                                            />
                                        </div>
                                    </InputGroup>
                                </div>
                            </div>
                        </li>
                    )
                } else if (item.cd_comp === "checkbox") {
                    return (
                        <li key={index}>
                            <div className="table-cell label">
                                <Label>{item.cd_title}
                                {
                                    item.cd_require == true &&
                                    <div className="badge badge-dot badge-dot-sm badge-danger">
                                        필수항목
                                    </div>
                                }
                                </Label>
                                {
                                    item.fix == false &&
                                    <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                                }
                            </div>
                            <div className="table-cell">
                                {
                                    item.combo_data.map((data,index) => {
                                        return (
                                            <CustomInput
                                                key={index}
                                                type="checkbox"
                                                label={data.label}
                                                inline
                                                id={item.stateName.split(',')[index]}
                                                name={item.stateName}
                                                checked={state[item.stateName.split(',')[index]]}
                                                onChange={onCheckedChange(item, index)}
                                                onKeyDown={onKeyDownHandler(item.stateName.split(',')[index])}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </li>
                    )
                } else if (item.cd_comp === "YearPeriod") {
                    const nameList = item.stateName.split(",");

                    return (
                        <li key={index}>
                            <div className="table-cell label">
                            <Label>{item.cd_title}
                            {
                                item.cd_require == true &&
                                <div className="badge badge-dot badge-dot-sm badge-danger">
                                    필수항목
                                </div>
                            }
                            </Label>
                            {
                                item.fix == false &&
                                <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                            }
                            </div>
                            <div className="table-cell">
                                <div className="date-area">
                                    <InputGroup className="start">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                        {/* <BootstrapDatePicker
                                            id={nameList[0]}
                                            dateClass={'form-control'}
                                            value={state[nameList[0]]}
                                            onChangeFunc={AutoSelectChange(nameList[0])}
                                            compareMaxValue={state[nameList[1]]}
                                            onBlurFunc={onPickerBlurhandler(nameList[0], 'from', nameList[1])}
                                            onKeyDownFunc={onKeyDownHandler(nameList[0])}
                                            dateFormat={"yyyy"}
                                        /> */}
                                        <PopupDatePicker
                                            id={nameList[0]}
                                            value={state[nameList[0]]}
                                            maxDate={state[nameList[1]]}
                                            format="yyyy"
                                            openTo="year"
                                            views={["year"]}
                                            onChange={YearSelectChange(nameList[0])}
                                        />
                                    </InputGroup>
                                    <span className="sign">~</span>
                                    <InputGroup className="end">
                                        <InputGroupAddon addonType="prepend" >
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                            {/* <BootstrapDatePicker
                                                id={nameList[1]}
                                                dateClass={'form-control'}
                                                dateFormat={DATE_FORMAT}
                                                popperPlacement={"bottom-end"}
                                                value={state[nameList[1]]}
                                                onChangeFunc={AutoSelectChange(nameList[1])}
                                                compareMinValue={state[nameList[0]]}
                                                onBlurFunc={onPickerBlurhandler(nameList[1], 'to', nameList[0])}
                                                onKeyDownFunc={onKeyDownHandler(nameList[1])}
                                                dateFormat={"yyyy"}
                                                showMonthYearPicker
                                            /> */}
                                        <PopupDatePicker
                                            id={nameList[1]}
                                            value={state[nameList[1]]}
                                            minDate={state[nameList[0]]}
                                            format="yyyy"
                                            openTo="year"
                                            views={["year"]}
                                            onChange={YearSelectChange(nameList[1])}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </li>
                    )
                } else if (item.cd_comp === "YearDate") {
                    return (
                        <li key={index}>
                            <div className="table-cell label">
                            <Label>{item.cd_title}
                            {
                                item.cd_require == true &&
                                <div className="badge badge-dot badge-dot-sm badge-danger">
                                    필수항목
                                </div>
                            }
                            </Label>
                            {
                                item.fix == false &&
                                <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                            }
                            </div>
                            <div className="table-cell">

                                    <InputGroup className="start">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>
                                        <PopupDatePicker
                                            id={item.stateName}
                                            value={state[item.stateName]}
                                            format="yyyy"
                                            openTo="year"
                                            views={["year"]}
                                            onChange={YearSelectChange(item.stateName)}
                                        />
                                    </InputGroup>
                            </div>
                        </li>
                    )
                } else if (item.cd_comp === "oneDatePeriod") {
                    const nameList = item.stateName.split(",");

                    return (
                        <li key={index}>
                            <div className="table-cell label">
                            <Label>{item.cd_title}
                            {
                                item.cd_require == true &&
                                <div className="badge badge-dot badge-dot-sm badge-danger">
                                    필수항목
                                </div>
                            }
                            </Label>
                            {
                                item.fix == false &&
                                <div className="remove-icon min-icon" onClick={() => conditionRemove(item, index)}/>
                            }
                            </div>
                            <div className="table-cell">
                                <InputGroup className="start">
                                    <InputGroupAddon addonType="prepend">
                                        <div className="input-group-text">
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                        </div>
                                    </InputGroupAddon>
                                    <BootstrapDatePicker
                                        id={nameList[0]}
                                        dateClass={'form-control'}
                                        value={nullToBlank(state[nameList[0]]) == "" ? null : state[nameList[0]]}
                                        onChangeFunc={AutoSelectChange(nameList[0])}
                                        onBlurFunc={onPickerBlurhandler2(nameList[0], 'from', nameList[1], item.addDate)}
                                        onKeyDownFunc={onKeyDownHandler(nameList[0])}
                                        minDate={new Date(item.minValue)}
                                    />
                                </InputGroup>
                            </div>
                        </li>
                    )
                }
            })
        )
    }

    //데이트피커 포커스 아웃시 핸들러
    const onDatePickerBlurhandler = (searchName, position, compareName, startTime, endTime) => (event, autoDate, minFlag, maxFlag) => {
        if (autoDate === null) {
            setState({...state, [searchName] : null});
            return;
        }

        if (minFlag || maxFlag) {
            state[searchName] = autoDate;
            state[startTime] = format(autoDate, "HH:mm");
            state[compareName] = autoDate;
            state[endTime] = format(autoDate, "HH:mm");

            setState({...state});
        } else {
            setState({...state, [searchName] : autoDate});

            // let tempStartDate = "";
            // let tempEndDate = "";

            // if (position === 'from') {
            //     tempStartDate = autoDate;
            //     tempEndDate = state[compareName];
            // } else {
            //     tempStartDate = state[compareName];
            //     tempEndDate = autoDate;
            // }

            // if (nullToBlank(tempStartDate) !== "" && nullToBlank(tempEndDate) !== "") {
            //     let compareDate = new Date();
            //     compareDate.setHours(0);
            //     compareDate.setMinutes(0);
            //     compareDate.setSeconds(0);

            //     let addDate = tempStartDate.getTime() + (90 * 24 * 60 * 60 * 1000);
            //     compareDate.setTime(addDate);

            //     if (tempEndDate > compareDate) {
            //         warningModalFunction(commonLang(), MULTI_LANG.ALERT_MESSAGE_MAXPERIOD);
            //         setState({...state, [searchName] : tempEndDate});
            //         return;
            //     }
            // }
        }
    }

    //timepicker 포커스 아웃 핸들러
    const onTimePickerBlurhandler = (searchName, position, compareName, startDate, endDate) => (event,autoDate, minFlag, maxFlag) => {
        if (autoDate === null) {
            setState({...state, [searchName] : ""});
            return;
        }

        if (state[startDate] != null && state[endDate] != null && nullToBlank(state[searchName]) !== "" && nullToBlank(state[compareName]) !== "") {
             let tempStartDate = "";
             let tempEndDate = "";
             let tempAuto = autoDate.split(":");

             if (position === 'from') {
                tempStartDate = new Date(state[startDate]);
                tempStartDate.setHours(tempAuto[0]);
                tempStartDate.setMinutes(tempAuto[1]);
                tempStartDate.setSeconds(0);

                tempEndDate = new Date(state[endDate]);
                if (nullToBlank(state[compareName]) !== "") {
                    const compareValue = state[compareName].split(":");

                    tempEndDate.setHours(compareValue[0]);
                    tempEndDate.setMinutes(compareValue[1]);
                    tempEndDate.setSeconds(0);
                }

                if (tempStartDate > tempEndDate) {//yyyyMMddHHmmss
                    state[startDate] = tempStartDate;
                    state[searchName] = format(tempStartDate, "HH:mm");
                    state[endDate] = tempStartDate;
                    state[compareName] = format(tempStartDate, "HH:mm");
                } else {
                    state[startDate] = tempStartDate;
                    state[searchName] = format(tempStartDate, "HH:mm");
                }
             } else if (position === 'to'){
                tempStartDate = new Date(state[startDate]);
                tempEndDate = new Date(state[endDate]);
                tempEndDate.setHours(tempAuto[0]);
                tempEndDate.setMinutes(tempAuto[1]);
                tempEndDate.setSeconds(0);

                if (nullToBlank(state[compareName]) !== "") {
                    const compareVal = state[compareName].split(":");
                    tempStartDate.setHours(compareVal[0]);
                    tempStartDate.setMinutes(compareVal[1]);
                    tempStartDate.setSeconds(0);
                }

                if (tempStartDate > tempEndDate) {
                    state[startDate] = tempEndDate;
                    state[searchName] = format(tempEndDate, "HH:mm");
                    state[endDate] = tempEndDate;
                    state[compareName] = format(tempEndDate, "HH:mm");
                } else {
                    state[endDate] = tempEndDate;
                    state[searchName] = format(tempEndDate, "HH:mm");
                }
            }

            // if (tempStartDate !== "" && tempEndDate !== "") {
            //     const compareDate = new Date();
            //     compareDate.setHours(0);
            //     compareDate.setMinutes(0);
            //     compareDate.setSeconds(0);

            //     const addDate = tempStartDate.getTime() + (90 * 24 * 60 * 60 * 1000);
            //     compareDate.setTime(addDate);

            //     if (tempEndDate > compareDate) {
            //         setIsMaxDateWarnOpen(!isMaxDateWarnOpen);
            //         setState({...state, [searchName] : ""});
            //         return;
            //     }
            // }
        }

        setState({...state});
    }

    //피커 포커스 아웃시 핸들러
    const onPickerBlurhandler = (searchName, position, compareName, requireFlag) => (event,autoDate, minFlag, maxFlag) => {
        if (autoDate === null) {
            if (requireFlag) {
                setState({...state});
            } else {
                setState({...state, [searchName] : null});
            }
            return;
        }

        // let strPeriod = "";
        // let strViewPeriod = "";

        if (minFlag || maxFlag) {
            state[searchName] = autoDate;
            state[compareName] = autoDate;

            setState({...state});
        } else {
            setState({...state, [searchName] : autoDate});

            // let tempStartDate = "";
            // let tempEndDate = "";

            // if (position === 'from') {
            //     tempStartDate = autoDate;
            //     tempEndDate = state[compareName];
            // } else {
            //     tempStartDate = state[compareName];
            //     tempEndDate = autoDate;
            // }

            // if (tempStartDate !== "" && tempEndDate !== "") {
            //     let compareDate = new Date();
            //     compareDate.setHours(0);
            //     compareDate.setMinutes(0);
            //     compareDate.setSeconds(0);

            //     let addDate = tempStartDate.getTime() + (90 * 24 * 60 * 60 * 1000);
            //     compareDate.setTime(addDate);

            //     if (tempEndDate > compareDate) {
            //         // setIsMaxDateWarnOpen(!isMaxDateWarnOpen);
            //         // setState({...state, [searchName] : null});
            //         return;
            //     }
            // }
        }
    }

    //oneDatePeriod 피커 포커스 아웃시 핸들러
    const onPickerBlurhandler2 = (searchName, position, compareName, add) => (event,autoDate, minFlag, maxFlag) => {
        if (autoDate === null || autoDate < new Date()) {
            state[searchName] = new Date();
            state[compareName] = makeEndDate(new Date(), add);

            setTimeout(() => {
                setState({...state});
            }, 10);

            return;
        }

        state[searchName] = autoDate;
        state[compareName] = makeEndDate(autoDate, add);

        setTimeout(() => {
            setState({...state});
        }, 10);
    }

    const makeEndDate = (value, add) => {
        let tempEndDate = new Date();
        tempEndDate.setHours(0);
        tempEndDate.setMinutes(0);
        tempEndDate.setSeconds(0);

        let plusDate = value.getTime() + (add * 24 * 60 * 60 * 1000);
        tempEndDate.setTime(plusDate);

        return tempEndDate;
    }

    const onSearchHandler = () => {
        setting.map(item => {
            if (item.cd_comp == "autoCombo") {
                state[item.stateName] = autoSelect[item.stateName] == undefined ? [] : autoSelect[item.stateName];
            }
        })

        setState({...state});
        setTimeout(function() {
            searchFunc();
        },10);
    }

    return (
        <Fragment>
            <h3 className="drawer-heading">
                {MULTI_LANG.LABEL_TITLE_CONDITION}
            </h3>
            <div className="drawer-section p-3">
                <div className="form-wrap filter-warp">
                    <Form>
                        {
                            addCombo.length > 0 &&
                            <div className="float-right mb-2 mr-21">
                                <UncontrolledButtonDropdown isOpen={isDropDownOpen} toggle={dropDownOpenHandler}>
                                    <DropdownToggle caret color="outline-dark" className="mb-2 mr-2 btn-icon notoggle">
                                    <i className="btn-icon-wrapper font-weight-bold float-left pe-7s-plus2"></i>{MULTI_LANG.LABEL_TITLE_CONDITIONADD}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-xsm p-0 dropdown-menu-rights fixW">
                                        <div>
                                            <CardBody className="p-0 pt-3 pb-2">
                                            {/*<ul className="m-0 p-0 h-380 overflow-auto">*/}
                                            <ul className="m-0 p-0 overflow-auto">
                                                <DropdownItems />
                                            </ul>
                                            </CardBody>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        }
                        <FormGroup className="mb-2 mb-sm-0 inline">
                            <ul className="p-0 m-0">
                                <CreateCondition />
                            </ul>
                        </FormGroup>
                    </Form>
                </div>
            </div>
            <div className="footer-btn">
                <div className="divider"/>
                <div className="text-center mt-2 d-block mb-3">
                    <Button className="mr-2 btn-icon font-weight-md w90" color="light" onClick={() => initFunc()}>
                        <i className="pe-7s-refresh btn-icon-wrapper font-weight-bold"> </i>
                        {MULTI_LANG.BUTTON_TITLE_INIT}
                    </Button>
                    <Button className="btn-icon font-weight-md text-white w90" color="primary" onClick={onSearchHandler} close={onAuth}>
                        <i className="pe-7s-search btn-icon-wrapper font-weight-bold"> </i>
                        {MULTI_LANG.BUTTON_TITLE_SEARCH}
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}

export default SEARCH_FILTER;
